import * as Sentry from "@sentry/browser";
import React from "react";
import { Redirect } from "react-router-dom";

interface Props {
  error: {
    errorCauses: Array<string>;
    errorCode: string;
    errorId: string;
    errorLink: string;
    errorSummary: string;
    message: string;
    name: string;
  };
}

const OktaErrorHandler: React.FC<Props> = ({ error }) => {
  Sentry.captureException(error);
  
  return <Redirect to={`/login-error?text=${error.errorSummary}`} />
};

export default OktaErrorHandler;
