import React from 'react';
import classNames from 'classnames';

const Textarea = ({ input, meta, defaultValue, onChange, ...custom }) => {
  // eslint-disable-line no-unused-vars
  //let isInvalid = !!(meta.touched && (meta.error || meta.warning));
  let data = custom.data || {};
  let isInvalid = !!((meta.touched || data.forceTouched) && (meta.error || meta.warning));
  let value = input.value || '';
  let fieldId = custom.id || 'textfield-' + new Date().getTime() + '-' + Math.floor(Math.random() * 1000 + 1);
  let classes = classNames('input-content -textfield', {
    '-required': custom.required,
    '-invalid': isInvalid
  });

  let rest = [
    'placeholder',
    'autoComplete',
    'spellCheck',
    'autoCapitalize',
    'autoCorrect',
    'required',
    'onFocus',
    'onKeyUp',
    'onKeyDown'
  ].reduce((prev, key) => {
    if (custom[key]) {
      prev[key] = custom[key];
    }
    return prev;
  }, {});

  return (
    <div className={classes}>
      {custom.label && (
        <label htmlFor={fieldId} className="input-label">
          {custom.label}
        </label>
      )}
      <textarea
        {...input}
        id={fieldId}
        value={value}
        aria-invalid={isInvalid}
        {...rest}
        className={'textarea' + (isInvalid ? ' -invalid' : '')}
      />
      {isInvalid && (
        <div className="input-error-message" role="alert">
          {meta.error || meta.warning}
        </div>
      )}
    </div>
  );
};

export default Textarea;
