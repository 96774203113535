export const ErrorTextList = [
  {
    id: '411-4-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått ',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '412-4-x',
    text: 'Fant ikke produkt id',
    note: 'Feil i produktdefinisjoner i baksystem. Prøv igjen kan fungere.',
    action:
      'Kategori 1 Fant ikke produkt-id. Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than   AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '413-4-x',
    text: 'Fant ikke produkt navn',
    note: 'Feil i produktdefinisjoner i baksystem. Prøv igjen kan fungere.',
    action:
      'Kategori 1 Feil i produktdefinisjoner i baksystem. Prøv igjen kan fungere. Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '414-4-x',
    text: 'AgreementID mangler eller er feil. Kun ved PerformMultipleJourne yOrder eller hvis AgreementID er sat',
    note: '',
    action:
      'Kategori 2 Feil i appen. Gjelder Nets (?) og Strex. Tiltak: Sjekk om kunden har prøvd å legge inn betalingsmåte på nytt. Er problemet der fremdeles, må kunde avinstallere app, og installere på nytt. Følg videre rutinebeskrivel',
    noTitle: 'En feil har oppstått',
    noText:
      'Slett betalingsmåte og legg inn på nytt. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please delete the payment method and register it again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '415-4-x',
    text: 'Sone Fra mangler. Får ikke kjøpt billett.',
    note: '',
    action:
      'Kategori 2 Sone Fra mangler. Kunden får ikke kjøpt billett. Feilen oppstår hvis valgt stoppested ikke har en sonetilknytning. Kunden må prøve igjen, og eventuelt legge inn stoppesteder eller soner på nytt.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Legg inn stoppesteder (Fra og til) eller Soner på nytt. (Feilkode xxx-x-x, app-ID xxxx-xx xx-xx.)',
    enTitle: 'An error has occurred',
    enText: 'Try again. Enter stops (From - to) or Zones again. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '431-4-x',
    text: 'Antall passasjerer er flere en tillatt pr kategori (Kun enkeltbillett)',
    note:
      'I senere versjon vil vi kunne informere kunden om hva som faktisk er mulig etter endring, og meldingen kan være: Maksimalt antall reisende på en billett er x voksne og y barn/honnør. Reduser antallet og prøv igjen.',
    action: 'Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'Antall reisende er for høyt',
    noText: 'Maksimalt antall reisende på en billett er endret. Reduser antallet og prøv igjen',
    enTitle: 'The number of travellers is too high',
    enText: 'The maximum number for one ticket has been changed. Reduce the number and try again'
  },
  {
    id: '432-4-x',
    text: 'Feil i periodebillett objekt, feil profile eller feil antall soner',
    note: 'Ingen hensikt å prøve igjen.',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-fei',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '839-x-x',
    text:
      'Produktet man prøver å kjøpe krever at man har et forhåndsgodkjent telefonnummer. Denne feilmelding vises kun dersom forhåndsgodkjenningen nettopp er blitt fjernet og appen ikke har fått denne informasjonen fra server. Bruker kan da trykke på produktet i appen. Og denne feilmeldingen vil stoppe bruker fra å gjennomføre et kjøp.',
    note: 'Kan kun inntreffe for HjemJobbHjem (Kolumbus).',
    action:
      'Kategori 2 Ved kjøp av  HjemJobbHjem-billetter kreves det at telefonnummeret er registrert, og ligger på en liste over tillatte telefonnumre. Dersom appen «tror» at kjøp av HjemJobbHjem-bille tt kan gjøres, men telefonnummeret i mellomtiden er fjernet fra «godkjent»-listen, så vil kjøpet bli avvist. Kunden må kjøpe en annen billett med en annen betalingsmåte',
    noTitle: 'Ikke tilgjengelig',
    noText:
      'Billett ikke lenger tilgjengelig for registrert telefonnummer. Kjøp en annen billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Not available',
    enText:
      'Ticket no longer available for the registered phone number. Buy another ticket. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '433-4-8',
    text: '',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '912-4-x',
    text: 'Start dato er for langt frem i tid (1 eller 7 døgn) Gjelder vel ikke kun dato, men også tidspunkt.',
    note:
      'Vil være aktuelt om vi går fra 7 dager til 5 dager, f.eks. Teoretisk i dag, men om vi sender fra baksystemer scenarios hvor vi har endret i baksystem, men ikke i app. Da vil prøv igjen med annet tidspunkt kunne fungere. Denne vil kun komme dersom det er usynk mellom produkt parametere i baksystem  og app, og da kun hvis baksystem godtar kortere frem i tid en app, f.eks. baksystem godtar 4 dager og app godtar 7 dager. Vil da komme hvis kunden kjøper billett for 6 dager frem i td.',
    action:
      'Kategori 2 Startdato er for langt frem i tid (1 eller 7 døgn). Kunden må kjøpe en billett med tidligere startdato. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen med en tidligere startdato. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText: 'Try again with an earlier start date. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '951-4-x',
    text: 'Oppdatering av instans-profil feilet',
    note:
      'F.eks. timeout på databasen. Database nede. Prøv igjen. Vedvarer problemet ta kontakt.... Hver gang det gjøres et kjøp oppdateres instans-en med OS-versjon, app-versjon, dato for sist bruk. Svar fra Einar: Nei, (..å oppdatere..) vil ikke være mulig, men kan feile, f.eks. en timeout. Vil da fungere å prøve igjen.',
    action:
      'Kategori 2 Kan hjelpe å prøve igjen. Hvis ikke, må kunden bruke en annen betalingsmåte enn RuterBillett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '962-4-x',
    text: 'Initialisering av betaling feilet',
    note: 'Får ikke startet betalingsprosessen. Pa yex-forbindelse nede. Får ikke kontakt. Prøv igjen senere',
    action:
      'Kategori 2 Forbindelse med betalingsleverandør kan være nede. Kunden kan prøve igjen eller bruke en annen betalingsmåte. Følg rutinebeskrivelse for kategori 2-feil.',
    noTitle: 'Betaling feilet',
    noText:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment error',
    enText:
      'Purchase not completed. Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '965-4-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Tiltak: Sjekk om betalingsavtale ligger i Kundesenterportal. Hvis ikke, må kunden legge inn kort eller Strex betalingsavtale på nytt. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'Betaling feilet',
    noText:
      'Kjøpet er ikke gjennomført. Prøv igjen. Om problem vedvarer, legg inn bankkort på nytt eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment error',
    enText:
      'Purchase not completed. Try again. If the problem persists, re-register your card or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '967-4-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Gjelder kjøp med betalingsavtale (AutoPay) hos Nets (?) eller Strex. Tiltak: Det kan være flere årsaker. Spør kunde om bankkort er gyldig, om det er dekning på konto, om han har byttet bankkortet som er registrert i appen, er kortet gyldig?  Er alt dette ok, følg videre rutinebeskrivelsen for kategori  2-feil.',
    noTitle: 'Betaling avvis',
    noText:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Om problem vedvarer, bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment rejected',
    enText:
      'Purchase not completed. Try again. If the problem persists, re-register your card or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '995-4-8',
    text: '',
    note: '',
    action:
      'Kategori 2 Mulig tiltak: Start kjøpsprosessen på nytt. Avinstallering av app kan hjelpe, noe kan ha hengt seg opp. Be kunde ev. avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger.Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.',
    enTitle: 'An error has occured',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '995-4-9',
    text: '',
    note: '',
    action:
      'Kategori 2 Mulig tiltak: Start kjøpsprosessen på nytt. Avinstallering av app kan hjelpe, noe kan ha hengt seg opp. Be kunde ev. avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger.Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occured',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '995-4-43',
    text: '',
    note: '',
    action:
      'Kategori 2 Mulig tiltak: Start kjøpsprosessen på nytt. Avinstallering av app kan hjelpe, noe kan ha hengt seg opp. Be kunde ev. avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger.Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'An error has occured ',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '1001-x-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Kjøp er avvist på grunn av for mange kjøp i gitt periode. Ruter har satt reglene for betalingskort - for å unngå svindel. For andre betalingsmåter er det leverandørenes egne regler som gjelder. Tiltak: Kunden kan forsøke et annet betalingsmiddel. (Vi vil ikke oppfordre til dette i selve feilmeldingen pga svindelrisiko.)',
    noTitle: 'Betaling avvist',
    noText:
      'Valgte betalingsmåte er brukt ved for mange kjøp innen en gitt periode. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment rejected',
    enText:
      'Selected payment method is used for too many purchases within a given period. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '1067-4-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Gjelder kjøp med betalingsavtale (AutoPay). Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør eller en feil i interne systemer. Kunden kan prøve igjen eller bruke en annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori  2-feil.  ',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment failed',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '1167-4-x',
    text: '',
    note: '',
    action:
      'Kategori 2  Gjelder kjøp med betalingsavtale (AutoPay). Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør eller en feil i interne systemer. Kunden kan prøve igjen eller bruke en annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori  2-feil. ',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Payment failed',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)  '
  },
  {
    id: '1964-4-x',
    text: 'Strex har en beløpsgrense per måned. Denne vises dersom bruker er over beløpsgrensen for denne måneden.',
    note: '',
    action:
      'Kategori 2 Tiltak: Strex har en beløpsgrense per måned. Denne vises dersom bruker er over beløpsgrensen for denne måneden. Kunden må velge en annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Beløpsgrense oversteget',
    noText:
      'Dette kjøpet vil innebære at du overstiger beløpsgrensen til Strex. Velg en annen betalingsmåte. (Feilkode xxx-x-x og app-ID xxxx-xxxx-xx.',
    enTitle: 'Amount limit exceeded',
    enText:
      "This purchase exceeds Strex's amount limit. Please choose a different payment method. (Error code xxx-x-x and App ID xxxx-xxxx-xx.)"
  },
  {
    id: '400-4-x',
    text: 'Feil i input. Denne vil komme ved feil verdier i input.',
    note: 'Ingen hensikt å prøve igjen.',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '433-4-1',
    text: 'Request objekt mangler',
    note: 'Ingen hensikt å prøve igjen.',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '410-4-x',
    text: 'Feil pris',
    note: 'Andorid: Viser toast med tekst fra feilmeldingsfil.',
    action: 'Viser toast og henter priser på nytt.',
    noTitle: 'Feil pris',
    noText: 'Prisen er endret. Bekreft igjen.',
    enTitle: 'Incorrect price',
    enText: 'The price has changed. Please confirm again.'
  },
  {
    id: '963-4-x',
    text: 'Feil passord',
    note: 'Kjøp billett med nytt betalingskort men passord lagret.',
    action: 'Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'Feil passord',
    noText: 'Prøv igjen.',
    enTitle: 'Incorrect password',
    enText: 'Try again.'
  },
  {
    id: '964-4-x',
    text: 'Feil passord for mange ganger',
    note: '',
    action: 'Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'For mange forsøk',
    noText: 'Passordet er sperret. Velg nytt passord',
    enTitle: 'Too many attempts',
    enText: 'The password has been blocked. Select a new password.'
  },
  {
    id: '995-4-x',
    text: 'Fant ikke sesjonsdata.',
    note: 'Vi lagrer data underveis. Må finne tilbake fra tidligere kall. Tar for lang tid mellom kall. Prøv på nytt.',
    action:
      'Kategori 2 Mulig tiltak: Start kjøpsprosessen på nytt. Avinstallering av app kan hjelpe, noe kan ha hengt seg opp. Be kunde ev. avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)   '
  },
  {
    id: '1001-x-x',
    text: '',
    note:
      'Kjøp er avvist på grunn av for mange kjøp i gitt periode. Bruker må velge annen betalingsmåte eller bankkort',
    action:
      'Kategori 2 Kjøp er avvist på grunn av for mange kjøp i gitt periode. Ruter har satt reglene for betalingskort - for å unngå svindel. For andre betalingsmåter er det leverandørenes egne regler som gjelder. Tiltak: Kunden kan forsøke et annet betalingsmiddel. (Vi vil ikke oppfordre til dette i selve feilmeldingen pga svindelrisiko.)',
    noTitle: 'Betaling avvist',
    noText:
      'Valgte betalingsmåte er brukt ved for mange kjøp innen en gitt periode. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Payment rejected',
    enText:
      'Selected payment method is used for too many purchases within a given period. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '971-4-5',
    text: ' Fant ikke sesjonsdata',
    note:
      'Brukt for lang tid i Nets(?)-siden (over 1 time eller teknisk feil). Prøv på nytt. Kort ikke belastet. (kun reservert). (Må tilbake til start).',
    action:
      'Kategori 2 Kunden har brukt for lang tid til å betale eller teknisk feil. Tiltak: Må starte på nytt. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '972-4-5',
    text: 'OrderRef i request er ulik hva som er i databasen',
    note: 'Kodefeil - nesten utenkelig. Korrupte data. Kort ikke belastet, kun reservert. Gjennomfør nytt kjøp.',
    action: 'Kategori 2 Tiltak: Be kunden prøve å kjøpe på nytt. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '973-4-5',
    text: 'AgreementID er satt og er ulik hva som er i databasen',
    note: 'Kodefeil - nesten utenkelig. Korrupte data. Kort ikke belastet, kun reservert. Gjennomfør nytt kjøp.',
    action:
      'Kategori 2 Tiltak: Be kunden prøve å kjøpe på nytt. Kan hjelpe å slette betalingsavtale og legge inn på nytt. Gjelder Nets(?) og Strex. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '974-4-68',
    text:
      'CompletePayment feilet (betaling er ikke foretatt) Kan være mange grunner som feil csv kode, for lite penger på konto osv',
    note:
      'Betaling feilet. Kort ikke belastet. Kontakt bank. Legg inn kort på nytt. Kortnr. kan være feil om det bygd opp riktig. Sjekk kortopplysninger.',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det kan være flere årsaker. Spør kunde om bankkort er gyldig, om det er dekning på konto, om kortnummer/utløpsd ato/CVC-kode er korrekt lagt inn, er kortet gyldig? Det kan også være en feil i de andre betalingsleverandør ene sine systemer. Er alt dette ok, følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Betaling avvist',
    noText:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Om problem vedvarer, bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Payment rejected',
    enText:
      'Purchase not completed. Any reserved payment will be credited automatically. If the problem persists, use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '977-4-x',
    text: '',
    note: 'Betaling reservert, men ikke belastet.',
    action: 'Kategori 2 NOD kan være nede. Tiltak: Prøv igjen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Any reserved payment will be credited automatically. Try again. If the problem persists, use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '1074-4-5',
    text: '',
    note:
      'En eller annen feil har oppstått, f.eks. nettverksbrudd eller database feil. Denne vil komme ved CompletePayment – etter at kunden har lagt inn betalingsinformasjo n og trykket OK.',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør eller en feil i Ruters interne systemer. Kunden kan prøve igjen eller bruke annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori  2-feil.',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Payment error',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '1174-4-5',
    text: '',
    note:
      'Timeout i kall mot Nets(?). Denne vil komme ved CompletePayment – etter at kunden har lagt inn betalingsinformasjo n og trykket OK.',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør (timeout). Prøv igjen vil sannsynligvis fungere. Hvis ikke, følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Payment error',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '400-4-7',
    text: 'Capture av betaling feilet i Nets(?)',
    note: '',
    action:
      'Kategori 2 Beløp er reservert, men ikke belastet. Skyldes vanligvis avslag fra betalingsleverandør . Tiltak: Be kunde prøve igjen eller bruke en annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Betaling feilet',
    noText:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .',
    enTitle: 'Payment error',
    enText:
      'Purchase not completed. Any reserved payment will be credited automatically. Try againg or use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '980-4-7',
    text: 'Er gjort for mange forsøk på å hente billett',
    note:
      'Dette er billetter med ordrestatus 7 Her krediteres vel kunden manuelt dagen etter basert på lister. Billett krediteres om kunden har kjøpt ny billett innenfor 30 minutter. Prosess er automatisert. kreditering skjer førstkommende natt 02.30. Hvis eventuell betaling ikke er kreditert, ta kontakt ..... for manuell kreditering.',
    action:
      'Kategori 2 Aktuelt scenario: Kunden oppdager at han har fått 2 trekk på konto, og kan ikke se at den først kjøpte billetten er kreditert. Tiltak i Kundesenterportal: Sjekk app-ID, finn ordrene og se om den ene billetten er forsøkt kreditert. Hvis den har status Kreditering feilet, krediter ordren. Har den status Kreditert, be kunden avvente overføring fra bank (kan ta inntil 10 dager). Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Billett ikke levert',
    noText:
      'Billett er ikke levert. Gjennomfør kjøpet på nytt. Eventuell dobbeltbetaling krediteres automatisk. (Feilkod e xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Ticket not issued',
    enText:
      'Ticket not delivered. Please try your purchase again. Any double payment will be credited automatically. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '981-4-7',
    text: 'Henting av billett fra NOD feilet',
    note: 'Teknisk feil i NOD eller nettverk mellom baksystem og IO',
    action:
      'Kategori 2 Henting av billett fra NOD feilet. Tiltak: Kunde må kjøpe ny billett. Betaling er ikke gjennomført, og vil bli tilbakeført automatisk til kunden etter x timer/dager (avhengig av betalingsleverandør /bank). Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Billett ikke levert',
    noText:
      'Billett er ikke levert. Gjennomfør kjøpet på nytt. Eventuell dobbeltbetaling krediteres automatisk. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .',
    enTitle: 'Ticket not issued',
    enText:
      'Ticket not delivered. Please try your purchase again. Any double payment will be credited automatically. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '982-4-7',
    text:
      'Billett er utløp og kan ikke hentes mer. Merk at det skal være mulig å hente billetten i en time etter utløp',
    note: '',
    action: 'Ingen feilmelding billett endrer status.',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '983-4-7',
    text: 'Billetten er kansellert',
    note: '',
    action: 'Ingen feilmelding billett endrer status.',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '984-4-7',
    text: 'Dato for oppdatering av billett er frem i tid',
    note:
      'Klokkefeil vil slå inn. Teoretisk. Generell feilmelding. Prøv igjen klokkeslett. Kan få beskjed fra integrasjonslaget om når oppdatering er tilgjengelig. Vanlig feilmelding med prøv igjen.',
    action:
      'Kategori 2 Alvorlig feil i appen. (Appen prøver å oppdatere for tidlig.) Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.  ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNav n og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on  start-up, or later under Settings. If you choose to restore from Settings, you   will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '995-4-7',
    text: '',
    note: '',
    action:
      'Kategori 2 Tiltak: Sjekk om kunde er dobbeltbelastet. Er ikke kunden kreditert, sjekk årsak. Følg rutinebeskrivel sen for kategori 2-feil.',
    noTitle: 'Billett ikke levert',
    noText:
      'Billett er ikke levert. Gjennomfør kjøpet på nytt. Eventuell dobbeltbetaling krediteres automatisk. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .',
    enTitle: 'Ticket not issued',
    enText:
      'Ticket not delivered. Please try your purchase again. Any double payment will be credited automatically. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)   '
  },
  {
    id: '400-4-6',
    text: 'Feil input',
    note: 'Logges, men kunden får ingen feilmelding. Ingen tekst.',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '400-3-7',
    text: 'Feil input ',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      ' Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '980-3-7',
    text: 'Er gjort for mange forsøk på å hente billet',
    note:
      'Vil ikke fungere å prøve igjen siden oppdatering er blokkert. Kunden må kjøpe ny billett. Mobilen faller ut. Prøver å sende billett 5 ganger ila. 3 min. Så stopp (mulig svindelforsøk). Får ikke billett. Kontakt kundeservice kreditere og kjøpe ny billett.',
    action:
      'Kategori 2 Gjelder billetter med endring av starttid. Aktuelt scenario: Kunden oppdager at han har fått 2 trekk på konto, og kan ikke se at den først kjøpte billetten er kreditert. Tiltak i Kundesenterportal: Sjekk app-ID, finn ordrene og se om den ene billetten er forsøkt kreditert. Hvis den har status Kreditering feilet, krediter ordren. Har den status Kreditert, be kunden avvente overføring fra bank (kan ta inntil 10 dager). Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Billett ikke levert',
    noText:
      'Billett er ikke levert. Gjennomfør kjøpet på nytt. Eventuell dobbeltbetaling krediteres automatisk. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Ticket not issued ',
    enText:
      'Ticket not delivered. Please try your purchase again. Any double payment will be credited automatically. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '981-3-7',
    text: 'Henting av billett fra NOD feilet',
    note:
      'Feil med nettverk i baksystem. Endre start - prøv igjen senere. Ev. kanseller, og kjøp på nytt. Automatisk oppdatering vil app prøve nytt av seg selv. Ingen feilmelding.',
    action:
      'Kategori 1 Alvorlig feil i baksystem. Tiltak: Sjekk om kunden har prøvd igjen. Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 1-feil. Hvis dette gjelder billett som er kjøpt med utsatt oppstart, og kunden har forsøkt å endre starttidspunkt, vil gammel billett fortsatt være gyldig og kunden kan endre startdato senere.',
    noTitle: 'En feil har oppstått ',
    noText: 'Prøv igjen senere. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText: 'Try again later. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '982-3-7',
    text:
      'Billett er utløp og kan ikke hentes mer. Merk at det skal være mulig å hente billetten i en time etter utløp',
    note: 'Prøver å oppdatere utløpt billett, her skal appen oppdatere fra gyldig til utløpt. Ingen feilmelding.',
    action: 'Ingen feilmelding billett endrer status til utløpt',
    noTitle: '',
    noText: '',
    enTitle: ' ',
    enText: ''
  },
  {
    id: '983-3-7',
    text: 'Billetten er kansellert',
    note: 'Billett skal gå til kansellert (ingen feilmelding), men endring av billettbilde. ',
    action: 'Ingen feilmelding ',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '984-3-7',
    text: 'Dato for oppdatering av billett er frem i tid',
    note: 'Teoretisk. App vil prøve igjen senere. Ingen feilmelding',
    action: 'Ingen feilmelding',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '995-3-7',
    text: 'Fant ikke sesjonsdata',
    note:
      'Ved oppdatering av billett, så vil ikke dette skje hvis det ikke har oppstått en alvorlig feil i baksystem. Dersom billett blir kansellert/kreditert i Kundesenterportalen, vil integrasjonslaget gi beskjed om dette og billetten endre status i appen. Dersom 995 skjer ved overføring (rekonstruksjon), så kan 995 oppstå dersom  det gjøres flere forsøk på å overføre fra A til B og B prøver å hente opp første forsøket. Av sikkerhetshensyn, er da sesjonsdata slettet så vi ikke risikerer at noen kan hente ut flere billetter.',
    action:
      'Kategori 2 Tiltak: Sjekk at kunden har prøvd å endre starttidspunkt igjen. Overfør eller krediter ev. restverdi om kunden har aktiv billett. Ved kreditering må kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, ta kontakt med kundesenter. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx)',
    enTitle: 'An error has occurred',
    enText: 'Try again. If the problem persists, please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx. '
  },
  {
    id: '400-3-6',
    text: 'Feil input',
    note: 'Logges, men kunden får ingen feilmelding. Ingen tekst. ',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '400-5-7',
    text: 'Feil input',
    note:
      'Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil. Ingen feilmelding',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '980-5-7',
    text: 'Er gjort for mange forsøk på å hente billett',
    note:
      'Mobilen faller ut. Prøver å sende billett 5 ganger ila. 3 min. Så stopp (mulig svindelforsøk). Får ikke oppdatert billett. Kontakt kundeservice kreditere og kjøpe ny billett.',
    action: 'Ingen feilmelding.',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '981-5-7',
    text: 'Henting av billett fra NOD feilet',
    note:
      'Feil med nettverk i baksystem. Endre start - prøv igjen senere. Ev. kanseller, og kjøp på nytt. Automatisk oppdatering vil app prøve nytt av seg selv. Ingen feilmelding.',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '982-5-7',
    text:
      'Billett er utløp og kan ikke hentes mer. Merk at det skal være mulig å hente billetten i en time etter utløp',
    note: 'Prøver å oppdatere utløpt billett, her skal appen oppdatere fra gyldig til utløpt. Ingen feilmelding.',
    action: 'Ingen feilmelding billett endrer status til utløpt',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '983-5-7 ',
    text: 'Billetten er kansellert',
    note: 'Billett skal gå til kansellert (ingen feilmelding), men endring av billettbilde. (Function as designed).',
    action: 'Ingen feilmelding ',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '984-5-7',
    text: 'Dato for oppdatering av billett er frem i tid',
    note: 'Teoretisk. App vil prøve igjen senere. Ingen feilmelding',
    action: 'Ingen feilmelding',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '995-5-7',
    text: 'Fant ikke sesjonsdata',
    note:
      'Ved oppdatering av billett, så vil ikke dette skje hvis det ikke har oppstått en alvorlig feil i baksystem. Dersom billett blir kansellert/kreditert i Kundesenterportal, vil integrasjonslaget gi beskjed om dette og billetten endre status i appen. Dersom 995 skjer ved overføring (rekonstruksjon), så kan 995 oppstå dersom  det gjøres flere forsøk på å overføre fra A til B og B prøver å hente opp første forsøket. Av sikkerhetshensyn, er da sesjonsdata slettet så vi ikke risikerer at noen kan hente ut flere billetter.',
    action: 'Ingen feilmelding',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '400-5-6',
    text: 'Feil input',
    note: 'Logges, men kunden får ingen feilmelding. Ingen tekst.',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '201-x-3',
    text: 'Fant ikke ordrer',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil. ',
    noTitle: 'Kansellering av billett feilet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'Cancellation of ticket failed',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx'
  },
  {
    id: '204-x-3 ',
    text: 'Feil NOD order status',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil. ',
    noTitle: 'Kansellering av billett feilet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'Cancellation of ticket failed',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '206-x-3',
    text: 'Feil ordre status',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil.',
    noTitle: 'Kansellering av billett feilet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'Cancellation of ticket failed',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '406-x-3 ',
    text: 'Feil OrderID (TicketID)',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil. ',
    noTitle: 'Kansellering av billett feilet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'Cancellation of ticket failed',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '409-x-3',
    text: 'InstanceID er ulik det som ligger i ordren',
    note: '',
    action:
      'Kategori 2 Prøver å kansellere en billett som er kjøpt med en annen telefon. (Instance ID er ulik det som ligger i ordren (kun for RuterBillett)). Tiltak: Overfør eller krediter ev. restverdi om kunden har aktiv billett. Ved kreditering må kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'An error has occurred',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '409-38-3',
    text: 'Prøver å kanseller billett som allerede er hentet ned til en annen telefon.',
    note: '',
    action:
      'Kunden prøver å kansellere billett som allerede er hentet ned til en annen telefon. Kun informasjon til kunden som har kjøpt billett til annen telefon, og som prøver å kansellere denne etter at den er tatt i bruk av den telefonen som har hentet ned billetten.',
    noTitle: 'Billetten kan ikke kanselleres',
    noText: 'Billetten er allerede tatt i bruk.',
    enTitle: 'Cancellation of ticket not permitted',
    enText: 'Ticket is already in use.'
  },
  {
    id: '850-x-3',
    text: 'Kansellering av ordre er ikke tillatt',
    note: '',
    action:
      'Kategori 2 Tiltak: Sjekk ordre i Kundesenterportalen for å finne ut hvorfor det ikke er tillatt å kansellere billetten. Krediter ev. restverdi om kunden har aktiv billett. Ved kreditering må kunden kjøpe ny billett. Følg videre rutinebeskrivelse n for kategori 2-feil. ',
    noTitle: 'Ikke tillatt å kansellere billet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'Cancellation of ticket not permitted',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '941-x-3',
    text: 'Kansellering av ordre i NOD feilet',
    note: '',
    action: 'Vises ikke til kunde. Ingen tekst. ',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '978-x-3',
    text:
      'Kreditering av ordre feilet i Nets(?) eller Vipps. Kan være mange grunner som vi ikke har oversikt over eller vet grunnen til',
    note: '',
    action:
      'Kategori 2 Tilbakebetaling til belastet betalingsmåte har gått feil. Tiltak: Sjekk at billett er kansellert. Sørg for kreditering i Kundesenterportalen. Følg videre rutinebeskrivelse n for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Tilbakebetaling kunne ikke gjennomføres. Ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx -xx.',
    enTitle: 'An error has occurred',
    enText:
      'Your payment could not be credited. Please contact RK177. State error code xxx-x-x   and app ID xxxx-xxxx-xx.'
  },
  {
    id: '983-x-3',
    text: 'Billett er allerede kansellert',
    note: '',
    action: 'Oppd. billett som er kansellert',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '406-3-4',
    text: 'Feil OrderID',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'Kansellering av billett feilet',
    noText: 'Vennligst ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx',
    enTitle: 'Cancellation of ticket failed',
    enText: 'Please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '409-3-4',
    text: 'Mangler InstanceID (skal i teorien ikke kunne skje siden det allerede er gjort sjekker)',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. (Mangler InstanceID) Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'En feil har oppstått ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '983-3-4',
    text: 'Billett er kansellert',
    note: 'Skal endre status til kansellert.',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '201-3-10',
    text: 'Fant ikke ordrer',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Endring av starttidspunkt feilet',
    noText: 'Prøv igjen. Om problem vedvarer, kanseller og kjøp ny billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Changed start time failed',
    enText:
      'Try again. If the problem persists, please cancel and purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '204-3-10',
    text: 'Feil NOD order status',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Endring av starttidspunkt feilet',
    noText: 'Prøv igjen. Om problem vedvarer, kanseller og kjøp ny billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Changed start time failed ',
    enText:
      'Try again. If the problem persists, please cancel and purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '206-3-10',
    text:
      'Feil ordre status eller at oppdatering av startdato er gjort mer en 3 ganger hvor ny start dato ikke er endret',
    note: '',
    action:
      "Kategori 2 Kunden har endret starttidspunkt for mange ganger (flere enn tre). Tiltak: Kunden kan selv kansellere billetten og eventuelt kjøpe ny. Kunden kan også velge 'start billett nå'. Følg videre rutinebeskrivelsen for kategori 2-feil. ",
    noTitle: 'Endring av starttidspunkt ikke lenger tillat',
    noText: 'Endring av starttidspunkt ikke lenger tillat',
    enTitle: 'Changing start time no longer permitted',
    enText:
      'You can start the ticket now or cancel it and perhaps purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '406-3-10',
    text: 'Feil OrderID (TicketID)',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Endring av starttidspunkt feilet',
    noText: 'Prøv igjen. Om problem vedvarer, kanseller og kjøp ny billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Changed start time failed ',
    enText:
      'Try again. If the problem persists, please cancel and purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '409-3-10',
    text: 'InstanceID er ulik det som ligger i ordren',
    note: '',
    action:
      'Kategori 2 Noter kundens feilkode og app-ID. Krediter billetten og be kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Endring av starttidspunkt feilet',
    noText: 'Prøv igjen. Om problem vedvarer, kanseller og kjøp ny billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Changed start time failed ',
    enText:
      'Try again. If the problem persists, please cancel and purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '851-3-10',
    text:
      'Oppdatering av gyldighetsperiode er ikke tillatt. Grunn kan her også være at det er gjort 3 forsøk på å endre startdato',
    note: '',
    action:
      "Kategori 2 Kunden har endret starttidspunkt for mange ganger (flere enn tre). Tiltak: Kunden kan selv kansellere billetten og eventuelt kjøpe ny. Kunden kan også velge 'start billett nå'. Følg videre rutinebeskrivelsen for kategori 2-feil.",
    noTitle: 'Endring av starttidspunkt ikke lenger tillatt ',
    noText:
      'Du kan starte billetten nå, eller kansellere den og eventuelt kjøpe ny billett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Changing start time no longer permitted ',
    enText:
      'You can start the ticket now or cancel it and perhaps purchase a new ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '977-3-10',
    text: 'Sending av ordrer til NOD feilet',
    note: '',
    action:
      'Kategori 1 Alvorlig feil i baksystem. Tiltak: Sjekk om kunden har prøvd igjen. Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 1-feil. Hvis dette gjelder billett som er kjøpt med utsatt oppstart, og kunden har forsøkt å endre starttidspunkt, vil gammel billett fortsatt være gyldig og kunden kan endre startdato senere.',
    noTitle: 'Billett ikke levert ',
    noText: 'Prøv igjen senere. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Ticket not issued ',
    enText: 'Try again later. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '983-3-10',
    text: 'Billett er allerede kansellert',
    note: '',
    action: '',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '951-23-15',
    text: 'Mangler pin kode',
    note:
      'Vil komme dersom baksystemet ikke kjenner til eller finner App instansen. Liten risiko for at det kan skje, alvorlig feil, her må app reinstalleres.',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '962-23-15',
    text: 'Initalisering av betaling feilet',
    note: '',
    action:
      'Kategori 2 Feil hos betalingsleverandør , banker eller linjer. Tiltak: Be kunden prøve igjen. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Try again. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)',
    enText: ''
  },
  {
    id: '963-23-15',
    text: "gå til profilsiden, 'betaling og passord', 'erstatt kort'. Tast feil passord mange ganger.",
    note: '',
    action: 'Ingen feilkode i melding. Kunden løser problemet selv.  ',
    noTitle: 'Feil passord',
    noText: 'Prøv igjen.',
    enTitle: 'Incorrect password',
    enText: 'Try again.'
  },
  {
    id: '964-23-15',
    text: '',
    note: '',
    action: 'Ingen feilkode i melding. Kunden løser problemet selv. ',
    noTitle: 'Passordet er sperret',
    noText: 'Velg nytt passord',
    enTitle: 'The password has been blocked',
    enText: 'Select a new password.'
  },
  {
    id: '997-23-15',
    text: 'Current url er tom',
    note: '',
    action:
      'Kategori 1 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '400-23-14',
    text: 'Feil input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Dette gjelder ved lagring av kredittkort hos PayEx i profilen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '971-23-14',
    text: 'Fant ikke sesjonsdata',
    note: '',
    action:
      'Kategori 2 Tiltak: Be kunden prøve igjen. Alternativt be kunden legge inn betalingsavtale i forbindelse med kjøp. Hjelper ikke det, må kunden avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, use another payment method. (Error code xxx-x-x, App ID xxxx-xxxx-xx.) '
  },
  {
    id: '972-23-14',
    text: 'OrderRef i request er ulik sesjonsdata',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. (OrderRef i request er ulik sesjonsdata.) Dette gjelder ved lagring av kredittkort hos Nets(?) i profilen. Tiltak: Prøv igjen kan fungere. Hvis ikke, må kunden avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error   code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '973-23-14',
    text: 'AgreementID er ulik sesjonsdata',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under.  (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to  restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '974-23-14',
    text:
      'CompletePayment feilet (betaling er ikke foretatt) Kan være mange grunner som feil csv kode, for lite penger på konto osv',
    note:
      'Betaling feilet. Kort ikke belastet. Kontakt bank. Legg inn kort på nytt. Vedvarende..... Vil det hjelpe å legge inn kort på nytt? Ja., men lite trolig. Kortnr. kan være feil om det bygd opp riktig. Sjekk kortopplysninger.',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det kan være flere årsaker. Spør kunde om bankkort er gyldig, om det er dekning på konto, om kortnummer/utløpsd ato/CVC-kode er korrekt lagt inn, er kortet gyldig? Det kan også være en feil i de andre betalingsleverandør ene sine systemer. Er alt dette ok, følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'Betaling avvist',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Sjekk saldo og kortinfo. Om problem vedvarer, kontakt din bankforbindelse eller prøv en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'Payment rejected',
    enText:
      'Any reserved payment will be credited automatically. Please check your balance and card info. If the problem persists, contact your bank or try another payment method. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '1074-23-14',
    text: '',
    note:
      'En eller annen feil har oppstått, f.eks. nettverksbrudd eller database feil. Denne vil komme ved CompletePayment – etter at kunden har lagt inn betalingsinformasjo n og trykket OK. ',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør eller en feil i Ruters interne systemer. Kunden kan prøve igjen eller bruke annen betalingsmåte. Følg videre rutinebeskrivelsen for kategori  2-feil.',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: '',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x,  App ID xxxx-xxxx-xx.)'
  },
  {
    id: '1174-23-14 ',
    text: '',
    note:
      'Timeout i kall mot Nets(?). Denne vil komme ved CompletePayment – etter at kunden har lagt inn betalingsinformasjo n og trykket OK.',
    action:
      'Kategori 2 Feilmelding kommer etter at kunden har lagt inn betalingsinformasjo n og trykket ok. Tiltak: Det er en feil i kommunikasjonen mot betalingsleverandør (timeout). Prøv igjen vil sannsynligvis fungere. Hvis ikke, følg videre rutinebeskrivelsen for kategori  2-feil. ',
    noTitle: 'Betaling feilet',
    noText:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: '',
    enText:
      'Any reserved payment will be credited automatically. Try again or use another payment method. (Error code xxx-x-x,   App ID xxxx-xxxx-xx.) '
  },
  {
    id: '400-23-16',
    text: 'Feil input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, kan en løsning være å gå til Innstillinger og Endre passord. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, you can try resolving it by selecting Settings and Change Password. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '420-23-16',
    text: 'Fant ikke avtale',
    note: '',
    action:
      'Betalingsavtale kan være slettet i Kundesenterportalen. Hvis avtalen allerede er slettet ser jeg ingen grunn til å informere brukeren om det. Foreslår at vi gjør som i dag, og ikke informerer om dette.',
    noTitle: '',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '976-23-16',
    text: 'Sletting feilet mot PayEx ',
    note: '',
    action:
      'Kategori 2 Sletting av betalingsavtale feilet. Sjekk om betalingsavtale finnes i Kundesenterportal. Tiltak: Prøv igjen kan fungere. Om problem vedvarer, må kunden avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred ',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '997-23-16',
    text: 'Current url er tom',
    note: '',
    action:
      'Kategori 1 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText: '',
    enTitle: '',
    enText: ''
  },
  {
    id: '400-x-17',
    text: 'Feil input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '400-8-18',
    text: 'Feil input (tom eller feil tegn',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Feil input (tom eller feil tegn) Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please   try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error   code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '200-8-29',
    text: 'Fant ikke stopp',
    note: '',
    action:
      'Kategori 1 Fant ikke stoppesteder. Tiltak: Kunden må benytte sonevalget, eller kjøpe billett med en annen betalingsmåte enn RuterBillett.  Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppståt',
    noText:
      'Prøv igjen eller oppgi hvilke soner du skal kjøpe billett for. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Try again or specify the zones you are purchasing a ticket for. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '985-7-20',
    text: 'Info tekst finnes ikke',
    note: '',
    action:
      'Kategori 2 Infotekst under menyvalget Informasjon finnes ikke. Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen senere. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)  ',
    enTitle: 'An error has occurred',
    enText: 'Try again later. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '400-x-23',
    text: 'Input feil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Input-feil gjelder Send kvittering. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error   code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '932-x-23',
    text: 'Sperret',
    note: 'Sending av kvittering til oppgitt e-post adresse er sperret for aktuell (kallende) app',
    action:
      'Kategori 2 Tiltak: Sjekk om sperring av e-post er rett, opphev eventuelt sperre i Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. Sending av kvittering til oppgitt e-post adresse er sperret for aktuell (kallende) app',
    noTitle: 'E-postadresse er sperret',
    noText:
      'Kvittering er ikke sendt. Sjekk at e-postadressen er korrekt. Om problem vedvarer, ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx',
    enTitle: 'E-mail address is blocked',
    enText:
      'A receipt has not been sent. Check that the e-mail address is correct. If the problem persists, please call RK177. State error code xxx-x-x and app ID xxxx-xxxx-xx.'
  },
  {
    id: '200-x-66',
    text: '',
    note: '',
    action:
      'Usynkronisert mellom app og baksystem. Prøv et annet stoppested i fra-feltet. Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'Fant ikke reisestrekning ',
    noText: 'Prøv igjen med et annet stoppested i fra-feltet. ',
    enTitle: 'Route not found',
    enText: 'Please try again by entering an alternative stop in the From display'
  },
  {
    id: '201-x-66 ',
    text: '',
    note: '',
    action:
      'Usynkronisert mellom app og baksystem. Prøv et annet stoppested i til-feltet. Ingen feilkode i melding. Kunden løser problemet selv. ',
    noTitle: 'Fant ikke reisestrekning',
    noText: 'Prøv igjen med et annet stoppested i fra-feltet. ',
    enTitle: 'Route not found',
    enText: 'Please try again by entering an alternative stop in the From display'
  },
  {
    id: '221-x-66',
    text: '',
    note: '',
    action:
      'Usynkronisert mellom app og baksystem. Prøv et annet stoppested i til-feltet. Ingen feilkode i melding. Kunden løser problemet selv. ',
    noTitle: 'Fant ikke reisestrekning',
    noText: 'Prøv igjen med et annet stoppested i fra-feltet. ',
    enTitle: 'Route not found',
    enText: 'Please try again by entering an alternative stop in the From display'
  },
  {
    id: '400-x-66',
    text: 'Input feil',
    note: '',
    action:
      'Kategori 2. Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportalen. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again using another stop or zone. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)  '
  },
  {
    id: '221-x-24',
    text: 'Distanse ble ikke funnet',
    note: '',
    action:
      'Usynkronisert mellom app og baksystem. Prøv et annet stoppested i fra-feltet. Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'Fant ikke reisestrekning ',
    noText: 'Prøv igjen med et annet stoppested i fra-feltet. ',
    enTitle: 'Route not found',
    enText: 'Please try again by entering an alternative stop in the From display'
  },
  {
    id: '400-x-24',
    text: 'Input feil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.   ',
    noTitle: 'En feil har oppståt',
    noText:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)  ',
    enTitle: 'An error has occurred',
    enText:
      'Try again using another stop or zone. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)  '
  },
  {
    id: '201-15-38 ',
    text: '',
    note: '',
    action:
      'Kategori 2 Er relatert til stoppesteder som ikke finnes i baksystem. Kan skje når det er gjort oppdateringer i baksystem og appene enda ikke har oppdatert stoppesteder. Be kunden velge et annet stoppested i samme sone, eller bruke soner i stedet for stoppesteder ved kjøp av billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Finner ikke stoppested',
    noText:
      'Velg et annet stoppested, eller oppgi hvilke soner du skal kjøpe billett for. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Cannot find stop',
    enText:
      'Please choose another stop or specify the zones you are purchasing a ticket for. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '221-15-38',
    text: 'Distanse ble ikke funnet',
    note: '',
    action:
      'Usynkronisert mellom app og baksystem. Prøv et annet stoppested i fra- eller til-feltet. Ingen feilkode i melding. Kunden løser problemet selv.',
    noTitle: 'Fant ikke reisestrekning',
    noText: 'Prøv igjen med et annet stoppested i fra- eller til-feltet.',
    enTitle: 'Route not found',
    enText: 'Please try again by entering an alternative stop in the From or To displays.'
  },
  {
    id: '400-15-38',
    text: 'Input feil ',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, kjøp enkeltbillett. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again using another stop or zone. If the problem persists, please purchase a single ticket. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '251-1-26',
    text: 'Fant ikke produkter',
    note: '',
    action:
      'Kategori 1 Finner ikke produktdefinisjoner. Tiltak: Kjøp billett med en annen betalingsmåte enn billettapp.  Følg videre rutinebeskrivelsen for kategori 1-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '251-4-26',
    text: 'Fant ikke produkter',
    note: '',
    action:
      'Kategori 1 Finner ikke produktdefinisjoner. Tiltak: Kjøp billett med en annen betalingsmåte enn billettapp.  Følg videre rutinebeskrivelsen for kategori 1-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '222-10-27',
    text: 'Feil antall soner i input – input stemmer ikke med distansen som skal være',
    note: '',
    action:
      'Kategori 1 Antall soner stemmer ikke overens med sone fra – til i input. Tiltak: Kjøp billett med en annen betalingsmåte enn billettapp. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '251-10-27',
    text: 'Ingen rader i objektet Profiles (fant ingen billetter)',
    note: '',
    action:
      'Kategori 1 Finner ikke produktdefinisjoner. Tiltak: Kjøp billett med en annen betalingsmåte enn RuterBillett. Følg videre rutinebeskrivelsen for kategori 1-feil. ',
    noTitle: 'En feil har oppstått ',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '400-10-27',
    text: 'Input feil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)  ',
    enTitle:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)  ',
    enText:
      'Try again using another stop or zone. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)  '
  },
  {
    id: '251-9-28',
    text: 'Fant ikke priser',
    note: '',
    action:
      'Kategori 1 Fant ikke priser. Tiltak: Kjøp billett med en annen betalingsmåte enn RuterBillett. Følg videre rutinebeskrivelsen for kategori 1-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '251-4-28',
    text: 'Fant ikke priser',
    note: '',
    action:
      'Kategori 1 Fant ikke priser. Tiltak: Kjøp billett med en annen betalingsmåte enn RuterBillett. Følg videre rutinebeskrivelsen for kategori 1-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn AppNavn. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, use a payment method other than AppNavn. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '201-13-34',
    text: 'Fant ikke brukerprofil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '400-13-34',
    text: 'Feil eller manglende input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '201-13-35',
    text: 'Fant ikke brukerprofil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '201-16-35',
    text: 'Fant ikke brukerprofil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under.  (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '201-31-37',
    text: "Fant ingen billetter som er rekonstruert til 'kallende' telefon",
    note: '',
    action:
      'Kategori 2 Saksbehandler kan ha gjort en feil under overføring. Sjekk i Kundesenterportal om ting er gjort riktig eller om det ikke finnes billetter som kan overføres. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Finner ikke billetter ',
    noText:
      'Det ser ikke ut til at du har billetter som kan gjenopprettes. Prøv igjen. Om problem vedvarer, ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx.',
    enTitle: 'Tickets not found',
    enText:
      'You do not appear to have tickets that can be restored. Try again. If the problem persists, please contact RK177. State error code xxx-x-x and app ID xxxx-xxxx-xx.'
  },
  {
    id: '201-27-36',
    text: 'Fant ingen billetter som kan overføres',
    note: '',
    action:
      'Kategori 2 Saksbehandler kan ha gjort en feil under overføring. Sjekk i Kundesenterportal om ting er gjort riktig eller om det ikke finnes billetter som kan overføres. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'Finner ikke billetter',
    noText:
      'Det ser ikke ut til at du har billetter som kan gjenopprettes. Prøv igjen. Om problem vedvarer, ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx.',
    enTitle: 'Tickets not found',
    enText:
      'You do not appear to have tickets that can be restored. Try again. If the problem persists, please contact RK177. State error code xxx-x-x and app ID xxxx-xxxx-xx.'
  },
  {
    id: '400-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '401-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '402-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '403-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '404-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '405-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '406-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '407-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '408-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '409-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '422-27-36',
    text: 'Feil i input',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billetten selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .) ',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '852-27-36',
    text: 'Overføring av billett ikke lenger tillatt',
    note: 'Maks tillatte overføringer er gjort. En begrensning i dag på 2 overføringer pr. billett.',
    action:
      'Kategori 2 Kunde har forsøkt å gjenopprettet appen mer enn to ganger slik at billetten(e) ikke blir med over. Tiltak: Billett(er) må overføres av kundeservice. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'Billett kan ikke automatisk gjenopprettes ',
    noText:
      'Selvbetjent gjenoppretting av billett er kun mulig to ganger. Ta kontakt med RK177 for gjenoppretting av billett. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx.',
    enTitle: 'Your ticket cannot be restored automatically',
    enText:
      'You may only attempt to restore your ticket twice. Please contact RK177 to restore your ticket. State error code xxx-x-x and app ID xxxx-xxxx-xx'
  },
  {
    id: '995-27-36',
    text: "Feil i sesjons data. F.eks. at kall gjøres to ganger og Integrasjonslaget finner ikke 'cashet' data",
    note: '',
    action:
      'Kategori 2 Skjer i forbindelse med gjenoppretting i appen. Tiltak: Overfør eller krediter ev. restverdi om kunden har aktiv billett. Ved kreditering må kunden kjøpe ny billett. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen. Om problem vedvarer, ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx',
    enTitle: 'An error has occurred',
    enText: 'Try again. If the problem persists, please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '875-x-x ',
    text: 'Maks antall ganger for feiltasting av hentekode satt til 6 ganger.',
    note: '',
    action:
      'Kategori 2 Hentekode er tastet feil mer enn 6 ganger. Be kunden sjekke at han/hun har riktig hentekode, og prøve igjen senere.',
    noTitle: 'For mange forsøk',
    noText:
      'Funksjonen er midlertidig sperret. Sjekk at du har riktig hentekode, og prøv igjen senere. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.',
    enTitle: 'Too many attempts ',
    enText:
      'Function temporarily blocked. Please check that you have the right retrieval code and try again later. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '901-x-x',
    text: 'AppVersion eller AppPLatform mangler',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '902-x-x',
    text: 'AppVersion er ikke et nummer',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.  ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '903-x-x',
    text: 'Ikke godkjent AppPlatform',
    note: '',
    action:
      'Kategori 1 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'AppNavn støtter ikke denne plattformen. Avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'AppNavn does not support this platform. Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '906-x-x',
    text: 'OsVersion mangler',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'AppNavn does not support this platform. Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '907-x-x',
    text: 'InstanceID mangler eller er feil',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'AppNavn does not support this platform. Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '991-x-x',
    text: 'Ulovlige verdier i InstanceID',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '992-x-x',
    text: 'AcceptLanguage inneholder ulovlige verdier',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '993-x-x',
    text: 'OsVersion innheholder ulovlige verdier',
    note: '',
    action:
      'Kategori 2 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrivelsen for kategori 2-feil. ',
    noTitle: 'En feil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx .)',
    enTitle: 'An error has occurred',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '999-x-x',
    text: 'En annen feil.',
    note: '',
    action: 'Kategori 2 Noter kundens feilkode og app-ID. Følg videre rutinebeskrivelsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått',
    noText: 'Prøv igjen. Om problem vedvarer, ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx',
    enTitle: 'An error has occurred',
    enText: 'Try again. If the problem persists, please call RK177. State error code xxx-x-x and App ID xxxx-xxxx-xx.'
  },
  {
    id: '1010-x-x',
    text: '',
    note: '',
    action:
      'Kategori 2 Det har oppstått en feil i RuterBilletts håndtering av fingeravtrykk som passord/identifiserin gsalternativ. Tiltak iOS: Kunden må gå til Innstillinger I RuterBillett-appen, og skru av fingeravtrykk og så skru på igjen. Tiltak Android: Fingeravtrykk skrus av og på igjen fra telefonens Innstillinger ( i ikke RuterBillett-appen). Eksempel Samsung Galaxy 6: Gå til Innstillinger, velg Låse skjerm og sikkerhet, og deretter Fingeravtrykk. Skru av og på igjen. Tiltak Android: Fingeravtrykk skrus av og på igjen fra telefonens Innstillinger ( i ikke RuterBillett-appen). Eksempel Samsung Galaxy 6: Gå til Innstillinger, velg Låse skjerm og sikkerhet, og deretter Fingeravtrykk. Skru av og på igjen. Merk: Kunden har ikke gjort noe feil, og KS bør lede kunden gjennom prosessen.\n\n',
    noTitle: 'En feil har oppstått',
    noText:
      'Deaktiver fingeravtrykk og aktiver på nytt. Ved problemer ta kontakt med RK177. Oppgi feilkode xxx-x-x og app-ID xxxx-xxxx-xx ',
    enTitle: 'An error has occurred',
    enText:
      'Fingerprint must be disabled and re-enabled. If you have a problem, call . State errorRK177 code xxx-x-x and App ID xxxx-xxxx-xx. '
  },
  {
    id: '5010 -Connection timeout',
    text: '',
    note: 'Oppstår hvis integrasjonsla get bruker for lang tid på å svare på en henvendelse',
    action:
      'Kategori 2 Oppstår hvis integrasjonsla get bruker for lang tid på å svare på en henvendelse. Kan også skyldes forbindelse til betalingstjene sten/genereri ng av billett. Tiltak: Prøv igjen vil ofte hjelpe. Annet Wi-Fi, bedre signaler e.l. Følg videre rutinebeskrive lsen for kategori 2-feil. Melding til brukeren: Dårlig eller ingen forbindelse',
    noTitle: 'Tidsavbrudd',
    noText:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.',
    enTitle: 'Timeout',
    enText:
      'Poor or no internet connection. Try another location with a better signal, or switch to another Wi-Fi source. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '5011 - Socket timeout',
    text: '',
    note:
      'Oppstår hvis nettverksforbi ndelsen mellom appen og integrasjonsla get ikke blir opprettet innen tidsfristen på 10 sekunder.',
    action:
      'Kategori 2 Oppstår hvis nettverksforbi ndelsen mellom appen og integrasjonsla get ikke blir opprettet innen tidsfristen på 10 sekunder. Tiltak: Prøv igjen vil ofte hjelpe. Annet Wi-Fi, bedre signaler e.l. Følg videre rutinebeskrive lsen for kategori 2-feil. Melding til brukeren: Dårlig eller ingen forbindelse',
    noTitle: 'Tidsavbrudd',
    noText:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.',
    enTitle: 'Timeout',
    enText:
      'Poor or no internet connection. Try another location with a better signal, or switch to another Wi-Fi source. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '5012 Payment timeout',
    text: '',
    note: 'Oppstår dersom appen ikke får svar fra betalingstjeneste innen 25 sekunder.',
    action:
      'Kategori 2 Oppstår dersom appen ikke får svar fra betalingstjene sten PayEx innen 25 sekunder. Tiltak: Prøv igjen vil ofte hjelpe. Annet Wi-Fi, bedre signaler e.l. Følg videre rutinebeskrive lsen for kategori 2-feil.',
    noTitle: 'Tidsavbrudd',
    noText:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.',
    enTitle: 'Timeout',
    enText:
      'Poor or no internet connection. Try another location with a better signal, or switch to another Wi-Fi source. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '5013 - Server JSON-feil',
    text: '',
    note: 'Oppstår hvis serveren sender uventet data som appen ikke forstår.',
    action: 'Tiltak: Prøv igjen på et senere tidspunkt. Viktig å gi beskjed til Support.',
    noTitle: 'En feil har oppstått ',
    noText: 'Prøv igjen. (Feilkode xxx-x-x og app-ID xxxx-x xxx-xx.) ',
    enTitle: 'An error has occured',
    enText: 'Try again. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '5014 - DB-feil',
    text: '',
    note:
      'Noe uventet har gått feil ved lesing, lagring, sletting eller oppdatering av data i den lokale databasen i appen.',
    action:
      'Kategori 2 Noe uventet har gått feil ved lesing, lagring, sletting eller oppdatering av data i den lokale databasen i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrive lsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenopprettin g fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x, app-ID xxxx-x xxx-xx.) ',
    enTitle: 'An error has occured  ',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x, app ID xxxx-xxxx-xx.) '
  },
  {
    id: '5015 DB-migrering',
    text: '',
    note:
      'Oppstår hvis noe går galt når brukeren starter appen første gang etter en oppgradering. Migrering skjer ved oppgradering av appen. Dersom denne feilen kommer vil det si at det har blitt rot i databasen når man har oppgradert fra en gammel appversjon til en ny.',
    action:
      'Kategori 1 Alvorlig feil i appen. Oppstår hvis noe går galt når brukeren starter appen første gang etter en oppgradering.  Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrive lsen for kategori 2-feil.',
    noTitle: 'Oppgradering feilet',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenopprettin g fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x, app-ID xxxx-x xxx-xx.) ',
    enTitle: 'Upgrade failed ',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '5016 Kryptering feilet',
    text: '',
    note:
      'Lesing eller skriving av beskyttet data (f.eks. billett) feilet. Dette kan skje hvis noen tukler med appen i håp om å kopiere billetter, eller fordi det er en feil i kildekoden',
    action:
      'Kategori 2 Tiltak: Kan evt. avinstallere og installere appen på nytt.  Lesing eller skriving av beskyttet data (f.eks. billett) feilet. Dette kan skje hvis noen tukler med appen i håp om å kopiere billetter, eller fordi det er en feil i kildekoden. Følg videre rutinebeskrive lsen for kategori 2-feil.',
    noTitle: 'En sikkerhetsfeil har oppstått',
    noText:
      'Avinstaller AppNavn og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenopprettin g fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x, app-ID xxxx-x xxx-xx.)',
    enTitle: 'A security error has occured ',
    enText:
      'Please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '5017 -Unknown host/ Connection refused/ UTF-8 encoding not supported/ TCP error',
    text: '',
    note: 'Generell nettv erk- og kommunikasj onsfeil for situasjoner anhvor ingen dre feilkoder passer.',
    action:
      'Katgegori 2 Generell nettverk- og kommunikasj onsfeil for situasjoner hvor ingen andre feilkoder passer. Tiltak: Prøv igjen vil ofte hjelpe. Annet Wi-Fi, bedre signaler e.l. Følg videre rutinebeskrive lsen for kategori 2-feil. ',
    noTitle: 'Nettverksfeil',
    noText:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode xxx-x-x, app-ID xxxx-xxxx-xx.)',
    enTitle: 'Poor or no internet connection',
    enText:
      'Poor or no internet connection. Try another location with a better signal, or switch to another Wi-Fi source. (Error code xxx-x-x, App ID xxxx-xxxx-xx.)'
  },
  {
    id: '5018 - Invalid HTTP response code',
    text: '',
    note: '',
    action:
      'Kategori 2 Oppstår f.eks. hvis serveren har tekniske problemer. Tiltak: Prøv igjen på et senere tidspunkt, eller bruk en annen betalingsmåte . Følg videre rutinebeskrive lsen for kategori 2-feil.',
    noTitle: 'En feil har oppstått ',
    noText: 'Prøv igjen. (Feilkode xxx-x-x og app-ID xxxx-x xxx-xx.)',
    enTitle: 'An error has occured',
    enText: 'Try again.(Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '5020 - App JSON-feil',
    text: '',
    note: '',
    action:
      'Kategori 1 Alvorlig feil i appen. Tiltak: Kunde må avinstallere app, og installere på nytt. Om kunden ikke får gjenopprettet billett selv må den overføres via Kundesenterportal. Følg videre rutinebeskrive lsen for kategori 1-feil.',
    noTitle: 'En feil har oppstått   ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenopprettin g fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-x xxx-xx.) ',
    enTitle: 'An error has occured ',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  },
  {
    id: '5025 mangler data i GAP',
    text: '',
    note: '',
    action:
      'Kategori 1 Feil i produktkonfig urasjonen i baksystem. Prøv igjen kan fungere. Noter kundens feilkode og app-ID. Følg videre rutinebeskrive lsen for kategori 1-feil.  ',
    noTitle: 'En feil har oppstått   ',
    noText:
      'Prøv igjen. Om problem vedvarer, avinstaller AppNavn og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenopprettin g fra Innstillinger, trenger du app-ID-en under. (Feilkode xxx-x-x, app-ID xxxx-x xxx-xx.) ',
    enTitle: 'An error has occured ',
    enText:
      'Please try again. If the problem persists, please uninstall AppNavn and then reinstall it. Automatically restore on start-up, or later under Settings. If you choose to restore from Settings, you will need the app ID below. (Error code xxx-x-x, app ID xxxx-xxxx-xx.)'
  }
];