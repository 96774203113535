import React from 'react';
import './Solved.scss';
import { HelpSolvedIssuesList, HelpSolved } from '../static/data/HelpSolvedIssuesList';

const renderSolved = (issue: HelpSolved) => {
  return (
    <div className="solved">
      <h2>{issue.title}</h2>
      <div className="date">{issue.date}</div>

      {issue.text.length > 0 && (
        <>
          <div className="solved-title">SAK</div>
          <div className="solved-content">{issue.text.map((t) => <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>)}</div>
        </>
      )}
      {issue.comment.length > 0 && (
        <>
          <div className="comment-title">KOMMENTAR / WORKAROUND</div>
          <div className="comment-content">{issue.comment.map((t) => <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>)}</div>
        </>
      )}


    </div>
  );
}

const Solved: React.FC = () => {
  return (
    <div className="help-solved">
      <h1>Løste utfordringer</h1>
      {HelpSolvedIssuesList.map(renderSolved)}
    </div>
  );
}

export default Solved;