import React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { string } from 'prop-types';

function PhoneColumn(props) {
  const { phoneCountryCode, phone } = props;
  let text = `${phoneCountryCode || ''} ${phone || ''}`;

  if (phoneCountryCode && phone) {
    const parsedNumber = parsePhoneNumberFromString(phoneCountryCode + phone);

    if (parsedNumber && parsedNumber.isValid()) {
      text = parsedNumber.formatInternational();
    }
  }

  return (
    <td>
      {text}
    </td>
  );
}

PhoneColumn.propTypes = {
  phoneCountryCode: string.isRequired,
  phone: string.isRequired,
};

export default PhoneColumn;
