import React from 'react';
import { Pagination as RuterPagination } from '@ruter-ds/rds-components';
import { ELASTIC_MAX_RESULT_WINDOW } from '../../constants';
import './Pagination.scss';

interface PaginationProps {
  onPageChange: (pageNumber: number) => void;
  activePage: number;
  totalNumberOfPages: number;
  pageSize: number;
}

const Pagination: React.SFC<PaginationProps> = ({ onPageChange, activePage, totalNumberOfPages, pageSize }) => {

  const setSize = totalNumberOfPages * pageSize;

  let numberOfPagesToShow = totalNumberOfPages;
  let hideLastPageLink = false;

  if (setSize > ELASTIC_MAX_RESULT_WINDOW) {
    numberOfPagesToShow = ELASTIC_MAX_RESULT_WINDOW / pageSize;
    hideLastPageLink = true;
  }

  return (
    <RuterPagination
      onPageChange={onPageChange}
      activePage={activePage}
      totalNumberOfPages={numberOfPagesToShow}
      className={hideLastPageLink ? 'hide-last-page-link' : null}
    />
  );
}

export default Pagination;