import { Container, Icon, Input, Message } from '@ruter-ds/rds-components';
import { ChevronRightIcon, ExclamationCircleIcon, SearchIcon } from '@ruter-ds/rds-icons';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../common/Pagination/Pagination';
import usePaginatedFetch from '../../usePaginatedFetch';
import "./Orders.scss";

interface Order {
  agreeementNumber: string;
  failedTicketCount: number;
  id: string;
  invoiceReference: string;
  mediaType: string;
  nrOfZones: number;
  orderedBy: string;
  passengerCount: number;
  personalReference: string;
  productId: number;
  productTemplateId;
  profileId: number;
  purchaseDate: string;
  requestedTicketCount: number;
  ticketCount: number;
  ticketIds: Array<string>;
  ticketType: string;
  totalPrice: number;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  zones: Array<string>;
}

interface OrderResult {
  matches: Array<Order>;
  totalEntries: number;
}

function renderLoading() {
  return (
    <tr>
      <td colSpan={8}>Laster inn ordre...</td>
    </tr>
  )
}

function renderNoOrders() {
  return (
    <tr>
      <td colSpan={8}>Fant ingen ordre.</td>
    </tr>
  )
}


const Orders: React.FC = () => {
  const { result, loading, page, queryInput, pageSize, setPage, fetchWithQuery, setInputQuery, error } = usePaginatedFetch<OrderResult>("orders");
  const history = useHistory();

  const renderRow = (order: Order) => {
    return (
      <tr key={order.id} onClick={() => history.push(`/billettluke/ordre/${order.id}`)}>
        <td>{moment(order.purchaseDate).format('DD.MM.YYYY HH:mm:ss')}</td>
        <td>{order.orderedBy}</td>
        <td>{`${order.ticketCount}/${order.requestedTicketCount}`}</td>
        <td align="right">{order.totalPrice}</td>
        <td>{order.invoiceReference}</td>
        <td>{order.personalReference}</td>
        <td className="action"><Icon component={<ChevronRightIcon />} /></td>
      </tr>
    )
  }

  const totalNumberOfPages = result ? Math.floor(result.totalEntries / pageSize) + 1 : 1;
  const activePage = page + 1;

  return (
    <Container width="l" className="order-history-page">
      <h1>Ordrehistorikk</h1>
      <div className="search">
        <form onSubmit={(e) => {e.preventDefault(); fetchWithQuery(queryInput)}}>
          <Input
            placeholder={`Filtrer på "bestilt av" eller kommentar`}
            iconRight={<SearchIcon />}
            value={queryInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputQuery(e.target.value)
            }}
            autoFocus={true}
          />
          <button type="submit" className="secondary-btn -blue -fill">Søk</button>
        </form>
      </div>

      {error && !loading && (
        <Message
          skin="danger"
          title="Det skjedde en feil ved henting av ordre"
          icon={<ExclamationCircleIcon />}
        >
        </Message>
      )}
      {!error && (
        <table>
          <thead>
            <tr>
              <th scope="col">Kjøpsdato</th>
              <th scope="col">Bestilt av</th>
              <th scope="col">Antall</th>
              <th scope="col" style={{ textAlign: "right" }}>Pris</th>
              <th scope="col">Fakturaref</th>
              <th scope="col">Kommentar</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && renderLoading()}
            {!loading && result && result.totalEntries === 0 && renderNoOrders()}
            {!loading && result && result.totalEntries > 0 && result.matches.map(renderRow)}
          </tbody>
        </table>
      )}
      <Pagination
        onPageChange={(page) => setPage(page - 1)}
        activePage={activePage}
        totalNumberOfPages={totalNumberOfPages}
        pageSize={pageSize}
      />

    </Container>
  )
}

export default Orders;