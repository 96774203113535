import React from 'react';
import { convertToHumanDate, getFormattedPhoneNumber } from '../common/General';
import { AppSource } from '../app/interfaces';
import { getCreatedDate, getLastUsedDate } from '../common/editing/EditingTableList';

interface ViewAppTableListProps {
  source: AppSource;
}

export const ViewAppTableList: React.FunctionComponent<ViewAppTableListProps> = ({ source }) => {
  // the data in elastic is appearantly wrong
  const countryCode = source.mobilephonenumber || '';
  const phoneNumber = source.mobilephonenumbercountrycode || '';

  const formattedPhone = getFormattedPhoneNumber(`${countryCode}${phoneNumber}`);

  return (
    <div className="view-order-content">
      <ul className="table-list -listing">
        <li>
          <div className="column">App-versjon</div>
          <div className="column">{source.appversionname}</div>
        </li>
        <li>
          <div className="column">Plattform</div>
          <div className="column">
            {source.appplatform}, {source.appplatformversion}
          </div>
        </li>
        <li>
          <div className="column">Opprettet</div>
          <div className="column">{getCreatedDate(source)}</div>
        </li>
        <li>
          <div className="column">Sist brukt</div>
          <div className="column">{getLastUsedDate(source)}</div>
        </li>
        <li>
          <div className="column">Sperret</div>
          <div className="column">{source.appblocked ? 'Ja' : 'Nei'}</div>
        </li>
        <li>
          <div className="column">Tlf.</div>
          <div className="column">
            {formattedPhone}
          </div>
        </li>
        {source.citybike_enduserid && (
          <li>
            <div className="column">Svartelistet</div>
            <div className="column">{source.citybike_blacklist ? 'Ja' : 'Nei'}</div>
          </li>
        )}
      </ul>
    </div>
  )
}
