import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { CitybikeOrderSource, RootState, SearchResult } from '../app/interfaces';
import { openEditing } from '../oos-action-creators';
import { cityBikeSearch } from '../oos-actions';
import { SearchData } from '../oosAjax';
import { FindCityBike } from './findCityBike';
import EndUserIdSearch from './EndUserIdSearch';
import { CityBikeOrders } from './cityBikeOrders';

const CityBikeComponent: React.FC<CityBikeProps> = props => (
  <div>
    <FindCityBike />
    <EndUserIdSearch />
    <CityBikeOrders {...props} />
  </div>
);

const mapStateToProps = (state: RootState) => ({
  cityBikeOrderHits: 'hits' in state.oos.search ? state.oos.search.hits : null,
  loading: !!state.oos.waitingFor
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, null, AnyAction>) => ({
  actions: {
    cityBikeSearch: (data: SearchData) => dispatch(cityBikeSearch(data)),
    refundDialog: (orderLine: SearchResult<CitybikeOrderSource>) =>
      dispatch(
        openEditing({
          type: 'citybikerefund',
          cbOrder: orderLine
        })
      )
  }
});

export interface CityBikeProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {}
export const CityBike = connect(mapStateToProps, mapDispatchToProps)(CityBikeComponent);
