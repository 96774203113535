import React from 'react';
interface LoadingProps {
  text?: string;
  small?: string;
}
export const Loading: React.FunctionComponent<LoadingProps> = ({ text, small }) => (
  <div>
    <div className={'loading' + (small ? ' -small' : '')} />
    {text ? <div className="loading-text">{text}</div> : null}
  </div>
);
