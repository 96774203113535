/* eslint-disable */

import React from 'react';
import './Calendar.scss';

//class Calendar extends Component {
export class Calendar extends React.Component {
  static defaultProps = {
    mode: { start: null, end: null }
  };

  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      value: props.value,
      interval: [],
      clock: props.clock ? [[0, 0], [23, 55]] : null,
      date: new Date(),
      error: null,
      opt: {
        calledBack: false,
        legend: props.legend || 'Dato',
        stamp: new Date().getTime(),
        keyupTimer: 0,
        hideTimer: 0,
        reg: null,
        interval: [],
        controller: 2,
        aDay: 24 * 60 * 60 * 1000,
        month: [
          'Januar',
          'Februar',
          'Mars',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Desember'
        ],
        week: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        fieldA: {
          name: props.fieldNameFrom || 'calendar-A',
          id: 'calendar-A',
          defaultValue: '',
          label: 'Fra dato'
        },
        fieldB: {
          name: props.fieldNameTo || 'calendar-B',
          id: 'calendar-B',
          defaultValue: '',
          label: 'Til dato'
        },
        hours: [
          '00',
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23'
        ],
        minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
        messages: {
          INVALID_DATE_TEXT: 'Ugyldig dato.',
          DATE_IS_LESS_THAN_MIN: 'Ugyldig tidsinterval',
          DATE_IS_GREAT_THAN_MAX: 'Ugyldig tidsinterval'
        }
      }
    };
  }

  componentWillMount() {
    const { now, view } = this.props;
    const { opt } = this.state;

    opt.view = typeof view === 'number' ? parseInt(view, 10) : 1;
    opt.now = typeof now === 'number' ? new Date(now) : new Date();
    opt.nowYear = opt.now.getFullYear();
    opt.nowMonth = opt.now.getMonth();
    opt.nowDate = opt.now.getDate();
    opt.now = new Date(opt.nowYear, opt.nowMonth, opt.nowDate, 0, 0, 0, 0, 0);
    opt.nowTime = opt.now.getTime();
    opt.nowDay = opt.now.getDay();
    opt.max = this.props.max || new Date();
    opt.min = this.props.min;
    opt.maxTime = opt.max ? opt.max.getTime() : -1;
    opt.minTime = opt.min ? opt.min.getTime() : -1;
    opt.interval = [null, null]; //this.props.interval || [null,null];

    if (opt.min) {
      opt.messages['DATE_IS_LESS_THAN_MIN'] = 'Dato kan ikke være før ' + this._convertDateToText(opt.min, null, true);
    }

    if (opt.max) {
      opt.messages['DATE_IS_GREAT_THAN_MAX'] =
        'Dato kan ikke være senere enn ' + this._convertDateToText(opt.max, null, true);
    }
  }

  render() {
    const { disabled, placeholder, shortcuts, singleField } = this.props;
    const { error, focus, clock, opt } = this.state;
    const { fieldA, fieldB, messages, legend } = opt;

    const invalidInputA = (error || {}).index === 0;
    const invalidInputB = (error || {}).index === 1;
    const type =
      'calendar-wrapper -stay-open__' +
      (opt.view > 1 ? ' -multiple' : '') +
      (focus ? ' -on-focus' : '') +
      (error ? ' -has-error' : '') +
      (disabled ? ' -disabled' : '') +
      (shortcuts && shortcuts.length ? ' -has-shortcuts' : '') +
      (singleField ? ' -single-field' : '');
    const maxLength = clock ? '16' : '10';
    const textholder = placeholder || ['', ''];

    return (
      <div className={type}>
        <div className="calendar-cnt">
          <fieldset>
            <legend className="input-label">{legend}</legend>
            <input
              ref="inputA"
              name={fieldA.name}
              id={fieldA.id}
              type="text"
              defaultValue={fieldA.defaultValue}
              maxLength={maxLength}
              placeholder={textholder[0]}
              className={'textfield input-a' + (invalidInputA ? ' -invalid' : '')}
              autoComplete="off"
              spellCheck="false"
              autoCapitalize="off"
              autoCorrect="off"
              aria-label={fieldA.label}
              aria-invalid={invalidInputA}
              disabled={disabled ? true : false}
              onBlur={this._blur}
              onKeyUp={this._keyup}
              onFocus={this._focus}
            />
            <input
              ref="inputB"
              name={fieldB.name}
              id={fieldB.id}
              type="text"
              defaultValue={fieldB.defaultValue}
              maxLength={maxLength}
              placeholder={textholder[1]}
              className={'textfield input-b' + (invalidInputB ? ' -invalid' : '')}
              autoComplete="off"
              spellCheck="false"
              autoCapitalize="off"
              autoCorrect="off"
              aria-label={fieldB.label}
              aria-invalid={invalidInputB}
              disabled={disabled ? true : false}
              onBlur={this._blur}
              onKeyUp={this._keyup}
              onFocus={this._focus}
            />
          </fieldset>
          {(shortcuts || []).length !== 0 && (
            <ul className="shortcut-list">
              {shortcuts.map(data => (
                <li key={'shortcut-' + data.id} className={data.type || ''}>
                  <a
                    href="#"
                    role="button"
                    className="link"
                    onClick={e => {
                      this._clickShortcut(e, data);
                    }}
                  >
                    {data.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
          <div className="calendar-widget" onBlur={this._blur} onFocus={this._focus} onClick={this._click}>
            {this._getCalendar()}
          </div>
        </div>
        {error && (
          <div className="input-error-message" role="alert">
            {messages[error.type] || error.type}
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.mode.unMount = true;
  }

  componentDidMount() {
    const interval = this.props.interval || [null, null];
    let updateField = false;

    if (typeof interval[0] === 'number') {
      this._updateInterval(interval[0], 0);
      updateField = true;
    }

    if (typeof interval[1] === 'number') {
      this._updateInterval(interval[1], 1);
      updateField = true;
    }

    if (updateField) {
      this._updateField();
    }
    setTimeout(() => {
      this.props.mode.unMount = false;
    }, 250);
  }

  /****************************************************************************
    ===  ===
  ****************************************************************************/
  getIntervalDate = () => this.state.interval || [];

  getConfig = () => this.state.opt || {};

  updateConfig = config => {
    if (!config) {
      return;
    }

    const { opt } = this.state;
    let update = false;

    if (config.max) {
      opt.max = config.max;
      opt.maxTime = opt.max.getTime();
      update = true;
    }

    if (config.min) {
      opt.min = config.min;
      opt.minTime = opt.min.getTime();
      update = true;
    }

    if (update) {
      this.setState({ opt: opt });
    }
  };

  setIntervalDate = interval => {
    let out = false;
    if (interval && interval[0] && interval[1]) {
      this._updateInterval(null, 0);
      this._updateInterval(null, 1);

      this._updateInterval(interval[0], 0);
      this._updateInterval(interval[1], 1);
      this._updateField();
      if (this.state.error) {
        this._toggleErrorMessage();
      }
      out = true;
    }
    return out;
  };

  resetIntervalDate = () => {
    this._updateInterval(null, 0);
    this._updateInterval(null, 1);
    this._updateField();
    if (this.state.error) {
      this._toggleErrorMessage();
    }
  };

  hideCalendar = () => {
    if (this.props.mode.unMount) {
      return;
    }
    this.setState({ focus: false });
    this.state.opt.focusTarget = null;
  };

  showCalendar = () => {
    clearTimeout(this.state.opt.hideTimer);
    if (this.state.focus || this.props.mode.unMount) {
      return;
    }

    this.setState({ focus: true });
  };

  _getCloneDate = (data, resetClock) => {
    let stamp = new Date().getTime() + '';

    if (data !== null && typeof data === 'object') {
      stamp = data.getTime() + '';
    } else if ((data + '').match(/^\d+$/)) {
      stamp = data + '';
    }

    const d = new Date(parseInt(stamp, 10));
    return resetClock ? new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0) : d;
  };

  _updateInterval = (stamp, where) => {
    const { opt } = this.state;
    const interval = opt.interval || [];
    const index = where;
    let tmp = null;
    if (stamp !== null && typeof stamp === 'object') {
      stamp = stamp.getTime();
    }

    interval[index] = stamp;
    opt.active = {};
    if (interval.length === 1) {
      if (interval[0] < opt.minTime) {
        interval[0] = opt.minTime;
      } else if (interval[0] > opt.maxTime) {
        interval[0] = opt.maxTime;
      }
      tmp = this._getCloneDate(interval[0], true);
      //opt.active[ interval[0] ] = true;
    } else if (interval[0] === null && interval[1]) {
      if (interval[1] > opt.maxTime) {
        interval[1] = opt.maxTime;
      }
      tmp = this._getCloneDate(interval[1]);
      //opt.active[ interval[1] ] = true;
    } else if (interval[0] && interval[1] === null) {
      if (interval[0] < opt.minTime) {
        interval[0] = opt.minTime;
      }
      tmp = this._getCloneDate(interval[0], true);
      //opt.active[ interval[0] ] = true;
    }

    if (tmp) {
      opt.active[tmp.getTime()] = true;
    }

    if (interval[0] && interval[1]) {
      if (interval[0] < opt.minTime) {
        interval[0] = opt.minTime;
      }
      if (interval[1] > opt.maxTime) {
        interval[1] = opt.maxTime;
      }

      let count = 0;
      if (interval[0] > interval[1]) {
        const holder = interval[1];
        interval[1] = interval[0];
        interval[0] = holder;
      }
      const a = new Date(interval[0]);
      const b = new Date(interval[1]);
      while (a.getTime() <= b.getTime() && count < 1000) {
        tmp = this._getCloneDate(a, true);
        opt.active[tmp.getTime()] = true;
        //opt.active[c.getTime()] = true;
        a.setDate(a.getDate() + 1);
        count++;
      }
    }

    this.setState({ interval: interval });
  };

  /****************************************************************************
    === EVENT ===
  ****************************************************************************/
  _focus = e => {
    const target = e.currentTarget;
    const id = target.getAttribute('id') || '';

    if (target.tagName.match(/input/i)) {
      const aId = this.refs.inputA.getAttribute('id');
      this.state.opt.focusTarget = id === aId ? 0 : 1;
    }

    if (this.props.mode.unMount) {
      this.props.mode.unMount = false;
    }

    this.showCalendar();
  };

  _blur = () => {
    if (this.props.mode.unMount) {
      return;
    }

    clearTimeout(this.state.opt.hideTimer);
    const index = this.state.opt.focusTarget;
    if (!isNaN(index)) {
      this._toggleErrorMessage(true);
    }

    this.state.opt.hideTimer = setTimeout(() => {
      this.hideCalendar();
    }, 200);
  };

  _keyup = e => {
    if (this.props.mode.unMount) {
      return;
    }
    const { opt } = this.state;
    const field = e.target;
    const index = opt.focusTarget;
    const clock = this.state.clock;
    clearTimeout(opt.keyupTimer);
    setTimeout(() => {
      if (isNaN(index) || this.props.mode.unMount) {
        return;
      }

      const date = this._getDateOrErrorByVerifiedText(field.value);
      if (date && clock && clock[index]) {
        clock[index][0] = date.getHours();
        clock[index][1] = date.getMinutes();
        this.setState({ clock: clock });
      }

      const invalid = this._toggleErrorMessage();
      this._updateInterval(date, index);
      this._triggerCallback(true, invalid);
    }, 100);
  };

  _click = e => {
    e.preventDefault();
    let type = 'calendar-item';
    const target = e.target;
    let item = this._getClosestParent(target, type);

    if (item) {
      return this._hasClass(item, '-out-of-month') ? null : this._clickCalendarDate(item);
    }

    type = 'calendar-navigation';
    item = this._getClosestParent(target, type);
    if (item) {
      return this._hasClass(item, '-disabled') ? null : this._clickCalendarNavigation(item);
    }
  };

  _clickCalendarNavigation = item => {
    const stamp = item ? parseFloat(item.getAttribute('data-stamp')) : 0;
    if (!stamp || isNaN(stamp)) {
      return;
    }

    let date = new Date(stamp);
    if (this.state.opt.view > 1) {
      const temp = new Date(this.state.date.getTime());
      const diff = temp.getFullYear() * 12 + temp.getMonth() - (date.getFullYear() * 12 + date.getMonth());
      if (diff > 1 || diff === 0) {
        temp.setMonth(temp.getMonth() - (diff - 1));
        date = temp;
      }
    }
    this.setState({ date: date });
  };

  _clickCalendarDate = item => {
    const stamp = item ? parseFloat(item.getAttribute('data-stamp')) : 0;
    if (!stamp || isNaN(stamp)) {
      return;
    }

    const { opt } = this.state;
    const clock = this.state.clock;
    const isSelected = this._hasClass(item, 'selected');
    let index = null;
    let date = new Date(stamp);
    const controller = opt.controller;
    const interval = opt.interval || [];

    if (interval.length === 1) {
      index = 0;
    } else if (controller === 2 && interval.length === 2) {
      index = isNaN(opt.focusTarget) ? -1 : opt.focusTarget;
      if (index === -1) {
        index = 0; //index = controller.eq(0).is(':focus') ? 0 : 1;
      } else if (index < 0) {
        index = 0;
      } else if (index > 1) {
        index = 1;
      }
      if (isSelected && interval[index] === stamp) {
        date = null;
      }
    }

    if (date && clock && clock[index]) {
      date.setHours(clock[index][0]);
      date.setMinutes(clock[index][1]);
    }

    this._updateInterval(date, index);
    this._updateField();

    if (this.state.error) {
      this._toggleErrorMessage();
    }

    // Auto set focus to to-date-textfield
    if (index === 0 && !this.props.singleField && this.refs.inputB && !this.refs.inputB.value) {
      this.refs.inputB.focus();
    }
  };

  _clickShortcut = (e, data) => {
    if (e) {
      e.preventDefault();
    }

    this._updateInterval(null, 0);
    this._updateInterval(null, 1);

    const now = new Date();
    let dates = [];
    const aDay = 24 * 60 * 60 * 1000;
    if (data.id === '-today') {
      dates = [this._getCloneDate(now, true), now];
    } else if (data.id === '-yesterday') {
      const cloned = this._getCloneDate(now, true);
      const end = new Date(cloned.getTime() - 1);
      now.setDate(now.getDate() - 1);
      dates = [this._getCloneDate(now, true), end];
    } else if (data.id.match(/\-([1-9]+)hour/i)) {
      const matched = data.id.match(/\-([1-9]+)hour/i);
      const n = parseInt(matched[1]);
      const t = now.getTime() - 60 * 60 * 1000 * n;
      const start = new Date(t);
      dates = [start, now];
    } else if (data.id.match(/\-([0-9]+)day(\-now)?/i)) {
      const matched = data.id.match(/\-([0-9]+)day(\-now)?/i);
      const n = parseInt(matched[1]);
      const t = now.getTime() - 24 * 60 * 60 * 1000 * n;
      const start = new Date(t);
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      const end = !n || matched[2] ? now : new Date(start.getTime() + 24 * 60 * 60 * 1000);
      dates = [start, end];
    } else if (data.id.match(/\-([0-9]+)?week(\-now)?/)) {
      const matched = data.id.match(/\-([0-9]+)?week(\-now)?/);
      const count = parseInt(matched[1] || '0');
      const day = now.getDay() || 7;
      const passed = day - 1;
      const start = count
        ? new Date(now.getTime() - (passed * aDay + count * 7 * aDay))
        : new Date(now.getTime() - passed * aDay);
      const end =
        !count || matched[2] ? now : new Date(now.getTime() - (passed * aDay + (count - 1) * 7 * aDay + aDay));
      dates = [this._getCloneDate(start, true), this._getCloneDate(end, true)];
    } else if (data.id.match(/\-([0-9]+)?month(\-now)?/)) {
      const matched = data.id.match(/\-([0-9]+)?month(\-now)?/);
      const count = parseInt(matched[1] || '0');
      const start = this._getCloneDate(now, true);
      let end = this._getCloneDate(now, true);

      start.setDate(1);
      const month = start.getMonth() - count;

      if (count) {
        start.setMonth(month);
      }

      if (count && !matched[2]) {
        end = new Date(start.getTime());
        end.setMonth(month + 1);
        end.setDate(0);
      }
      dates = [start, end];
    } else if (data.id.match(/\-([0-9]+)?year(\-now)?/)) {
      const matched = data.id.match(/\-([0-9]+)?year(\-now)?/);
      const count = parseInt(matched[1] || '0');
      const start = this._getCloneDate(now, true);
      start.setMonth(0);
      start.setDate(1);
      start.setFullYear(start.getFullYear() - count);

      let end = !count || matched[2] ? now : null;
      if (end === null) {
        end = new Date(start.getTime());
        end.setFullYear(end.getFullYear() + 1);
      }

      dates = [start, end];
    }

    this._updateInterval(dates[0], 0);
    this._updateInterval(dates[1], 1);
    this._updateField();
    if (this.state.error) {
      this._toggleErrorMessage();
    }
  };

  _changeTimer = (value, key, pin) => {
    const { opt } = this.state;
    const { clock, interval } = this.state;
    const index = typeof pin === 'number' ? pin : opt.focusTarget || 0;
    if (!clock || !clock[index]) {
      return;
    }

    const number = parseInt(value.replace(/^0/, ''), 10);

    if (key === 'hour') {
      clock[index][0] = number;
      this.setState({ clock: clock });
    } else if (key === 'minute') {
      clock[index][1] = number;
      this.setState({ clock: clock });
    }

    if (!interval[index]) {
      return;
    }
    const date = new Date(parseInt(interval[index] + '', 10));
    date.setHours(clock[index][0]);
    date.setMinutes(clock[index][1]);

    this._updateInterval(date, index);
    this._updateField();
  };

  /****************************************************************************
    === OUTPUT ===
  ****************************************************************************/
  _getCalendar = () => {
    const { opt } = this.state;
    const index = opt.focusTarget || 0;
    const clock = this.state.clock || [];
    const date = this.state.date ? new Date(this.state.date.getTime()) : undefined;
    const loop = (opt.view || 1) - 1;
    const month = date.getMonth();
    const tables = [];

    for (let i = 0; i <= loop; i++) {
      date.setMonth(month - i);
      tables.push(this._getCalendarView(date));
    }

    const reverse = tables.reverse();
    const timer = clock[index]
      ? {
          hourValue: (clock[index][0] < 10 ? '0' + clock[index][0] : clock[index][0]) + '',
          minuteValue: (clock[index][1] < 10 ? '0' + clock[index][1] : clock[index][1]) + '',
          hours: opt.hours,
          minutes: opt.minutes
        }
      : null;

    return (
      <div className="collection">
        <ul>
          {reverse.map((table, i) => {
            const type = 'calendar-holder' + (i === 0 ? ' -first' : '') + (i === loop ? ' -last' : '');
            return (
              <li className={type} key={'calendar-table-' + i}>
                {table}
              </li>
            );
          })}
        </ul>
        {timer && (
          <div className="calendar-timer">
            <div className="timer-cnt">
              <div className="timer-hour">
                <label htmlFor="calendar-hour">Time</label>
                <select
                  id="calendar-hour"
                  name="input-hour"
                  className="select-box"
                  value={timer.hourValue}
                  onChange={e => {
                    this._changeTimer(e.target.value, 'hour', index);
                  }}
                >
                  {timer.hours.map((data, i) => (
                    <option key={'calendar-hour-' + i} value={data}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
              <div className="timer-minute">
                <label htmlFor="calendar-minute">Minute</label>
                <select
                  id="calendar-minute"
                  name="input-minute"
                  className="select-box"
                  value={timer.minuteValue}
                  onChange={e => {
                    this._changeTimer(e.target.value, 'minute', index);
                  }}
                >
                  {timer.minutes.map((data, i) => (
                    <option key={'calendar-minute-' + i} value={data}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  _getCalendarView = date => {
    if (!date) {
      date = new Date();
    }

    const data = this._getCalendarData(date);
    const table = this._getCalendarTable(data);

    return (
      <div className="calendar-view">
        <div className="calendar-header" role="">
          <div className="calendar-name">{data.name}</div>
          <a href="" className="calendar-navigation -previous" title={data.minAria} data-stamp={data.minStamp}>
            <span className="aria-visible">{data.minAria}</span>
          </a>
          <a href="" className="calendar-navigation -next" title={data.maxAria} data-stamp={data.maxStamp}>
            <span className="aria-visible">{data.maxAria}</span>
          </a>
        </div>
        {table}
      </div>
    );
  };

  _getCalendarData = date => {
    if (!date) {
      return '';
    }

    const { opt, clock } = this.state;
    const d = this._getDateAsList(date);
    let c = null;
    const f = new Date(d[0], d[1], 1, 0, 0, 0, 0);
    const monthTimestamp = f.getTime();
    const row = [];
    let count = 0;
    const day = opt.aDay;
    const current = opt.nowTime || 0;
    let i = 0;
    let j = 0;
    const active = opt.active || {};
    const min = opt.minTime || -1;
    const max = opt.maxTime || -1;
    const focus = opt.focusItem || {};

    if (f.getDay()) {
      f.setDate(1 - (f.getDay() - 1));
    } else {
      f.setDate(1 - 6);
    }

    const minStamp = f.getTime() - day - 1000;
    const minMode = min > -1 ? (f.getTime() <= min ? 'disabled' : '') : '';

    for (i = 0; i < 6; i++) {
      const column = [];
      for (j = 0; j < 7; j++) {
        c = new Date(f.getTime());
        c.setDate(c.getDate() + count++);

        const t = c.getTime();
        const n = this._getDateAsList(c);
        const tMax = t;
        let tMin = t;
        if (clock) {
          tMin = t - 1 + 24 * 60 * 60 * 1000;
        }

        const mode =
          '' +
          (max > -1 ? (max < tMax ? ' -disabled' : '') : '') +
          (min > -1 ? (min > tMin ? ' -disabled' : '') : '') +
          (n[1] === d[1] ? '' : ' -out-of-month') +
          (current >= t && current < t + day ? ' -is-today' : '') +
          (active && active[t] ? ' -selected' : '') +
          (focus && focus[t] ? ' -focus' : '') +
          (j === 0 || j === 6 ? ' -end-column' : '');

        column.push({
          row: i + 1,
          aria: this._getCalendarDateAriaText(c),
          name: c.getDate(),
          mode: mode,
          off: mode.match(/out-of-month|disabled/) ? true : false,
          stamp: t,
          date: this._convertDateToText(c, ''),
          selected: active && active[t] ? true : false
        });
      }
      row.push({ column: column });
    }

    c.setDate(1);
    if (c.getMonth() === d[1]) {
      c.setMonth(d[1] + 1);
    }

    const maxStamp = c.getTime() + day;
    const maxMode = max > -1 ? (max < c.getTime() ? 'disabled' : '') : '';

    const loop = opt.week.length;
    const week = [];
    for (i = 0; i < loop; i++) {
      j = i === 0 ? loop - 1 : i - 1;
      week[j] = {
        aria: opt.week[i],
        name: opt.week[i].substring(0, 2)
      };
    }

    const name = opt.month[d[1]] + ' ' + d[0];
    if (f.getMonth() === d[1]) {
      f.setMonth(d[1] - 1);
    }

    const aPrevious = 'Vis ' + this._getCalendarDateAriaText(f, true).toLowerCase();
    const aNext = 'Vis ' + this._getCalendarDateAriaText(c, true).toLowerCase();

    return {
      name: name,
      minMode: minMode,
      minStamp: minStamp,
      minAria: aPrevious,
      maxMode: maxMode,
      maxStamp: maxStamp,
      maxAria: aNext,
      week: week,
      row: row,
      monthStamp: monthTimestamp
    };
  };

  _getCalendarTable = data => {
    if (!data) return;

    const week = data.week.map(function(item, i) {
      return (
        <th role="presentation" key={i + '-h'}>
          <span className="aria-visible">{item.aria}</span>
          <span className="week-name" aria-hidden="true">
            {item.name}
          </span>
        </th>
      );
    });

    const out = data.row.map(function(row, i) {
      const column = row.column.map(function(item, j) {
        const type =
          'calendar-item at-row' + i + (item.mode ? ' ' + item.mode : '') + (item.selected ? ' selected' : '');

        return (
          <td key={j + '-c'} className={item.off ? 'off' : 'on'}>
            <a
              href="#"
              className={type}
              aria-selected={item.selected}
              data-stamp={item.stamp}
              tabIndex={item.off ? '-1' : ''}
            >
              <span className="aria-visible">{item.aria}</span>
              <span aria-hidden="true">{item.name}</span>
            </a>
          </td>
        );
      });
      return (
        <tr role="presentation" key={i + 't'}>
          {column}
        </tr>
      ); //
    });

    return (
      <table className="calendar-table" aria-label="Kalender" role="application">
        <thead>
          <tr className="calendar-table-header calendar-weak" role="presentation">
            {week}
          </tr>
        </thead>
        <tbody>{out}</tbody>
      </table>
    );
  };

  _getDateAsList = date => [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ];

  _getCalendarDateAriaText = (date, onlyMonthAndYear) => {
    if (!date) {
      return '';
    }
    const { opt } = this.state;
    const day = opt.week[date.getDay()];
    const month = opt.month[date.getMonth()];
    return onlyMonthAndYear
      ? month + ' ' + date.getFullYear()
      : day + ', ' + date.getDate() + '. ' + month + ' ' + date.getFullYear();
  };

  _convertDateToText = (date, separator, clock) => {
    const s = typeof separator === 'undefined' || separator === null ? '.' : separator;
    let l = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
    let i = 0;
    for (i = 0; i < l.length; i++) {
      if (l[i] < 10) {
        l[i] = '0' + l[i];
      }
    }

    const out = l.join(s);
    if (!clock) {
      return out;
    }

    l = [date.getHours(), date.getMinutes()];
    for (i = 0; i < l.length; i++) {
      if (l[i] < 10) {
        l[i] = '0' + l[i];
      }
    }
    return out + ' ' + l.join(':');
  };

  _convertTextToDate = (text, wantTimestamp) => {
    const r = /^(0?[1-9]|[12][0-9]|3[01])[\/\-\.](0?[1-9]|1[012])[\/\-\.](\d{4})(\s+(([0-1]\d)|(2[0-3])):([0-5]\d))?$/;
    const t = (text || '').replace(/^\s+/, '').replace(/\s+$/, '');
    const m = t.match(r);
    if (!m) {
      return null;
    }

    const s = [m[3], m[2], m[1], m[5], m[8], '0', '0'];
    for (let i = 0; i < s.length; i++) {
      s[i] = parseInt((s[i] || '').replace(/^0/, '') || '0', 10);
    }
    const date = new Date(s[0], s[1] - 1, s[2], s[3], s[4], s[5], s[6]);
    return wantTimestamp ? date.getTime() : date;
  };

  /************************************************************************/
  _toggleErrorMessage = onBlur => {
    const { error } = this.state;
    const field = [this.refs.inputA, this.refs.inputB];
    let invalid = null;
    for (let i = 0; i < field.length; i++) {
      if (!field[i] || !field[i].value) {
        continue;
      }

      //let date = this._getDateOrErrorByVerifiedText( field[i].value );
      const date = this._convertTextToDate(field[i].value);
      if (date) {
        if (this._isDateLessThanMin(date)) {
          invalid = { index: i, type: 'DATE_IS_LESS_THAN_MIN' };
        } else if (this._isDateGreatThanMax(date)) {
          invalid = { index: i, type: 'DATE_IS_GREAT_THAN_MAX' };
        }
      } else {
        invalid = { index: i, type: 'INVALID_DATE_TEXT' };
      }

      if (onBlur && !error && invalid) {
        this.setState({ error: invalid });
      }

      if (invalid) {
        return invalid;
      }
    }

    if (error && !invalid) {
      this.setState({ error: null });
    }
  };

  _getDateOrErrorByVerifiedText = text => {
    const date = text ? this._convertTextToDate(text) : null;
    if (!date) {
      return null;
    }

    const invalid = this._isDateLessThanMin(date) || this._isDateGreatThanMax(date);
    return invalid ? null : date;
  };

  _isDateLessThanMin = date => {
    if (!date) {
      return false;
    }
    const { opt } = this.state;
    const time = date.getTime();
    return opt.minTime > -1 && time < opt.minTime;
  };

  _isDateGreatThanMax = date => {
    if (!date) {
      return false;
    }
    const { opt } = this.state;
    const time = date.getTime();
    return opt.maxTime > -1 && time > opt.maxTime;
  };

  _updateField = () => {
    const { opt } = this.state;
    const interval = opt.interval || [];
    const clock = this.state.clock ? true : false;
    const field = [this.refs.inputA, this.refs.inputB];
    for (let i = 0; i < field.length; i++) {
      if (!field[i]) {
        continue;
      }

      const text = !interval[i] ? '' : this._convertDateToText(new Date(interval[i]), null, clock);
      field[i].value = text;
    }

    this._triggerCallback();
  };

  _getClosestParent = (dom, what, idTest) => {
    const verify = (parent, type, specific) => {
      if (!parent || (parent.tagName || '').match(/^html/i)) {
        return;
      }

      if (specific) {
        const t = parent.getAttribute('id') == type;
        return t ? parent : verify(parent.parentNode, type, specific);
      }
      return this._hasClass(parent, type) ? parent : verify(parent.parentNode, type, specific);
    };
    return what ? verify(dom, what, idTest) : null;
  };

  _hasClass = (target, type) => {
    if (!target) {
      return;
    }
    const v = target && target.tagName ? target.getAttribute('class') || '' : '';
    const r = new RegExp('(^|\\s+)' + type + '($|\\s+)', 'g');
    return v.match(r) != null;
  };

  _addClass = (target, type) => {
    if (!target) {
      return;
    }

    const v = target.getAttribute('class');
    if (!v) {
      return target.setAttribute('class', type);
    }

    const r = new RegExp('(^|\\s+)' + type + '($|\\s+)', 'g');
    if (!v.match(r)) {
      target.setAttribute('class', this._trim(v + ' ' + type, true));
    }
  };

  _removeClass = (target, type) => {
    if (!target) {
      return;
    }

    const v = target.getAttribute('class');
    if (!v) {
      return;
    }

    const r = new RegExp('(^|\\s+)' + type + '($|\\s+)', 'g');
    if (v.match(r)) {
      target.setAttribute('class', this._trim(v.split(r).join(' '), true));
    }
  };

  _trim = (text, multipleWhiteSpace) => {
    const out = (text || '').replace(/^\s+/, '').replace(/\s+$/g, '');
    return multipleWhiteSpace ? out.replace(/\s+/g, ' ') : out;
  };

  _triggerCallback = (keyup, invalid) => {
    const { callback } = this.props;
    if (typeof callback !== 'function') {
      return;
    }

    callback({
      keyup: keyup,
      from: this.refs.inputA.value,
      to: this.refs.inputB.value,
      stampFrom: this._convertTextToDate(this.refs.inputA.value, true),
      stampTo: this._convertTextToDate(this.refs.inputB.value, true),
      refs: [this.refs.inputA, this.refs.inputB],
      error: invalid || this.state.error
    });
  };
}
