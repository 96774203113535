import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './reducers';

export const configureStore = initialState => {
  const store = createStore(
    createRootReducer(),
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      window.devToolsExtension
        ? window.devToolsExtension({
            actionSanitizer: action =>
              action.type === 'MAP_LOADED' ? { ...action, mapState: '{ Unserializable }' } : action,
            stateSanitizer: state => (state.mapState ? { ...state, mapState: '{ Unserializable }' } : state)
          })
        : f => f
    )
  );

  return store;
};
