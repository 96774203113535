export const HelpGeneralList = [
  {
    title: 'Kategori 1 feil',
    text: [' Generell feil vi kan regne med flere kunder ser, support/operasjonssentralen må kontaktes.']
  },
  {
    title: 'Kategori 2 feil:',
    text: [
      'Du kan ikke gjenskape feilen. Noter feilmelding og hold øye med om det kommer flere like feilmeldinger framover.'
    ]
  },
  {
    title: 'En feil har oppstått',
    text: [
      'De fleste feilmeldingene kundene ser i appen vil ha teksten «En feil har oppstått». Feilen vil enten være lokal eller i baksystemet. For å se om det er en lokal feil kan du selv prøve å gjenskape feilen. Hvis du ikke får samme feilmelding kan du anbefale kunden å avinstallere og installere appen på nytt. Hvis kunde gjeninstallerer må du forsikre deg om at denne får gjenopprettet evt periodebilletter. Hvis du kan gjenskape feilen er det mest sannsynlig en generell feil og du må gå videre med denne som en kategori 1 feil, se nedenfor for beskrivelse av videre håndtering'
    ]
  },
  {
    title: 'Nettverk',
    text: [
      'Feil av typen 50xx-xx-xx er typisk nettverksproblemer, be kunden prøve igjen, bruke et annet/bedre Wi-Fi eller datatrafikk.'
    ]
  },
  {
    title: 'Betaling',
    text: [
      'Feilmeldinger som kommer når kunden skal betale vil ofte være relatert til kommunikasjon mellom baksystemet og betalingsformidleren. Be kunden prøve igjen, det kan være veldig kortvarig problem, eller spør om kunden kan bruke annen betalingsmåte. Prøv å gjenskape feilen. Hvis problemet består kan man kontakte support. Hvis feilmeldingen er 975-x-x er beløpet reservert men ikke belastet, dette skyldes vanligvis avslag fra betalingsleverandør. 981-x-x Betyr at billetten ikke er hentet fra NOD, betalingen er ikke gjennomført og kunden må prøve igjen.'
    ]
  },
  {
    title: 'Kansellering',
    text: [
      'Hvis det er problemer med kansellering av billetter i appen kan du søke etter ordren og refundere denne fra kundesenterportalen. Enkeltbilletter blir ikke kansellert i appen, men periodebilletter blir kansellert ved neste driftsdøgn.'
    ]
  },
  {
    title: 'Flytt billett',
    text: [
      'Hvis kunder ikke får gjenopprettet appen sin, men enten har registrert mobilnummeret sitt eller husker appID kan du hjelpe kunden å flytte billetter. Søk fram orderen du vil flytte og velg Flytt, skriv inn den nye appID’en og velg flytt. Nå kan kunden åpne menyen i appen sin og velge «Hent billett» og så «Hent fra kundesenter».'
    ]
  },  
];
