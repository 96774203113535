import { OrderStatus } from "../../app/interfaces";

export const OrderStatusList: Array<OrderStatus> = [
  {
    id: 8,
    name: 'Bekreftet levert til mobil',
    value: 'Bekreftet levert til mobil',
    field: 'orderstatus.keyword'
  },
  {
    id: 62,
    name: 'Kreditert',
    value: 'Kreditert',
    field: 'orderstatus.keyword'
  },
  {
    id: 96,
    name: 'Betaling kansellert',
    value: 'Betaling kansellert',
    field: 'orderstatus.keyword'
  },
  {
    id: 0,
    name: 'Igangsatt',
    value: 'Igangsatt',
    field: 'orderstatus.keyword'
  },
  {
    id: 95,
    name: 'Betaling feilet',
    value: 'Betaling feilet',
    field: 'orderstatus.keyword'
  },
  {
    id: 52,
    name: 'Starttidspunkt endret',
    value: 'Starttidspunkt endret',
    field: 'orderstatus.keyword'
  },
  {
    id: 23,
    name: 'Henting fullført',
    value: 'Henting fullført',
    field: 'orderstatus.keyword'
  },
  {
    id: 5,
    name: 'Eksportert til NOD',
    value: 'Eksportert til NOD',
    field: 'orderstatus.keyword'
  },
  {
    id: 21,
    name: 'Klar til henting',
    value: 'Klar til henting',
    field: 'orderstatus.keyword'
  },
  {
    id: 90,
    name: 'Henting fra NOD feilet',
    value: 'Henting fra NOD feilet',
    field: 'orderstatus.keyword'
  },
  {
    id: 93,
    name: 'Betaling ikke gjennomført',
    value: 'Betaling ikke gjennomført',
    field: 'orderstatus.keyword'
  },
  {
    id: 67,
    name: 'Overført',
    value: 'Overført',
    field: 'orderstatus.keyword'
  },
  {
    id: 7,
    name: 'Betaling gjennomført',
    value: 'Betaling gjennomført',
    field: 'orderstatus.keyword'
  },
  {
    id: 66,
    name: 'Overføring påbegynt',
    value: 'Overføring påbegynt',
    field: 'orderstatus.keyword'
  },
  {
    id: 97,
    name: 'Feilet under eksport til NOD',
    value: 'Feilet under eksport til NOD',
    field: 'orderstatus.keyword'
  },
  {
    id: 89,
    name: 'AutoPay feilet',
    value: 'AutoPay feilet',
    field: 'orderstatus.keyword'
  },
  {
    id: 63,
    name: 'Kreditering feilet',
    value: 'Kreditering feilet',
    field: 'orderstatus.keyword'
  }
];
//["Bekreftet levert til mobil", "Kreditert", "Betaling kansellert", "Igangsatt", "Betaling feilet", "Starttidspunkt endret", "Henting fullført", "Eksportert til NOD", "Klar til henting", "Henting fra NOD feilet", "Betaling ikke gjennomført", "Overført", "Betaling gjennomført", "Overføring påbegynt", "Feilet under eksport til NOD", "AutoPay feilet", "Kreditering feilet"]
