export const ProductIdKolumbus = {
  '24001': 'Enkeltbillett buss voksen',
  '24006': 'Enkeltbillett buss barn/honnør/student/verneplikt',
  '24011': 'Enkeltbillett buss honnør',
  '24016': 'Enkeltbillett buss student',
  '24021': 'Enkeltbillett buss vernepliktig',
  '24026': 'Enkeltbillett buss ta med sykkel om bord',
  '24061': 'Nærsonebillett Haugeund',
  '24301': '24-timersbillett voksen',
  '24306': '24-timersbillett barn',
  '24311': '24-timersbillett honnør',
  '24316': '24-timersbillett student',
  '24321': '24-timersbillett vernepliktig',
  '24331': '7-dagersbillett voksen',
  '24336': '7-dagersbillett barn',
  '24341': '7-dagersbillett honnør',
  '24346': '7-dagersbillett student',
  '24351': '7-dagersbillett vernepliktig',
  '24406': '30-dagersbillett voksen',
  '24411': '30-dagersbillett barn',
  '24416': '30-dagersbillett honnør',
  '24421': '30-dagersbillett student',
  '24426': '30-dagersbillett vernepliktig',
  '24431': '365-dagersbillett voksen',
  '24436': '365-dagersbillett barn',
  '24441': '365-dagersbillett honnør',
  '24446': '365-dagersbillett student',
  '24451': '365-dagersbillett vernepliktig',
  '24456': 'HJH-billett',
  '24706': 'Ungdomsbillett',
  '24811': 'Kombipass',
  '24826': 'Bysykkel',
  '': ''
};
