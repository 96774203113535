import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import * as Sentry from "@sentry/browser";
import jwtDecode from 'jwt-decode';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { accessTokenDecoded } from '../accessToken/actions';
import { AccessToken } from '../accessToken/actionTypes';
import apiClient from '../apiClient';
import { CityBike } from '../cityBike/cityBike';
import EditingWrapper from '../common/editing/EditingWrapper';
import Export from '../export/Export';
import Graph from '../graph/Graph';
import General from '../help/General';
import HelpMenu from '../help/HelpMenu';
import Issue from '../help/Issue';
import Solved from '../help/Solved';
import Home from '../home/Home';
import Info from '../info/Info';
import OrderComponent from '../ticketCounter/order/Order';
import Orders from '../ticketCounter/orders/Orders';
import OrderTicket from '../ticketCounter/orderTicket/OrderTicket';
import TicketCounter from '../ticketCounter/TicketCounter';
import Tickets from '../ticketCounter/tickets/Tickets';
import ViewApp from '../viewApp/ViewApp';
import ViewOrder from '../viewOrder/ViewOrder';
import './App.scss';
import AppLoading from './AppLoading';
import Footer from './Footer';
import Header from './Header';
import { RootState } from './interfaces';
import OktaErrorHandler from './OktaErrorHandler';
import RouteNotFoundComponent from './RouteNotFoundComponent';
import 'react-toastify/dist/ReactToastify.css';


function App(): JSX.Element {
  const { authState, authService } = useOktaAuth();
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.accessToken)
  const location = useLocation();

  useEffect(() => {
    if (authState.isPending || !authState.isAuthenticated || !authState.accessToken) {
      return;
    }

    const decodedAccessToken: AccessToken = jwtDecode(authState.accessToken);
    Sentry.setUser({ email: decodedAccessToken.sub });
    (window as any).decodedAccessToken = decodedAccessToken;
    apiClient.setAuthenticationService(authService);
    dispatch(accessTokenDecoded(decodedAccessToken));

  }, [authState.isPending, authState.accessToken, authState.isAuthenticated, dispatch, authService]);

  if (window.location.pathname.indexOf('implicit/callback') !== -1) {
    return <LoginCallback errorComponent={OktaErrorHandler} />
  }

  if (window.location.pathname.indexOf('login-error') !== -1) {
    const parameters = parse(location.search);
    
    return (
      <>
        <div style={{ margin: '50px auto 0 auto', display: "flex", justifyContent: "center" }}>
          <span style={{textAlign: 'center', lineHeight:'2rem'}}>
          Det skjedde en feil ved innlogging ({parameters.text}). <br />
          Trykk <a href="/" style={{color: 'blue'}}> her </a> for å prøve igjen.
          </span>
        </div>
      </>
    );
  }

  if (authState.isPending) {
    return <AppLoading />
  }

  if (!authState.isAuthenticated) {
    authService.login();
    return null;
  }

  if (authState.error) {
    throw new Error(authState.error);
  }

  if (!accessToken) {
    return <AppLoading />
  }

  return (
    <div id="main" className="main-wrapper">
      <ToastContainer className="toast-container" toastClassName="toast" />
      <Header />
      <div className="main-content">
        <Switch>
          <SecureRoute path='/billettluke' component={TicketCounter} />
          <SecureRoute path='/help' component={HelpMenu} />
        </Switch>
        <div className="layout-outer">
          <Switch>
            <SecureRoute exact path='/' component={Home} />
            <SecureRoute path='/home' component={Home} />
            <SecureRoute path='/vieworder' component={ViewOrder} />
            <SecureRoute path='/viewappinfo' component={ViewApp} />
            <SecureRoute path='/help/issue' component={Issue} />
            <SecureRoute path='/help/solved' component={Solved} />
            <SecureRoute path='/help' component={General} />
            <SecureRoute path='/info' component={Info} />
            <SecureRoute path='/graph' component={Graph} />
            <SecureRoute path='/export' component={Export} />
            <SecureRoute path="/billettluke/ordre/:id" component={OrderComponent} />
            <SecureRoute path='/billettluke/ordre' component={Orders} />
            <SecureRoute path='/billettluke/billetter' component={Tickets} />
            <SecureRoute path='/billettluke' component={OrderTicket} />
            <SecureRoute path='/citybike' component={CityBike} />
            <SecureRoute component={RouteNotFoundComponent} />
          </Switch>
        </div>
      </div>
      <Footer />
      <EditingWrapper />
    </div>
  )
}

export default App;
