import React, { useState, useEffect } from 'react';
import { HelpGeneralList } from '../static/data/HelpGeneralList';
import './General.scss';
import { ErrorTextList } from '../static/data/ErrorCodeList';

interface GeneralItem {
  title: string;
  text?: Array<string>;
}

interface ErrorCode {
  id: string;
  text: string;
  note: string;
  action: string;
  noTitle: string;
  noText: string;
  enTitle: string;
  enText: string;
}


const renderItem = (item: GeneralItem) => {
  return (
    <div className="error-panel">
      <h2>{item.title}</h2>
      <div className="">
        {item.text && item.text.map(paragraph => (
          <div key={item.title} className="paragraph">{paragraph}</div>
        ))}
      </div>
    </div>
  )
}

const renderErrorCode = (errorCode: ErrorCode, index: number) => {
  return (
    <div className="error-code-card">
      <div className="iterator">{index + 1}</div>
      <div className="block">
        <div className="label">Kode</div>
        <div className="content">{errorCode.id}</div>
      </div>
      <div className="block">
        <div className="label">Beskrivelse</div>
        <div className="content">{errorCode.text}</div>
      </div>
      <div className="block">
        <div className="label">Notat</div>
        <div className="content">{errorCode.note}</div>
      </div>
      <div className="block">
        <div className="label">Handling</div>
        <div className="content">{errorCode.action}</div>
      </div>
      <div className="block">
        <div className="label">Tittel</div>
        <div className="content">
          <div className="content-language">Norsk</div>
          <div className="content-text">{errorCode.noTitle}</div>
          <div className="content-language">Engelsk</div>
          <div className="content-text">{errorCode.enTitle}</div>
        </div>
      </div>
      <div className="block">
        <div className="label">Tekst</div>
        <div className="content">
          <div className="content-language">Norsk</div>
          <div className="content-text">{errorCode.noText}</div>
          <div className="content-language">Engelsk</div>
          <div className="content-text">{errorCode.enText}</div>
        </div>
      </div>
    </div>
  )
}

const filterErrorCodes = (query: string) => {
  const normalizedQuery = query.trim().toLowerCase();
  return ErrorTextList.filter(x => x.id.trim().toLowerCase().indexOf(normalizedQuery) !== -1 || x.text.trim().toLowerCase().indexOf(normalizedQuery) !== -1)
}

const General: React.FC = () => {
  const [search, setSearch] = useState("");
  const [errorCodes, setErrorCodes] = useState<Array<ErrorCode>>();

  useEffect(() => {
    setErrorCodes(filterErrorCodes(""))
  }, [])

  const handleChange = (value: string) => {
    setSearch(value);
    setErrorCodes(filterErrorCodes(value));
  }

  if(!errorCodes) {
    return null;
  }

  return (
    <div className="help-general">
      <h1>Generell</h1>
      {HelpGeneralList.map(renderItem)}

      <h2>Feilkoder:</h2>
      <input type="text" value={search} onChange={(e) => handleChange(e.target.value)} placeholder="Søk på feilkode og beskrivelse" />
      <div className="results">
        {errorCodes.map(renderErrorCode)}
      </div>
    </div>
  );
}

export default General;