import { TicketStatus } from "ruter-billettluke";

const language  = {
  statuses: {
    active: 'Gyldig',
    pending: 'Ventende',
    expired: 'Utløpt',
    failed: "Feilet",
    cancelled: 'Kansellert',
    unknown: 'Ukjent',
  },
};

const getTicketStatusText = (status: TicketStatus): string => {
  if (language.statuses[status]) {
    return language.statuses[status];
  }

  return language.statuses.unknown;
}

export default getTicketStatusText;
