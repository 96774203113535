import React from 'react';
import "./SubMenu.scss";
import { NavLink } from 'react-router-dom';

const HelpMenu: React.FC = () => {
  return (<div className="sub-menu">
    <ul>
      <li><NavLink activeClassName="active" exact to="/help">Generell</NavLink></li>
      <li><NavLink activeClassName="active" to="/help/issue">Kjente problemstillinger</NavLink></li>
      <li><NavLink activeClassName="active" to="/help/solved">Løste utfordringer</NavLink></li>
      <li><a href="https://ruter.formstack.com/forms/kk_innmeldingsskjema" target="_blank" rel="noreferrer">Feilmeldingsskjema</a></li>
    </ul>
  </div>)
}

export default HelpMenu;