export interface HelpSolved {
  title: string;
  type: string;
  date: string;
  text: Array<string>;
  comment: Array<string>;
}

export const HelpSolvedIssuesList: Array<HelpSolved> = [
  {
    title: 'Vipps forsvinner som betalingsmåte i 6.1',
    type: 'issue',
    date: '21.12.2018',
    text: ['Noen kunder opplever at Vipps er borte fra betalingsmåter etter oppdatering til 6.1.'],
    comment: [
      'Dette er løst, kundene trenger ikke gjenopprette, Vipps dukker opp i appen som før. Kommer i tillegg en rettelse i ny RB verjson 6.1.1 som rulles fra 10.1.19',
      'Workaround: avinstaller og installer på nytt. Gjenopprett eller få overført billett fra kundeservice.'
    ]
  },
  {
    title: 'Feil rekkefølge på utløpte billetter i iOS 6.0',
    type: 'issue',
    date: '17.12.2018',
    text: [],
    comment: ['Rettet i 6.1, be kunden være tålmodig og si at 6.1 rulles ut denne uka.']
  },

  {
    title: 'Kjøp til andre billetter blir utløpt i stedet for å starte',
    type: 'issue',
    date: '15.03.2018',
    text: [
      'På mottakers mobil ved henting av billetten står det at billetten automatisk aktiveres etter 7 dager fra hentetidspunktet.',
      'Hvis mottaker ikke starter billetten men lar den starte av seg seg feiler det, og 7 dagers blir billetten blir deaktivert og vises som utløpt i appen.',
      'Oppdatering',
      'Billetten blir ikke lenger ugyldig,den blir aktiv og varer så lenge den skal, men vil ikke vise QR-kode og dagens bilde'
    ],
    comment: [
      'Workaround: Hvis kunden kontakter oss må dere kreditere den som ble deaktivert og evt be dem kjøpe ny billett. Løst i sapi  7.7.1'
    ]
  },

  {
    title: 'Kjøp til andre med ordre med ordrestatus 1 betalt og betalingsstatus 5 påbegynt. Gjelder iOS.',
    type: 'issue',
    date: '03.01.2018',
    text: [
      'Fra desember har vi en voldsom økning i Kjøp til andre ordre med ordrestatus 1 betalt og betalingsstatus 5 påbegynt',
      'Disse ordrene har blitt liggende og er kun reservert. Vipps bekrefter at de blir løst fra reservasjonen på lik linje som andre reserverte ordre, i løpet av 2-5 virkedager.'
    ],
    comment: [
      'Workaround: Kunden må kjøpe ny billett. Hvis det er behov for å løse reservasjonene før det har gått 5 dager kan vi opprette sak i Siebel og videresende saken til Vipps.',
      'Sapi-drift teamet jobber med å finne årsaken.',
      'Løst i SAPI 7.7.1 (https://jira.ruter.no/browse/API-2714)'
    ]
  },

  {
    title: 'Kjøp til andre med ordre med ordrestatus 1 betalt og betalingsstatus 5 påbegynt. Gjelder iOS.',
    type: 'issue',
    date: '03.01.2018',
    text: [
      'Fra desember har vi en voldsom økning i Kjøp til andre ordre med ordrestatus 1 betalt og betalingsstatus 5 påbegynt',
      'Disse ordrene har blitt liggende og er kun reservert. Vipps bekrefter at de blir løst fra reservasjonen på lik linje som andre reserverte ordre, i løpet av 2-5 virkedager.'
    ],
    comment: [
      'Workaround: Kunden må kjøpe ny billett. Hvis det er behov for å løse reservasjonene før det har gått 5 dager kan vi opprette sak i Siebel og videresende saken til Vipps.',
      'Sapi-drift teamet jobber med å finne årsaken.',
      'Løst i SAPI 7.7.1 (https://jira.ruter.no/browse/API-2714)'
    ]
  },
  {
    title: 'Emojii i kontaktlisten gjør at appen kræsjer ved kjøp til andre.',
    type: 'issue',
    date: '23.10.2018',
    text: [
      'Har du lagret en emoji som navn på en person i kontaktlisten din og forsøker å angi at du skal kjøpe en kjøp til andre billett vil det feile.'
    ],
    comment: [
      'Endre navn i kontaktlisten din eller tast inn tlfnr manuelt istedet for å velge fra kontakter når du skal kjøpe billetten.',
      'løst i 6.0. rullet ut i uke 49'
    ]
  },
  {
    title: 'Apple Pay ikke tilgjengelig når de åpner BillettAppen',
    type: 'issue',
    date: '22.10.2018',
    text: [
      'Noen iOS brukere opplever å få melding om at Apple Pay ikke er tilgjengelig når de åpner RuterBillett. Det ser hittil ut som det gjelder kunder som ikke har aktivert Apple Pay.'
    ],
    comment: [
      'Meldingen kan klikkes bort og påvirker ikke bruken av RuterBillett',
      'Det er laget slik iOS, og finnes ikke i versjon 12 og over. Løsning for de som har mulighet er å oppdatere operativsystemet i tlf til iOS 12. Det hjelper ikke å avinstallere RuterBillett appen!!',
      'Dette vil ikke bli endret på fra iOS'
    ]
  },
  {
    title: 'Apple Pay blir tolket som et betalingskort i innstillinger',
    type: 'issue',
    date: '15.10.2018',
    text: [
      'Apple Pay blir tolket som et betalingskort i innstillinger, slik at det ser ut som man kan gi Apple Pay et eget kortnavn slik man kan med betalingskort',
      'Dette vil feile'
    ],
    comment: [
      'Denne er løst',
      'Gi beskjed til kunden om at det er kjent feil og vil fikses. Det skal ikke være mulig å endre navn på Apple Pay.'
    ]
  },

  {
    title: 'Feilkode 995-3-7',
    type: 'issue',
    date: '28.08.2018',
    text: [
      'Noen av kundene våre opplever å få feilmelding 995-3-7 når de åpner en billetten sin.',
      'Billetten blir ikke oppdatert. Support jobber med å finne ut hva som feiler, så meld ifra når dere kommer over dette.',
      'årsaken er at vi mangler sessions data. ',
      'Disse dataene blir slettet hver natt kl 2, så billetten burde funke dagen etter. dersom den ikke gjør det kan det "fikses" med å overføre/gjenopprette billett'
    ],
    comment: [
      'Dette er løst men det kan fortsatt finnes kunder som har feilmeldingen.',
      'Den vil ikke komme igjen etter en gjenoppretting eller overføring av billett til samme app id.',
      'OBS opplever du at kunder som har kjøpt billett fra desember 2018 må du melde det til support! Billetter kjøpt før dette blir løst av workaround',
      'Be kunden registrere telefonnummer i appen for så å gjenopprette via telefonnummer.',
      'Lar det seg ikke gjøre å gjenopprette hjelper vi kunden med å reininstallere appen og overfører billetten for dem.',
      'gjøres endring i sessionsid rutinene. løst i sapi 7.7.0 rullet i uke 48'
    ]
  },

  {
    title: 'trøbbel med billetter som forsvinner fra appen',
    type: 'issue',
    date: '05.06.2018',
    text: [
      'Ser ut som vi har trøbbel med billetter som forsvinner fra appen. Ser helt riktig ut i ordredatabasen, men billetten forsvinner i appen.',
      'Spørsmål support trenger svar på for å kunne feilsøke:',
      'Når forsvant billetten? Står billetten i utløpte billetter i billettoversikten? Var den rekonstruert fra en annen app, før dere rekonstruerte billetten?',
      'Er appen gjenopprettet fra en annen app?'
    ],
    comment: ['Workaround: Krediter billetten for kunden så de kan kjøpe ny. vi ikke har klart å reprodusere feilen.']
  },

  {
    title: 'Betalingsmåter har for noen IOS brukere forsvinner',
    type: 'issue',
    date: '26.11.2018',
    text: [
      'Betalingsmåter har for noen IOS brukere forsvant etter migrering til Basefarm natt til søndag.',
      'Betalingsmåte var da ikke synlig under innstillinger eller ved kjøp av billett og det var ikke mulig å gjennomføre kjøp. '
    ],
    comment: [
      'Feilen ble rettet søndag kveld. Vi «bumpet» ut nytt versjonsnummer på betalingstjenesten som fikk appene til å oppdatere med ny info. Dette løste problemet ved at gammel cache med blank side ble erstattet med fungerende link til server hos Basefarm. Vi kjørte scriptet rundt 22 søndag kveld, så dersom de åpnet appen 2100 (i søndag), så må de vel vente til 21 (idag, mandag) for at cache skal gå ut -> hente på nytt',
      'Har ikke fått tilbakemelding om nye feil i dag',
      'Hvis kunden fortsatt får feil må de avinstallere og installere på nytt.'
    ]
  },

  {
    title: 'Billettkontrollen melder at de ser 30-dagersbilletter som mangler knapp for kontrollkode og dagens bilde',
    type: 'issue',
    date: '22.10.2018',
    text: [
      'Billettkontrollen melder at de ser 30-dagersbilletter som mangler knapp for kontrollkode og dagens bilde (likt som fri-billettfeilen vi har hatt) men som ikke får feilmelding.',
      'De få billettene vi har fått sett på viser at billettene har sluttet å ha daglig oppdatering. Vi har ikke klart å gjenskape feilen.'
    ],
    comment: [
      'Workaround: overfør billetten for kunden til samme app id',
      'Send app id til support for videre feilsøking. Ikke kommet inn noe mer informasjon fra kunder'
    ]
  },

  {
    title: '984 - iOS',
    type: 'issue',
    date: '27.11.2018',
    text: ['984- feilen finnes kun på iOS. Det er en bug at den vises og ikke en reel feilmelding!'],
    comment: [
      'Bare trykk bort feilmeldingen, du vil fortsette å få den hver dag, men alt fungerer som det skal i appen. Det er en bug at den vises og ikke en reel feilmelding.',
      'Det er kun hvis feilmeldingen ikke blir borte at det er en reel feil, og man bør følge instruksjonene om å avinstallere og gjenopprette.',
      'blir rettet i versjon 6.0 av RuterBillett som er planlagt rukket ut uke 49',
      'Det er også funnet en feil i Sapi som påvirker dette, og det blir sannsynligvis lagt ut en feilretting fredag 30 hvis testing av denne går bra over natten!'
    ]
  },

  {
    title: 'Ordrestatus 91 gjennomføring av betaling feilet',
    type: 'issue',
    date: '',
    text: ['Disse ordrene skal ikke være trukket, men det viser seg at en del av ordrene er faktisk er betalt.'],
    comment: [
      'Det jobbes med å finne feilen og rette den. Hvis kunder henvender seg må billetten krediteres via Netaxept eller Vipps. Saksbehandler kan Refundere eller kontakte Vipps.'
    ]
  },

  {
    title: 'Utrulling av iOS 5.3.2',
    type: 'issue',
    date: '24.09.2018',
    text: [
      'Den nye versjonen vil gi kundene et popup vindu om ApplePay første gang de åpner appen etter oppdatering. Dette er fordi vi fortsatt skjuler apple pay funksjonaliteten mens vi venter på klarsignal for å kunne skru det på'
    ],
    comment: ['Kunden kan bare trykke OK, så forsvinner feilmeldingen. Den vil ikke vises igjen.']
  },
  {
    title:
      'Dersom man på iOS (Appversjon 5.3) bytter betalingskort i kjøpsprosessen, vil man måtte legge inn kortdetaljer på nytt, selv om man har et signert kort innlagt. Om billettkjøpet krever 3ds, må man også legge inn dette.',
    type: 'issue',
    date: '24.08.2018',
    text: [],
    comment: [
      'Feilen er lokalisert, og det jobbes med å rette den. Frem til da har vi ingen annen løsning enn å faktisk legge inn detaljene på nytt. Blir rettet når 5.3.2 kommer ut. løst, flyttes. kan fortsatt oppleves i 5.3.1 ios'
    ]
  },
  {
    title:
      'Oppgradering av OS til Android 9 Pie fører noen ganger til at man får beskjed om at "Gjenopprett feilet. Billettene blir slettet". ',
    type: 'issue',
    date: '14.08.2018',
    text: [''],
    comment: ['Løst RB 5.3.1 Android', 'Gjenopprett billettene med tlf nummer eller app-id.']
  },
  {
    title: 'Feil med billetter kjøpt fra varsel om utløp av billett.',
    type: 'issue',
    date: '08.06.2018',
    text: [
      'Kunder som har varsel om utløp av billett får tilbud om å gjenta kjøp i varselet. Billetten skal da starte når den gamle utløper. På kjøp siden skal det automatisk være satt til oppstarttidspunkt samme tidspunkt som gammel utløper. Det gjør den dessverre ikke, i kjøpsbildet ser vi at billetten får oppstartidspunkt nå, slik at billetten starter med en gang etter kjøp'
    ],
    comment: ['Krediter overlappende dager på gammel billett for kunden.', 'Feilen er rettet i 5.3.']
  },
  {
    title:
      'Kunder som legger inn telefonnummer på en helt tom app opplever å få en spinner som ender opp i en 400-feil.',
    type: 'issue',
    date: '04.07.2018',
    text: ['Løst uten utrulling 04.07.2018'],
    comment: [],
  },
  {
    title: 'Stans i utrullingen av RuterBillett 5.2.1 iOS',
    type: 'issue',
    date: '06.03.2018',
    text: [
      'Problemet med forsvunnede billetter har heldigvis ikke eskalert i samme grad som jeg fryktet, så pr dags dato har jeg 11 app-id-er hvor dette er meldt inn (av ca 400 000 brukere). Vi ønsker like fult å finne ut hva som har hendt med disse, men vi prioriterer nå og få ut en ny iOS versjon (5.2.2) som inneholder noen Vipps forbedringer og en fiks vi tror kan løse de forsvunnede billettene. ',
      'Planen nå er å få rullet ut 5.2.2 gradvis fra mandag, og i løpet av uken ha alle kollektivselskapene på iOS versjon 5.2.2',
      'Hei, vi har fått inn noen henvendelser fra kunder som har mistet sin billett etter utrullingen av 5.2. Vi har nå stoppet utrullingen for å undersøke nærmere hva dette skyldes. Hendelsesforløpet er at man har kjøpt en periodebillett med nyeste versjon, og dagen etter er billetten borte. Det er ikke mange henvendelser, (litt over 10 konkrete+ noen man antok var enkelttilfeller som ikke er registrert) men nok til at vi ønsker å undersøke mer hva som har skjedd, før vi ruller videre',
      'Vi har kun registrert det på iOS så langt.'
    ],
    comment: ['Utrullingen er fullført for både Android pr 13.03 med versjon 5.2.1 og iOS 14.03 med ny versjon 5.2.2']
  },
  {
    title: 'Billetter blir «borte» fra RuterBillett etter at de er betalt og lastet inn i appen. Gjelder kun iOS',
    type: 'issue',
    date: '09.01.2018',
    text: [
      'Vi har en teori om at dette skyldes den nattlige oppdateringen, hvor operativsystemet hindrer appen i å fullføre oppdateringen, pga en endring i siste versjon av RuterBillett'
    ],
    comment: ['Rettet i 5.1.2! be kunden oppdatere til nyeste versjon av RuterBillett']
  }
];
