import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import moment from 'moment';
import { AccessToken } from '../accessToken/actionTypes';
import { Order } from '../app/interfaces';
import { Company } from '../constants';
import { SearchTypes } from '../oosAjax';
import { ProductIdAKT } from '../static/data/ProductIdAKT';
import { ProductIdBrakar } from '../static/data/ProductIdBrakar';
import { ProductIdKolumbus } from '../static/data/ProductIdKolumbus';
import { ProductIdOKT } from '../static/data/ProductIdOKT';

export const createRegexp = (text: string, g: number, i: number, b: number, f: number | undefined = undefined): RegExp => {
  if (text === '*') {
    return /.*/;
  }
  const v = text.replace(/\*/, '.*').replace(/\+/g, '\\+');
  const m = g && i ? 'gi' : g || i ? (g ? 'g' : 'i') : '';
  const s = b ? (b === 2 ? '^' : b === 3 ? '(^|/|\\s+|,)' : '(^|/|\\s+)') : '';
  const e = f ? (f === 2 ? '$' : f === 3 ? '($|/|\\s+|,)' : '($|/|\\s+)') : '';
  return new RegExp(s + '(' + v + ')' + e, m);
};

export const addEvent = (callback: (e: React.FormEvent) => void, target: any, type: string): void => {
  if (target) {
    if (typeof target.addEventListener !== 'undefined') {
      target.addEventListener(type, callback, false);
    } else if (typeof target.attachEvent !== 'undefined') {
      target.attachEvent('on' + type, callback);
    }
  }
};

export const getWindowSize = (): number[] => {
  const size = [0, 0];
  if (!window.innerWidth) {
    // IE
    if (!(document.documentElement.clientWidth === 0)) {
      size[0] = document.documentElement.clientWidth;
      size[1] = document.documentElement.clientHeight;
    } else {
      size[0] = document.body.clientWidth;
      size[1] = document.body.clientHeight;
    }
  } else {
    size[0] = window.innerWidth;
    size[1] = window.innerHeight;
  }
  return size;
};

export const getDocumentScrollPosition = (): number[] => {
  if (typeof window.pageYOffset !== 'undefined') {
    return [window.pageXOffset, window.pageYOffset];
  }

  if (typeof document.documentElement.scrollTop !== 'undefined' && document.documentElement.scrollTop > 0) {
    return [document.documentElement.scrollLeft, document.documentElement.scrollTop];
  }

  return typeof document.body.scrollTop !== 'undefined' ? [document.body.scrollLeft, document.body.scrollTop] : [0, 0];
};

export const scrollBodyTop = (where: number): void => {
  document.body.scrollTop = document.documentElement.scrollTop = where && !isNaN(where) && where > 0 ? where : 0;
};

export const trim = (text: string, multipleWhiteSpace = false): string => {
  const out = (text || '').replace(/^\s+/, '').replace(/\s+$/g, '');
  return multipleWhiteSpace ? out.replace(/\s+/g, ' ') : out;
};

export const convertDateToText = (date: Date, separator: string, clock: boolean, americanFormat: boolean): string => {
  const s = typeof separator === 'undefined' || separator === null ? (americanFormat ? '-' : '.') : separator;
  let l = [date.getDate().toString(), date.getMonth().toString() + 1, date.getFullYear().toString()];
  let i = 0;
  if (americanFormat) {
    const y = l[2];
    l[2] = l[0];
    l[0] = y;
  }

  for (i = 0; i < l.length; i++) {
    if (Number(l[i]) < 10) {
      l[i] = '0' + l[i];
    }
  }

  const out = l.join(s);
  if (!clock) {
    return out;
  }

  l = [date.getHours().toString(), date.getMinutes().toString()];
  for (i = 0; i < l.length; i++) {
    if (Number(l[i]) < 10) {
      l[i] = '0' + l[i];
    }
  }
  return out + ' ' + l.join(':');
};

export const convertToHumanDate = (date: Date | string, format = 'DD.MM.YYYY, HH:mm'): string => {
  if (!date) {
    return '';
  }
  moment.locale('nb');
  return moment(date).format(format);
};

export const hasWriteAccess = (accessToken: AccessToken): boolean => {
  if (accessToken.readonly === undefined) {
    return false;
  }

  if (accessToken.readonly === true) {
    return false;
  }

  return true;
};

export const validRefundStatus = (src: Order, hasWriteAccess: boolean): boolean => {
  if (hasWriteAccess === false || !src) {
    return false;
  }

  // distribusjonsstaus er kansellert
  if (src && (src.nodorderstatusid || '') + '' === '6') {
    return false;
  }

  if(src.payerid){
    return false;
  }

  const oType = [12, 20, 25, 26, 30];
  if (!oType.some(x => x === src.typeid)) {
    return false;
  }

  if (src.orderstatusid === 63) {
    return src.paymentstatusid === 40;
  }

  return src.paymentstatusid === 10;
};

export const validMoveticketStatus = (src: Order, hasWriteAccess: boolean): boolean => {
  if (hasWriteAccess === false || !src) {
    return false;
  }

  const oType = [12, 20, 25, 26];
  const oStatus = [7, 8, 9, 23];

  return oType.some(x => x === src.typeid) && oStatus.some(x => x === src.orderstatusid);
};

export const validCancelOrderStatus = (src: Order, hasWriteAccess: boolean): boolean => {
  if (hasWriteAccess === false || !src) {
    return false;
  }

  if (src.tickettypeid === 1) {
    return false;
  }
  if (src.expirationdate) {
    moment.locale('nb');
    
    const now = moment();
    if (moment(src.expirationdate) < now) {
      return false;
    }
  }

  const oType = [12, 20, 25, 26];
  const oStatus = [5, 7, 8, 9, 14];

  return oType.some(x => x === src.typeid) && oStatus.some(x => x === src.orderstatusid);
};

export const validPickupOrderStatus = (src: Order, hasWriteAccess: boolean): boolean => {
  if (hasWriteAccess === false || !src) {
    return false;
  }

  return src.typeid === 12 && src.orderstatusid === 23 && src.paymentstatusid === 10;
};

export const validSendReceiptStatus = (src: Order, hasWriteAccess: boolean): boolean => {
  if (hasWriteAccess === false || !src) {
    return false;
  }

  const orderTypes = [12, 20, 25, 26];
  const paymentStatus = [10, 20];

  return orderTypes.some(x => x === src.typeid) && paymentStatus.some(x => x === src.paymentstatusid);
};

export const isSingleTicket = (type: string): boolean => (type && type.match(/^enkeltbillett/i) ? true : false);

export const getCountDayDifferent = (fromDateText: string | Date | undefined, toDateText: string | Date | undefined, roundUp = false, opposite = false): number => {
  moment.locale('nb');
  const day = 24 * 60 * 60;
  const start = fromDateText ? moment(fromDateText).unix() : moment().unix();
  const end = toDateText ? moment(toDateText).unix() : moment().unix();
  let result = (end - start) / day;

  if (opposite) {
    result *= -1;
  }
  if (roundUp) {
    result = Math.ceil(result);
  }
  return result;
};

export const getPaymentStorCode = (companyId: number): number => {
  switch (companyId) {
    case Company.Kolumbus:
      return 16;
    case Company.AKT:
      return 17;
    case Company.Brakar:
      return 18;
    case Company.Ostfold:
      return 19;
    case Company.Ruter:
      return 15;
    default:
      return 16;
  }
};

export const getProductIdData = (companyId: number): any => {
  switch (companyId) {
    case Company.Kolumbus:
      return ProductIdKolumbus;
    case Company.Ostfold:
      return ProductIdOKT;
    case Company.Brakar:
      return ProductIdBrakar;
    case Company.AKT:
      return ProductIdAKT;
    default:
      return {};
  }
};

export const isConfirmed = (status: string): boolean => (status && status.match(/^bekreftet/i) ? true : false);

export const getEnvName = (): 'local' | 'dev' | 'test' | 'prod' => {
  if (window.origin.indexOf('localhost') !== -1) {
    return 'local';
  } else if (window.origin.indexOf('kundesenterportalen-old.dev') !== -1) {
    return 'dev';
  } else if (window.origin.indexOf('kundesenterportalen-old.test') !== -1 || window.origin.indexOf('test.kollektiv') !== -1) {
    return 'test';
  } else {
    return 'prod';
  }
}

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'NO');

  if (parsedNumber && parsedNumber.isValid()) {
    return parsedNumber.formatInternational();
  } else {
    return phoneNumber;
  }
}

export const getCityBikeIndex = (company: Company): SearchTypes => {
  if(company === Company.Kolumbus) {
    return 'citybike_orders';
  }
  if(company === Company.Brakar) {
    return 'brakar_citybike_orders';
  }
  throw new Error(`Can not resolve citybike_index for company: ${company}`);
}


export const doesUserHaveCityBikeRight = (accessToken: AccessToken): boolean => {
  if (!hasWriteAccess(accessToken)) {
    return false;
  }

  if (accessToken.CompanyId === Company.Kolumbus || accessToken.CompanyId === Company.Brakar) {
    return true;
  }
  return false;
}