import moment from 'moment';
import { parse, stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OosEditing, OrderStatus, RootState, TicketCategory, TicketType } from '../app/interfaces';
import { Calendar } from '../common/calendar/Calendar';
import { convertDateToText, getProductIdData, trim } from '../common/General';
import { Loading } from '../common/loading/Loading';
import Pagination from '../common/Pagination/Pagination';
import { openEditing } from '../oos-action-creators';
import { search } from '../oos-actions';
import { SearchData } from '../oosAjax';
import { OrderStatusList } from '../static/data/OrderStatusList';
import { TicketCategoryList } from '../static/data/TicketCategoryList';
import { TicketTypeList } from '../static/data/TicketTypeList';
import './Home.scss';
import { HomeAppinstanceWidget } from './HomeAppinstanceWidget';
import { HomeTableList } from './HomeTableList';

interface Shortcut {
  id: string;
  name: string;
  type?: string;
}

interface SearchConfig {
  size: number;
  from: number;
  sort: string;
  text: string;
  advance: boolean;
}

interface State {
  error: string;
  interval: Array<any>;
  shortcuts: Array<Shortcut>;
  searchAppinstance: null;
  searchConfig: SearchConfig
  productList: Array<any>
}

type Props = RouteComponentProps & {
  actions: {
    search: any;
    openEditing: any;
    dispatch: () => void;
  };
  productId: string;
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // let query = (props.location || {}).query || {};

    const query = parse(props.location.search) || {} as any;
    this.state = {
      error: '',
      interval: [],
      shortcuts: [
        { id: '-2hour', name: '2 timer siden' },
        { id: '-6hour', name: '6 timer siden' },
        { id: '-today', name: 'Idag' },
        { id: '-yesterday', name: 'Igår' },
        { id: '-week', name: 'Denne uka', type: 'last-shortcut-link' },
        // {'id': '-month',     'name': 'Denne måneden'}
      ],
      searchAppinstance: null,
      searchConfig: {
        size: Number(query.size) || 10,
        from: Number(query.from) || 0,
        sort: query.sort as string || 'orderid:desc',
        text: query.text as string || '',
        advance: !!(query.filters as string),
      },
      productList: this._initProductList(props.productId) || [],
    };

    this._click = this._click.bind(this);
    this._submit = this._submit.bind(this);
    this._keyup = this._keyup.bind(this);
    this._change = this._change.bind(this);
    this._calendarCallback = this._calendarCallback.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  render() {
    const {
      searchConfig, shortcuts, searchAppinstance, productList,
    } = this.state;
    const { storage, productId } = this.props as any;
    const loading = !!storage.waitingFor;
    const sorted = searchConfig.sort;
    const hits = (storage.search || {}).hits || {};
    const list = hits.hits || [];
    const total = hits.total || 0;

    const pager = {
      activePage: Math.floor(searchConfig.from / searchConfig.size) + 1,
      totalNumberOfPages: Math.ceil(hits.total / searchConfig.size),
      pageSize: searchConfig.size,
    }


    return (
      <div className="home-wrapper">
        <div className="home-header">
          <div className={`search-tool ${searchConfig.advance ? ' -open-advance' : ''}`}>
            <form name="search" noValidate onSubmit={this._submit}>
              <div className="advance-search-wrapper">
                <div className="search-calendar-holder input-content">
                  <Calendar
                    clock
                    view={2}
                    shortcuts={shortcuts}
                    placeholder={['fra', 'til']}
                    callback={this._calendarCallback}
                    ref="calendar"
                  />
                </div>
                <div className="search-ticket-type-wrapper input-content">
                  <fieldset>
                    <legend className="input-label">Billettype</legend>
                    <label className="aria-visible" htmlFor="export-type-selector">
                      Billettype
                    </label>
                    {productList.length === 0 ? (
                      <ul className="input-holder">
                        {TicketTypeList.map((data, i) => (
                          <li key={`ticket-type-${i}`}>
                            <input
                              name={data.field}
                              id={`ticket-type-${data.id}`}
                              type="checkbox"
                              className="input-checkbox"
                              value={data.id}
                              ref={`${data.field}-${i}`}
                            />
                            <label htmlFor={`ticket-type-${data.id}`}>{data.name}</label>
                          </li>
                        ))}
                      </ul>
                    ) : (
                        <select
                          ref="productid"
                          className="select-box export-type-selector"
                          id="product-id-selector"
                          name="productid"
                        >
                          {productList.map((data, i) => (
                            <option key={`product-id-${i}`} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      )}
                  </fieldset>
                </div>
                <div className="search-ticket-status-wrapper input-content">
                  <fieldset>
                    <legend className="input-label">Billetstatus</legend>
                    <ul className="input-holder">
                      {TicketCategoryList.map((data, i) => (
                        <li key={`ticket-category-${i}`}>
                          <input
                            name={data.field}
                            id={`ticket-category-${data.id}`}
                            type="checkbox"
                            className="input-checkbox"
                            value={data.id}
                            ref={`${data.field}-${i}`}
                            onChange={(e) => {
                              this._change(e, 'ticket-category', data);
                            }}
                          />
                          <label htmlFor={`ticket-category-${data.id}`}>{data.name}</label>
                        </li>
                      ))}
                    </ul>
                  </fieldset>
                </div>
                <div className="search-order-status-wrapper input-content">
                  <fieldset>
                    <legend className="input-label">Ordre status</legend>
                    <ul className="input-holder">
                      {OrderStatusList.map((data, i) => (
                        <li key={`order-status-${i}`}>
                          <input
                            name={data.field}
                            id={`order-status-${data.id}`}
                            type="checkbox"
                            className="input-checkbox"
                            value={data.value || data.id}
                            ref={`${data.field}-${i}`}
                          />
                          <label htmlFor={`order-status-${data.id}`}>{data.name}</label>
                        </li>
                      ))}
                    </ul>
                  </fieldset>
                </div>
              </div>
              <div className="search-wrapper">
                <button
                  type="button"
                  className="secondary-btn advance-search-btn"
                  onClick={(e) => {
                    this._click(e, 'toggle-advance-search');
                  }}
                >
                  <span aria-hidden="true">Avansert</span>
                  <span className="aria-visible">
                    {searchConfig.advance ? 'Lukke' : 'Åpne'}
                    {' '}
                    avansert søk
                  </span>
                </button>

                <input
                  type="text"
                  className="textfield"
                  onKeyUp={(e) => {
                    this._keyup();
                  }}
                  spellCheck="false"
                  autoComplete="off"
                  autoCorrect="off"
                  placeholder="Søk etter billettnummer, app-ID eller mobilnummer"
                  ref="searchField"
                />
                <HomeAppinstanceWidget config={searchAppinstance} />
                <div className="submit-wrapper">
                  <div className="reset-button">
                    <button
                      type="button"
                      className="icon-btn -cross"
                      title="Nullstille søket"
                      onClick={(e) => {
                        this._click(e, 'reset-search');
                      }}
                    >
                      <span className="aria-visible">Nullstille søket</span>
                    </button>
                  </div>
                  <button type="submit" className="secondary-btn -blue -fill">
                    Søk
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : list.length === 0 ? (
          <div className="home-content">
            <div className="empty-text">Ingen treff</div>
          </div>
        ) : (
              <div className="home-content">
                <div className="list-information">
                  <div className={`shy italic small-text${total ? '' : ' hide'}`}>
                    Total:
                {total}
                  </div>
                  <div className="sort-option-list" />
                </div>
                <HomeTableList sorted={sorted} list={list} callback={this._click} productId={productId} showMore={undefined} parent={undefined} />
                <Pagination onPageChange={this.changePage} activePage={pager.activePage} totalNumberOfPages={pager.totalNumberOfPages} pageSize={pager.pageSize} />
              </div>
            )}
      </div>
    );
  }

  changePage(pageNumber: number) {
    const { searchConfig } = this.state;

    this.setState({
      searchConfig: {
        ...searchConfig,
        from: (pageNumber - 1) * searchConfig.size,
      }
    }, () => this._search())
  }

  componentDidMount() {
    (this.refs.searchField as HTMLInputElement).value = this.state.searchConfig.text || '';
    this._initAdvanceFilter();
    this._search('startup');
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    let out = true;
    const nLocation = nextProps.location;
    const cLocation = (this.props).location;
    const nQuery = JSON.stringify(parse(nLocation.search));
    const cQuery = JSON.stringify(parse(cLocation.search));

    if (nQuery === '{}' && cQuery !== '{}') {
      out = false;
      const { searchConfig } = nextState;
      this._reset({
        size: searchConfig.size || 10,
        from: 0,
        sort: searchConfig.sort || 'orderid:desc',
        text: '',
        advance: false,
      });
    }
    return out;
  }

  _keyup() {
    const { actions } = this.props as any;
    const { searchAppinstance } = this.state as any;
    const field = this.refs.searchField as any;
    const text = trim(field.value || '');

    if (text.length > 6 && text.match(/^[0-9]*$/)) {
      if (searchAppinstance && searchAppinstance.text === text) {
        return;
      }
      // text = '46824560';
      const config = {
        text,
        loading: `App-instance søker på tlf.nr. ${text}`,
        result: [],
      };
      this.setState({ searchAppinstance: config as any });

      actions.search({ type: 'appinstances', text }, (response) => {
        config.loading = '';
        config.result = (response.hits || {}).hits || [];
        this.setState({ searchAppinstance: config as any });
      });
    } else if (searchAppinstance) {
      this.setState({ searchAppinstance: null });
    }
  }

  _submit(e) {
    if (e) {
      e.preventDefault();
    }
    const { searchConfig } = this.state;
    searchConfig.text = (this.refs.searchField as any).value || '';
    searchConfig.from = 0;
    this.setState({ searchConfig });
    this._search();
  }

  _click(e, key, data = undefined) {
    if (e) {
      e.preventDefault();
    }

    const { actions } = this.props;

    const { searchConfig } = this.state;
    if (key === 'toggle-advance-search') {
      searchConfig.advance = !searchConfig.advance;
      this.setState({ searchConfig });
    } else if (key === 'reset-search') {
      this._reset();
    } else if (key === 'sort') {
      let splited = (searchConfig.sort || '').split(':');
      if (data === splited[0]) {
        splited[1] = splited[1] === 'desc' ? 'incr' : 'desc';
      } else {
        splited = [data, 'desc'];
      }
      searchConfig.sort = splited.join(':');
      this.setState({ searchConfig });
      this._search();
    } else if (key === 'refund' || key === 'moveticket') {
      actions.openEditing({
        type: key,
        order: data,
      });
    }
  }

  _change(e, key, data) {
    const target = e ? e.target : null;
    if (key === 'ticket-category' && target) {
      this._updateTicketCategory(target.checked, data.id);
    }
  }

  _reset(config = undefined) {
    const searchConfig = config || this.state.searchConfig;
    (this.refs.searchField as any).value = '';
    searchConfig.text = '';
    searchConfig.from = 0;
    (this.refs.calendar as any).resetIntervalDate();
    this.setState({
      searchConfig,
      interval: [],
      searchAppinstance: null,
    });
    this._initAdvanceFilter('reset');
    this._search('reset', config);
  }

  _search(modus: string | undefined = undefined, config = undefined) {
    if (this.state.error) {
      return;
    }

    const { actions, location } = this.props as any;
    const searchConfig = config || this.state.searchConfig;
    const advance = searchConfig.advance && modus !== 'reset' ? this._getAdvanceFilter(modus) : null;

    const data = {
      from: searchConfig.from,
      size: searchConfig.size,
      sort: searchConfig.sort,
      text: searchConfig.text,
      filters: advance
        ? Object.keys(advance)
          .map((field) => (advance[field]
            ? advance[field] instanceof Array
              ? {
                field,
                value: advance[field].join(','),
              }
              : advance[field]
            : null))
          .filter((data) => !!data)
        : null,
    };

    if (modus !== 'startup') {
      const query = JSON.parse(JSON.stringify(data));
      if (query.filters) {
        query.filters = JSON.stringify(query.filters);
      }

      const stringifiedQuery = stringify(query);
      window.history.replaceState(null, null, `${location.pathname}?${stringifiedQuery}`);
    }

    if (data.sort && data.sort.match(/^(mobileinstanceid|tickettype|clientuseragent|orderstatus|cardtype)/i)) {
      data.sort = data.sort.replace(':', '.keyword:');
    }

    actions.search(data);
  }

  _getAdvanceFilter(modus) {
    if (!this.state.searchConfig.advance) {
      return null;
    }

    let advance = null;
    const textDate = convertDateToText(new Date(), null, true, true).replace(/\s+/, 'T');
    [TicketTypeList, TicketCategoryList, OrderStatusList].forEach((list) => {
      list.forEach((src, i) => {
        const { field } = src;
        const input = this.refs[`${field}-${i}`];
        if (!input) {
          return;
        }

        const { value } = input as any;
        if (input && (input as any).checked) {
          if (!advance) {
            advance = {};
          }
          if (!advance[field]) {
            advance[field] = [];
          }

          if (src.type === 'category') {
            if (value.match(/^not/i)) {
              advance[field] = {
                field,
                type: 'date',
                gte: textDate,
              };
            } else {
              advance[field] = {
                field,
                type: 'date',
                lte: textDate,
              };
            }
          } else {
            advance[field].push(value);
          }
        }
      });
    });

    let interval = this.state.interval || [];
    if ((!interval || !interval[0] || !interval[1]) && modus === 'startup') {
      // tempary hack for fixing fileter with date by refreshing the page
      const parsed = parse(this.props.location.search).filters;
      const filters = parsed ? JSON.parse(parsed as any) : [];
      const date = filters.find((d) => d.field === 'dateordered');
      if (date) {
        moment.locale('nb');
        const gte = moment(date.gte).unix();
        const lte = moment(date.lte).unix();
        const fDate = new Date(gte * 1000);
        const tDate = new Date(lte * 1000);
        interval = [fDate.getTime(), tDate.getTime()];
      }
    }

    if (interval && interval[0] && interval[1]) {
      if (!advance) {
        advance = {};
      }
      const dates = [new Date(interval[0]), new Date(interval[1])];
      let fDate = convertDateToText(dates[0], null, true, true);
      let tDate = convertDateToText(dates[1], null, true, true);

      fDate = fDate.replace(/\s+/, 'T');
      tDate = tDate.replace(/\s+/, 'T');
      advance.dateordered = {
        field: 'dateordered',
        type: 'date',
        gte: fDate,
        lte: tDate,
      };
    }

    if (this.refs.productid && (this.refs.productid as any).value) {
      if (!advance) {
        advance = {};
      }
      advance.productid = {
        field: (this.refs.productid as any).name,
        value: (this.refs.productid as any).value,
      };
    }
    return advance;
  }

  _initAdvanceFilter(modus = undefined) {
    const { location } = window;
    const query = parse(location.search) || {};

    const filters = query.filters ? JSON.parse((query as any).filters) : null;
    const isReset = modus === 'reset';

    if (!filters && !isReset) {
      return;
    }

    const reduced = isReset
      ? {}
      : filters.reduce((prev, data) => {
        if (data.field === 'dateordered' || data.field === 'expirationdate' || data.field === 'startdate') {
          prev[data.field] = data;
        } else {
          prev[data.field] = {};
          (data.value || '').split(',').forEach((v) => {
            prev[data.field][`${v}`] = 1;
          });
        }
        return prev;
      }, {});

    [TicketTypeList, TicketCategoryList, OrderStatusList].forEach((list) => {
      list.forEach((src: TicketType | TicketCategory | OrderStatus, i: number) => {
        const { field } = src;
        const input = this.refs[`${field}-${i}`];
        if (!input) {
          return;
        }

        const { value } = input as any;
        if (isReset) {
          (input as any).checked = false;
          if ('type' in src && src.type === 'category') {
            (input as any).removeAttribute('disabled');
          }
        } else if (reduced[field] && reduced[field][value]) {
          (input as any).checked = true;
        } else if ('type' in src && src.type === 'category' && reduced[field]) {
          if (value.match(/^not/i) && reduced[field].gte) {
            (input as any).checked = true;
            this._updateTicketCategory(true, src.id);
          } else if (!value.match(/^not/i) && reduced[field].lte) {
            (input as any).checked = true;
            this._updateTicketCategory(true, src.id);
          }
        }
      });
    });

    if (!isReset && reduced.dateordered && reduced.dateordered.gte && reduced.dateordered.lte) {
      moment.locale('nb');
      const gte = moment(reduced.dateordered.gte).unix();
      const lte = moment(reduced.dateordered.lte).unix();
      const fDate = new Date(gte * 1000);
      const tDate = new Date(lte * 1000);
      const interval = [fDate.getTime(), tDate.getTime()];
      if ((this.refs.calendar as any).setIntervalDate(interval)) {
        this.setState({ interval });
      }
    }

    if (this.refs.productid) {
      if (isReset) {
        (this.refs.productid as any).value = '';
      } else if (reduced.productid) {
        const ids = [];
        for (const id in reduced.productid) {
          ids.push(id);
        }
        (this.refs.productid as any).value = ids.join(',');
      }
    }
  }



  _initProductList(productId) {
    const sortByName = (a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0
    };

    const storage = {};
    let key = '';
    const list = [];
    for (key in productId || {}) {

      if (!storage[productId[key]]) {
        storage[productId[key]] = [];
      }
      storage[productId[key]].push(key);
    }

    for (key in storage) {
      list.push({
        id: storage[key].join(','),
        name: key,
        field: 'productid',
      });
    }
    list.sort(sortByName);
    return list;
  }

  _calendarCallback(data) {
    const { error } = this.state;

    const current = data.error ? [] : [data.stampFrom, data.stampTo];
    this.setState({
      interval: current,
      error: data.error ? error || data.error : '',
    });
  }

  _updateTicketCategory(checked: boolean, id: string) {
    if (!id) {
      return;
    }
    const pin = id.match(/^not/i) ? id.replace(/^not/i, '') : `not${id}`;
    const input = document.getElementById(`ticket-category-${pin}`);
    if (!input) {
      return;
    }

    if (checked) {
      input.setAttribute('disabled', checked.toString());
    } else {
      input.removeAttribute('disabled');
    }
  }
}


function mapStateToProps(state: RootState) {
  return {
    storage: state.oos,
    productId: getProductIdData(state.accessToken.CompanyId),
    accessToken: state.accessToken,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, null, AnyAction>) => ({
  actions: {
    search: (data: SearchData, callback: (response: any) => void) => dispatch(search(data, callback, 'search')),
    openEditing: (data: OosEditing) => dispatch(openEditing(data)),
    dispatch,
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
