import React from 'react';

function YesNoColumn(props) {
  const { yes } = props;

  return (
    <td>
      {yes ? "ja" : "Nei"}
    </td>
  );
}

export default YesNoColumn;
