import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { change, Field, FormErrors, InjectedFormProps, reduxForm } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { AppEditingTypes, OosEditing, OrderEditingTypes, RootState } from '../../app/interfaces';
import { closeEditing } from '../../oos-action-creators';
import { cityBikeAction, postSAPI, search } from '../../oos-actions';
import { CityBikeData, PostSapiData, SearchData } from '../../oosAjax';
import { CountryCodeList } from '../../static/data/CountryCodeList';
import Textarea from '../forminput/Textarea';
import Textfield from '../forminput/Textfield';
import { createRegexp, getCountDayDifferent, isSingleTicket, scrollBodyTop, trim } from '../General';
import { Suggestion } from '../suggestion/Suggestion';
import './Editing.scss';
import { EditingTableList } from './EditingTableList';

type EditingComponentProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface EditingComponentState {
  error: string;
  success: boolean;
  loading: boolean;
  titles: { [key in OrderEditingTypes | AppEditingTypes | 'citybikerefund']: string };
  buttons: { [key in OrderEditingTypes | AppEditingTypes | 'citybikerefund']: string };
  calculated: string;
  successMessage: { [key: string]: string };
}

class EditingComponent extends React.Component<EditingComponentProps & InjectedFormProps<FormData>,
  EditingComponentState> {
  constructor(props: EditingComponentProps & InjectedFormProps<FormData>) {
    super(props);
    this.state = {
      error: '',
      success: false,
      loading: false,
      titles: {
        refund: 'Refunder',
        moveticket: 'Flytt billett',
        sendreceipt: 'Kvittering',
        deletepayment: 'Betalingsavtale',
        deletephone: 'Slett telefonnummer',
        openreconstruct: 'Åpne overføring',
        blockreconstruct: 'Sperre overføring',
        citybikeblacklist: 'Svartelist bruker',
        citybikeremoveblacklist: 'Opphev svartelistet bruker',
        citybikerefund: 'Refunder',
        toggleemail: 'Sperre e-post',
        cancelorder: 'Kansellere ordre',
        sendpickupcode: 'Send hentekode'
      },
      buttons: {
        refund: 'Refunder',
        moveticket: 'Flytt',
        sendreceipt: 'Send',
        deletepayment: 'Slett',
        deletephone: 'Slett',
        openreconstruct: 'Åpne',
        blockreconstruct: 'Sperre',
        citybikeblacklist: 'Svartelist',
        citybikeremoveblacklist: 'Opphev svartelisting',
        citybikerefund: 'Refunder',
        toggleemail: 'Sperre',
        cancelorder: 'Kanseller',
        sendpickupcode: 'Send'
      },
      calculated: '',
      successMessage: {
        refund: 'Refundering er sendt.',
        moveticket: 'Ordre er flyttet.',
        citybikeblacklist: 'Svartelisting utført.',
        citybikeremoveblacklist: 'Svartelisting opphevet.',
        citybikerefund: 'Refundering er sendt.',
        toggleemail: 'Sperring er satt på e-post ',
        sendreceipt: 'Kvittering er sendt til ',
        deletepayment: 'Betalingen er slettet.',
        cancelorder: 'Ordre er kansellert',
        sendpickupcode: 'Hentekode sendt til '
      }
    };
  }

  render() {
    const { type, handleSubmit } = this.props;

    const { error, loading, titles, buttons, success, successMessage, calculated } = this.state;
    if (!this.props.type) {
      return null;
    }
    const source =
      'order' in this.props
        ? this.props.order._source
        : 'app' in this.props
          ? this.props.app
          : this.props.cbOrder._source;

    const title = titles[type] || 'Editering';
    const button = buttons[type] || 'Submit';
    const disabledAmount = this.props.type === 'refund' && isSingleTicket(this.props.order._source.tickettype);

    const successMsg =
      (successMessage[type] || 'Handlingen er utført.') +
      (type === 'sendreceipt' && this.refs && this.refs.email ? (this.refs.email as Field).value : '') +
      (type === 'toggleemail' && this.refs && this.refs.email ? (this.refs.email as Field).value : '') +
      (type === 'sendpickupcode' && this.refs && this.refs.country && this.refs.phone
        ? trim([(this.refs.country as Field).value, (this.refs.phone as Field).value].join(' '), true)
        : '');

    const showRefundAmount = this.props.type === 'refund' || this.props.type === 'citybikerefund';
    const initialRefundAmount = this.props.type === 'refund' ? this.props.order._source.totalprice : parseInt((source as any).Amount)
    return (
      <div className="editing-wrapper">
        <div className={'editing-frame paper' + (loading ? ' -loading' : '') + (success ? ' -success' : '')}>
          <div className="editing-header">
            <h2>{title}</h2>
            <button
              id="editing-close-btn"
              className="icon-btn editing-closer -cross"
              onClick={this._close}
            >
              <span className="aria-visible">Lukk editeringen</span>
            </button>
          </div>
          <div className="editing-content">
            <div className="editor-wrapper">
              <EditingTableList type={type} source={source} />
              <div className="form-wrapper">
                <form name="editingFrom" noValidate onSubmit={handleSubmit(this._submit)} id={'editing-form-' + type}>
                  {error ? (
                    <div className="summary-error align-center" role="alert">
                      {error}
                    </div>
                  ) : null}

                  {showRefundAmount && (
                    <div className="refund-textfield-wrapper">
                      {calculated !== '' && <div className="calculated">{calculated}</div>}
                      <Field
                        name="amount"
                        type="tlf"
                        id="editing-amount"
                        label="Refunder beløp"
                        autoComplete="off"
                        spellCheck={false}
                        autoCapitalize="off"
                        autoCorrect="off"
                        component={Textfield}
                        props={{
                          required: true,
                          data: initialRefundAmount,
                          disabled: disabledAmount
                        }}
                      />
                    </div>
                  )}

                  {type === 'sendpickupcode' && (
                    <div className="send-pickup-code-textfield-wrapper">
                      <div className="input-content export-type-content">
                        <label htmlFor="export-type-selector">Landkode</label>
                        <select ref="country" className="select-box export-type-selector" id="export-type-selector">
                          {CountryCodeList.map((data, i) => (
                            <option key={'country-code-' + i} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Field
                        name="phone"
                        type="tel"
                        id="editing-phone"
                        label="Telefonnummer"
                        autoComplete="off"
                        spellCheck={false}
                        autoCapitalize="off"
                        autoCorrect="off"
                        component={Textfield}
                        props={{ required: true }}
                        ref="phone"
                      />
                    </div>
                  )}

                  {this.props.type === 'moveticket' && (
                    <div className="move-ticket-field-wrapper">
                      <Field
                        name="appid"
                        type="text"
                        id="editing-appid"
                        label="Ny app-id"
                        autoComplete="off"
                        spellCheck={false}
                        autoCapitalize="off"
                        autoCorrect="off"
                        component={Textfield}
                        props={{
                          required: true,
                          data: this.props.order._source.mobileinstanceid
                        }}
                        onKeyUp={(e: React.FormEvent) => {
                          this._keyup(e, 'appid');
                        }}
                        onKeyDown={(e: React.FormEvent) => {
                          this._keydown(e, 'appid');
                        }}
                        onFocus={(e: React.FormEvent) => {
                          this._focus(e, 'appid');
                        }}
                        onBlur={(e: React.FormEvent) => {
                          this._blur(e, 'appid');
                        }}
                      />
                      <Suggestion
                        label="AppId"
                        ref="appidSuggestion"
                        searchOverwrite={this._searchAppId}
                        beforeAddSuggestion={this._beforeAddAppId}
                      />
                    </div>
                  )}

                  {(type === 'sendreceipt' || type === 'toggleemail') && (
                    <Field
                      name="email"
                      type="email"
                      id="editing-email"
                      label="E-post"
                      autoComplete="off"
                      spellCheck={false}
                      autoCapitalize="off"
                      autoCorrect="off"
                      component={Textfield}
                      props={{ required: true }}
                      ref="email"
                    />
                  )}

                  {(type === 'refund' ||
                    type === 'moveticket' ||
                    type === 'deletephone' ||
                    type === 'deletepayment' ||
                    type === 'openreconstruct' ||
                    type === 'blockreconstruct' ||
                    type === 'toggleemail' ||
                    type === 'citybikeblacklist' ||
                    type === 'citybikeremoveblacklist' ||
                    type === 'cancelorder' ||
                    type === 'citybikerefund' ||
                    type === 'sendpickupcode') && (
                      <Field
                        name="comment"
                        label="Merknad"
                        autoComplete="off"
                        spellCheck={false}
                        autoCapitalize="off"
                        autoCorrect="off"
                        component={Textarea}
                        props={{ required: true }}
                      />
                    )}

                  <div className="form-action-holder">
                    <button type="submit" className="primary-btn">
                      {button}
                    </button>
                    <button
                      className="secondary-btn"
                      onClick={this._close}
                    >
                      Avbryt
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="success-wrapper">
              <div className="success-message">{successMsg}</div>
            </div>
          </div>
          <div className="editing-footer">
            <div id="appid-list" className="hide"></div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const order = 'order' in this.props ? this.props.order : undefined;
    const cbOrder = 'cbOrder' in this.props ? this.props.cbOrder : undefined;
    const { type, actions } = this.props;
    if (type === 'refund' && order) {
      const source = order._source;
      if (isSingleTicket(source.tickettype)) {
        actions.dispatch(change('editingFrom', 'amount', source.totalprice));
      } else {
        const left = getCountDayDifferent(source.expirationdate, null, true, true);
        if (left <= 0) {
          actions.dispatch(change('editingFrom', 'amount', source.totalprice));
        } else {
          let countDayDifferent = getCountDayDifferent(source.startdate, source.expirationdate);
          if (!countDayDifferent || isNaN(countDayDifferent)) {
            countDayDifferent = 30;
          }
          countDayDifferent = Math.round(countDayDifferent);
          let price = (source.totalprice / countDayDifferent) * left;
          if (price && !isNaN(price)) {
            price = parseInt(price + '');
            const greatThen = price > source.totalprice;
            actions.dispatch(change('editingFrom', 'amount', greatThen ? source.totalprice : price));
            if (!greatThen) {
              this.setState({
                calculated: 'Beregning: (' + source.totalprice + '/' + countDayDifferent + ') * ' + left
              });
            }
          }
        }
      }
    } else if (type === 'citybikerefund' && cbOrder) {
      actions.dispatch(change('editingFrom', 'amount', cbOrder._source.Amount));
    } else if (type === 'sendpickupcode') {
      actions.dispatch(change('editingFrom', 'country', '+47'));
    }

    scrollBodyTop(0);
  }

  _submit = (data: FormData) => {
    if (this.state.success) {
      return;
    }

    const { actions } = this.props;
    const order = 'order' in this.props ? this.props.order : undefined;
    if (order) {
      data.orderid = order._id;
    }

    if (!this.props.valid) {
      return this.setState({ error: 'Valideringsfeil.', loading: false });
    }

    const recall = (response: { error: { message: string }; success: boolean }) => {
      if (response.error || !response.success) {
        let errorMessage = response.error.message || 'Noe gikk galt.';

        if ((response as any)?.error?.response?.data?.status === 400 && ((response as any)?.error?.response?.data?.message || "").indexOf('Already refunded') !== -1) {
          errorMessage = 'Ordren er allerede refundert';
        }

        this.setState({
          error: errorMessage,
          loading: false
        });
      } else {
        this.setState({ error: '', loading: false, success: true });
        if (this.props.type === 'citybikeblacklist' || this.props.type === 'citybikeremoveblacklist' || this.props.type === 'citybikerefund') {
          window.location.reload();
        }
      }
    };

    this.setState({ error: '', loading: true, success: false });
    switch (this.props.type) {
      case 'citybikeblacklist':
        data.reasonId = 'MANUELL';
        data.endUserId = this.props.app.citybike_enduserid || '';
        data.instanceid = this.props.app.instanceid;
        (data as CityBikeData).platformVersion = this.props.app.platform_version;  
        break;
      case 'citybikeremoveblacklist':
        data.endUserId = this.props.app.citybike_enduserid || '';
        data.instanceid = this.props.app.instanceid;
        // Use unblacklist backend service corresponding to the platform version from the blacklisting
        (data as CityBikeData).platformVersion = this.props.app.citybike_blacklist.platform_version; 
        break;
      case 'deletephone':
      case 'blockreconstruct':
      case 'openreconstruct':
        data.instanceid = this.props.app.instanceid;
        break;
      case 'citybikerefund':
        data.endUserId = this.props.cbOrder._source.EndUserId || '';
        data.instanceid = this.props.cbOrder._source.InstanceId;
        data.orderid = this.props.cbOrder._source.OrderId;
        // Deduce the backend service version from the order number (v2 order ids have dashes)        
        (data as CityBikeData).platformVersion = this.props.cbOrder._source.OrderId.indexOf("-") >= 0 ? "v2" : "v1";
        (data as CityBikeData).referenceId = this.props.cbOrder._source.ReferenceId;
        break;
      case 'deletepayment':
        data.agreementid = this.props.order._source.agreementid || '';
        break;
      case 'toggleemail':
        data.mobileinstanceid = this.props.app.mobileinstanceid || this.props.app.AppId || this.props.app.instancename;
        break;
    }
    actions[this.props.type](data, recall);
  };

  _close = (e: React.FormEvent) => {
    e.preventDefault();
    const { actions } = this.props;
    actions.closeEditing();
  };

  /****************************************************************************
  ===  ===
  ****************************************************************************/
  _keydown = (e: React.FormEvent, key: string) => {
    if (key === 'appid') {
      (this.refs.appidSuggestion as Suggestion).keydown(e);
    }
  };

  _keyup = (e: React.FormEvent, key: string) => {
    if (key === 'appid') {
      (this.refs.appidSuggestion as Suggestion).keyup(e);
    }
  };

  _focus = (e: React.FormEvent, key: string) => {
    if (key === 'appid') {
      (this.refs.appidSuggestion as Suggestion).focus(e);
    }
  };

  _blur = (e: React.FormEvent, key: string) => {
    if (key === 'appid') {
      (this.refs.appidSuggestion as Suggestion).blur(e);
    }
  };

  _beforeAddAppId = (data: { name: string }) => {
    this.props.actions.dispatch(change('editingFrom', 'appid', data.name));
    return false;
  };

  _searchAppId = (
    text: string,
    suggestionCallback: (arg0: { text: string; reg: RegExp; matchedList: number[] }) => void
  ) => {
    const appidSuggestion = this.refs.appidSuggestion as Suggestion;
    if (appidSuggestion && text) {
      appidSuggestion.setLoading(true);
    }

    this.props.actions.search(
      {
        pathname: '/appinstances',
        type: 'appinstances',
        text: text
      },
      response => {
        //console.log('=== response ==='); console.log( response );
        // remove every character that is not used in appsearch
        text = text.replace(/([^A-Za-z0-9\-]+)/, ""); // eslint-disable-line
        const result = response || {};
        const reg = createRegexp(text, 1, 1, 1);
        const pin: any = {};
        //TODO finn ut hva result er så disse kan settes riktig.
        const list = ((result.hits || {}).hits || []).reduce((prev: any, hit: any) => {
          prev.push({ id: hit._id, name: hit._id, reg: reg });
          pin[hit._id] = 1;
          return prev;
        }, []);
        const holder = document.getElementById('appid-list');
        if (holder && list.length) {
          holder.innerHTML = JSON.stringify(pin);
        }
        suggestionCallback({ text: text, reg: reg, matchedList: list });
      }
    );
  };
}

const validate = (values: FormData) => {
  const errors: FormErrors<FormData> = {};
  let input: HTMLElement | null = null;

  if (!values.amount) {
    errors.amount = 'Beløp er påkrevd.';
  } else if (!/^[0-9]{1,6}/i.test('' + values.amount)) {
    errors.amount = 'Ugyldig beløp.';
  } else {
    input = document.getElementById('editing-amount');
    const value = parseFloat('' + values.amount);
    const max = input ? parseFloat(input.getAttribute('data') || '') : null;
    if (max && !isNaN(max) && max < value) {
      errors.amount = 'Beløpet kan ikke være høyere enn ' + max + ' kr.';
    } else if (value <= 0) {
      errors.amount = 'Beløpet må være høyere enn 0';
    }
  }

  if (!values.appid) {
    errors.appid = 'Appid er påkrevd.';
  } else if (!/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{2}$/.test(values.appid)) {
    errors.appid = 'Ugyldig appid.';
  } else {
    input = document.getElementById('editing-appid');
    const curent = input ? input.getAttribute('data') || '' : null;
    if (curent && curent === values.appid) {
      //errors.appid = 'Den nye App-id kan ikke være samme som nåværende App-id.';
    } else {
      const holder = document.getElementById('appid-list');
      if (holder) {
        try {
          const pin = JSON.parse(holder.innerHTML || '{}');
          const appid = trim(values.appid, true);
          if (!pin[appid.toUpperCase()]) {
            errors.appid = 'App-id fins ikke.';
          }
        } catch (error) {
          errors.appid = 'Feil ved å sjekke om App-id eksisterer.';
        }
      } else {
        errors.appid = 'Feil ved Elastic-search, der det gir ingen søkeresultat';
      }
    }
  }

  if (!values.email) {
    errors.email = 'Påkrevd.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Ugyldig e-post.';
  }

  if (!values.comment) {
    errors.comment = 'Merknaden er påkrevd.';
  }

  if (!values.country) {
    errors.country = 'Påkrevd.';
  } else if (!/^\+([0-9]{2}(\s+)?|[0-9]{3})$/i.test(values.country)) {
    errors.country = 'Ugyldig.';
  }

  if (!values.phone) {
    errors.phone = 'Telefonnummer er påkrevd.';
  } else {
    const trimed = trim(values.phone, true);
    if (!/^[1-9][0-9]{7,8}$/i.test(trimed)) {
      errors.phone = 'Telefonnummer er ugyldig.';
    }
  }

  return errors;
};

interface FormData {
  amount: number;
  appid: string;
  email: string;
  comment: string;
  country: string;
  phone: string;
  orderid: string;
  value: string;
  instanceid: string;
  agreementid: string;
  mobileinstanceid: string;
  reasonId: string;
  endUserId: string;
  platformVersion: string | null | undefined;
}

const mapStateToProps = (state: RootState): OosEditing => {
  return state.oos.editing ?? {} as OosEditing;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, null, AnyAction>) => ({
  actions: {
    dispatch,
    search: (data: SearchData, callback: (response: any) => void) => dispatch(search(data, callback, 'search')),
    closeEditing: () => dispatch(closeEditing()),

    refund: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'refund')),
    moveticket: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'moveticket')),
    citybikerefund: (data: CityBikeData, callback: (response: any) => void) => dispatch(cityBikeAction(data, callback, "citybikerefund")),
    deletephone: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'deletephone')),
    openreconstruct: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'openreconstruct')),
    blockreconstruct: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'blockreconstruct')),
    toggleemail: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'toggleemail')),
    citybikeblacklist: (data: CityBikeData, callback: (response: any) => void) => dispatch(cityBikeAction(data, callback, "citybikeblacklist")),
    citybikeremoveblacklist: (data: CityBikeData, callback: (response: any) => void) => dispatch(cityBikeAction(data, callback, "citybikeremoveblacklist")),
    sendreceipt: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'sendreceipt')),
    deletepayment: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'deletepayment')),
    cancelorder: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'cancelorder')),
    sendpickupcode: (data: PostSapiData, callback: (response: any) => void) => dispatch(postSAPI(data, callback, 'sendpickupcode')),
  }
})

const Editing = connect(mapStateToProps, mapDispatchToProps)(EditingComponent);
export default reduxForm<FormData>({ form: 'editingFrom', validate })(Editing);

