export const ProductIdOKT = {
  '18001': 'Enkeltbillett voksen 20-66',
  '18016': 'Enkeltbillett barn/ungdom',
  '18021': 'Enkeltbillett honnør',
  '18066': 'Enkeltbillett BuzzBilli BHG/skole',
  '18076': 'Periodebillett Nedre Glomma 30d voksen 25-66',
  '18111': 'Periodebillett Nedre Glomma 60d voksen 25-66',
  '18146': 'Periodebillett Østfoldkort 24 timer voksen 25-66',
  '18151': 'Periodebillett Østfoldkort 24 timer ung voksen',
  '18156': 'Periodebillett Østfoldkort 24 timer barn/ungdom',
  '18161': 'Periodebillett Østfoldkort 24 timer honnør',
  '18166': 'Periodebillett Østfoldkort 24 timer student',
  '18181': 'Periodebillett Østfoldkort 3 dager voksen 25-66',
  '18186': 'Periodebillett Østfoldkort 3 dager ung voksen',
  '18191': 'Periodebillett Østfoldkort 3 dager barn/ungdom',
  '18196': 'Periodebillett Østfoldkort 3 dager honnør',
  '18201': 'Periodebillett Østfoldkort 3 dager student',
  '18216': 'Periodebillett Østfoldkort 7 dager voksen 25-66',
  '18221': 'Periodebillett Østfoldkort 7 dager ung voksen',
  '18226': 'Periodebillett Østfoldkort 7 dager barn/ungdom',
  '18231': 'Periodebillett Østfoldkort 7 dager honnør',
  '18236': 'Periodebillett Østfoldkort 7 dager student',
  '18251': 'Periodebillett Østfoldkort 30 dager voksen 25-66',
  '18256': 'Periodebillett Østfoldkort 30 dager ung voksen',
  '18261': 'Periodebillett Østfoldkort 30 dager barn/ungdom',
  '18266': 'Periodebillett Østfoldkort 30 dager honnør',
  '18271': 'Periodebillett Østfoldkort 30 dager student',
  '18286': 'Periodebillett Østfoldkort 180 dager voksen 25-66',
  '18291': 'Periodebillett Østfoldkort 180 dager ung voksen',
  '18296': 'Periodebillett Østfoldkort 180 dager barn/ungdom',
  '18301': 'Periodebillett Østfoldkort 180 dager honnør',
  '18306': 'Periodebillett Østfoldkort 180 dager student',
  '18321': 'Periodebillett Østfoldkort 60 dager voksen 25-66',
  '18324': 'Periodebillett Østfoldkort 60 dager ung voksen',
  '18331': 'Periodebillett Østfoldkort 60 dager barn/ungdom',
  '18336': 'Periodebillett Østfoldkort 60 dager honnør',
  '18341': 'Periodebillett Østfoldkort 60 dager student',
  '18361': 'Enkeltbillett Flexx Natt ung voksen',
  '18386': 'Enkeltbillett (Hvalerferga) voksen 20-66',
  '18401': 'Enkeltbillett (Hvalerferga) barn/ungdom',
  '18406': 'Enkeltbillett (Hvalerferga) honnør',
  '18421': 'Enkeltbillett (Hvalerferga) MC',
  '18426': 'Enkeltbillett (Hvalerferga) Bil/ATV < 6m',
  '18431': 'Enkeltbillett (Hvalerferga) Bil/ATV 6-8m',
  '18436': 'Enkeltbillett (Hvalerferga) Bil/ATV 8-10m',
  '18441': 'Enkeltbillett (Hvalerferga) Elbil',
  '18491': 'Periodebillett Hvalerferga 24 timer voksen 25-66',
  '18496': 'Periodebillett Hvalerferga 24 timer ung voksen',
  '18501': 'Periodebillett Hvalerferga 24 timer barn/ungdom',
  '18506': 'Periodebillett Hvalerferga 24 timer honnør',
  '18511': 'Periodebillett Hvalerferga 24 timer student',
  '18526': 'Periodebillett Hvalerferga 3 dager voksen 25-66',
  '18531': 'Periodebillett Hvalerferga 3 dager ung voksen',
  '18536': 'Periodebillett Hvalerferga 3 dager barn/ungdom',
  '18541': 'Periodebillett Hvalerferga 3 dager honnør',
  '18546': 'Periodebillett Hvalerferga 3 dager student',
  '18561': 'Periodebillett Hvalerferga 7 dager voksen 25-66',
  '18566': 'Periodebillett Hvalerferga 7 dager ung voksen',
  '18571': 'Periodebillett Hvalerferga 7 dager barn/ungdom',
  '18576': 'Periodebillett Hvalerferga 7 dager honnør',
  '18581': 'Periodebillett Hvalerferga 7 dager student',
  '18596': 'Periodebillett Hvalerferga 30 dager voksen 25-66',
  '18601': 'Periodebillett Hvalerferga 30 dager ung voksen',
  '18606': 'Periodebillett Hvalerferga 30 dager barn/ungdom',
  '18611': 'Periodebillett Hvalerferga 30 dager honnør',
  '18616': 'Periodebillett Hvalerferga 30 dager student',
  '18631': 'Periodebillett Hvalerferga 180 dager voksen 25-66',
  '18636': 'Periodebillett Hvalerferga 180 dager ung voksen',
  '18641': 'Periodebillett Hvalerferga 180 dager barn/ungdom',
  '18646': 'Periodebillett Hvalerferga 180 dager honnør',
  '18651': 'Periodebillett Hvalerferga 180 dager student',
  '18666': 'Periodebillett Hvalerferga 365 dager Voksen',
  '18681': 'Periodebillett Hvalerferga 365 dager Honnør',
  '18701': 'Enkeltbillett Nedre Glomma Voksen',
  '18711': 'Enkeltbillett Nedre Glomma Barn',
  '18716': 'Enkeltbillett Nedre Glomma Honnør',
  '18736': 'Periodebillett 365 dager voksen',
  '18751': 'Periodebillett 365 dager honnør',
  '18766': 'Enkeltbillett Nedre Glomma fritid barn',
  '18768': 'Enkeltbillett Nedre Glomma fritid honnør',
  '18771': 'Periodebillett Nedre Glomma 24 timer voksen',
  '18781': 'Periodebillett Nedre Glomma 7 dager voksen',
  '18791': 'Periodebillett Nedre Glomma 365 dager voksen',
  '18801': 'HjemJobbHjemm Østfold',
  '18811': 'HjemJobbHjemm Nedre Glomma',
  '': ''
};
