import { AccessToken } from "./accessToken/actionTypes";
import { Company } from "./constants";

const getDecodedAccessToken = () => (window as any).decodedAccessToken as AccessToken

const getRequestorCodeFromToken = (): string => {
  const companyId = getDecodedAccessToken().CompanyId;

  if(companyId === Company.Kolumbus){
    return [Company.Kolumbus, 507].join(','); // Skynet billetter
  }
  if(companyId === Company.Brakar){
    return [Company.Brakar, 508].join(','); // Fribilletter
  }
  return companyId.toString();
}

export default getRequestorCodeFromToken;