import React from 'react';
import { Link } from 'react-router-dom';
import { CitybikeOrderSource, SearchResult } from '../app/interfaces';
import { getEnvName, convertToHumanDate } from '../common/General';

interface Props {
  orders: SearchResult<CitybikeOrderSource>[];
  refundAction: (orderline: SearchResult<CitybikeOrderSource>) => void;
}

const envName = getEnvName();
const envSuffix = envName == 'prod' ? "" : envName == 'dev' ? ".dev" : ".test";
const domain = envName == 'dev' ? "transhub.io" : "kollektivkameratene.no";
const newKsp = `https://ksp${envSuffix}.${domain}/V2/order/citybike/`;

export const CityBikeOrdersTable: React.FC<Props> = ({ orders, refundAction }) => (
  <div className="order-wrapper">
    <div>
      <ul className="table-list home-table-list -break-to-single-column-when-md-p">
        <li className="table-header">
          <div className="column app-id">App-ID</div>
          <div className="column order-id">Billettnummer</div>
          <div className="column bike-id no-wrap">Sykkel-ID</div>
          <div className="column price">Beløp</div>
          <div className="column price">Refundert</div>
          <div className="column price">Gjenstående</div>
          <div className="column description">Beskrivelse</div>
          <div className="column date">Turstart</div>
          <div className="column date">Turslutt</div>
          <div className="column date">Betalingsstatus</div>
          <div className="column refund">Refunder</div>
          <div className="column refunded-by">Refundert av</div>
          <div className="column refund-comment">Merknad</div>
        </li>
        {orders.map((src, j) => (
          <li key={'log-' + j}>
            <div className="column app-id no-wrap" aria-label="AppId">
              <Link
                className="no-wrap link"
                to={{
                  pathname: '/viewappinfo',
                  search: `?id=${src._source.InstanceName}`
                }}
              >
                {src._source.InstanceName}
              </Link>
            </div>
            <div className="column order-id no-wrap" aria-label="OrdreId">
              {src._source.OrderId}
            </div>
            <div className="column bike-id" aria-label="SykkelId">
              {src._source.BikeId}
            </div>
            <div className="column price" aria-label="Beløp">
              {src._source.Amount}
            </div>
            <div className="column price" aria-label="Beløp">
              {src._source.RemainingAmount ? (Number(src._source.Amount) - Number(src._source.RemainingAmount)).toFixed(2) : '-'}
            </div>
            <div className="column price" aria-label="Beløp">
              {src._source.RemainingAmount ? src._source.RemainingAmount : '-'}
            </div>
            <div className="column description" aria-label="Beskrivelse">
              {src._source.Description}
            </div>
            <div className="column date no-wrap" aria-label="Turstart">
              {convertToHumanDate(src._source.TripStart)}
            </div>
            <div className="column date no-wrap" aria-label="Turslutt">
              {convertToHumanDate(src._source.TripEnd)}
            </div>
            <div className="column date no-wrap" aria-label="Betalingsstatus">
              {src._source.OrderStatus}
            </div>
            <div className="column refund" aria-label="Refunder">
              {src._source.OrderStatus === 'FINISH' && src._source.app_platform_version !== 'v2' && (
                <button
                  title="Refunder penger"
                  className="icon-btn -refund -blue"
                  onClick={() => {
                    refundAction(src);
                  }}
                >
                  <span className="aria-visible">Refunder ordre</span>
                </button>
              )}
              {src._source.OrderStatus === 'FINISH' && src._source.app_platform_version === 'v2' && (
                <a href={newKsp + src._source.OrderId} className="icon-btn -refund -blue"></a>
              )}
            </div>
            <div className="column refunded-by" aria-label="Refundert av">
              {src._source.creditedby}
            </div>
            <div className="column refund-comment" aria-label="Merknad">
              {src._source.comment}
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
