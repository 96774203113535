import React, { Component, SyntheticEvent } from 'react';
import { AgreementsSource, AppEditingTypes, AppInstancesLog, AppSource, CitybikeOrderSource, DownloadSource, OrderEditingTypes, OrdersLog, OrderSource, PaymentSource, SearchResult, BlackListLog } from '../../app/interfaces';
import { HomeTableList } from '../../home/HomeTableList';
import { convertToHumanDate, getDocumentScrollPosition, getEnvName, scrollBodyTop } from '../General';
import './Tablist.scss';
import BlacklistLogComponent from './BlacklistLogComponent';

interface TablistProps {
  active?: string;
  list: TabContent[];
  callback: (
    event: SyntheticEvent,
    key: OrderEditingTypes | AppEditingTypes | 'citybikerefund',
    data: AppSource | SearchResult<OrderSource> | SearchResult<CitybikeOrderSource>
  ) => void;
  actions: any;
  parent: string;
  productId: unknown;
  hasWriteAccess: boolean;
}

type TabTypes = 'info' | 'log' | 'download' | 'order' | 'payment' | 'appinstancelog' | 'agreement' | 'citybike' | 'blacklist';

interface Tab {
  id: TabTypes;
  name: string;
}

interface InfoTab extends Tab {
  id: 'info';
  order: OrderSource;
  content: PaymentSource & OrderSource;
}

interface LogTab extends Tab {
  id: 'log';
  order: OrderSource;
  content: SearchResult<OrdersLog>[];
}

interface DownloadTab extends Tab {
  id: 'download';
  order: OrderSource;
  content: SearchResult<DownloadSource>[];
}

interface OrderTab extends Tab {
  id: 'order';
  order: AppSource;
  content: SearchResult<OrderSource>[];
}

interface AppLogTab extends Tab {
  id: 'appinstancelog';
  order: AppSource;
  content: SearchResult<AppInstancesLog>[];
}

interface AgreementTab extends Tab {
  id: 'agreement';
  order: AppSource;
  content: SearchResult<AgreementsSource>[];
}

interface CitybikeTab extends Tab {
  id: 'citybike';
  order: AppSource;
  content: SearchResult<CitybikeOrderSource>[];
}

interface BlackListTab extends Tab {
  id: 'blacklist',
  order: AppSource,
  content: Array<BlackListLog>;
}

export type TabContent = InfoTab | LogTab | DownloadTab | OrderTab | AppLogTab | AgreementTab | CitybikeTab | BlackListTab;

interface TablistState {
  active: string;
  increasingShowMore: number;
  show: ShowState;
}
interface ShowState {
  log: number;
  download: number;
  payment: number;
  order: number;
  agreement: number;
  appinstancelog: number;
  citybike: number;
  blacklist: number;
  '': number;
}

export class Tablist extends Component<TablistProps, TablistState> {
  constructor(props: TablistProps) {
    super(props);
    this.state = {
      active: props.active || props.list[0].id,
      increasingShowMore: 20,
      show: {
        log: 20,
        download: 20,
        payment: 20,
        order: 20,
        agreement: 20,
        appinstancelog: 20,
        citybike: 20,
        blacklist: 20,
        '': 20
      }
    };
  }

  render() {
    const { list, parent, productId, hasWriteAccess } = this.props;
    const { active, show } = this.state;
    const envName = getEnvName();
    const envSuffix = envName == 'prod' ? "" : envName == 'dev' ? ".dev" : ".test";
    const domain = envName == 'dev' ? "transhub.io" : "kollektivkameratene.no";
    
    return (
      <div className="tablist-wrapper tab-wrapper">
        <ul className="tablist tab-list" role="tablist">
          {list.map((data, i) => {
            const selected = data.id === active;
            const type = 'tab-btn secondary-btn -' + data.id + (selected ? ' -active' : '');
            return (
              <li key={'tab-' + i} role="presentation">
                <button
                  role="tab"
                  id={'tab-' + data.id}
                  aria-selected={selected}
                  aria-controls={'panel-' + data.id}
                  className={type}
                  onClick={e => {
                    this._click(e, 'tab', data.id);
                  }}
                >
                  {data.name}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="tablist-content tab-content">
          {list.map((data, i) => {
            const selected = data.id === active;
            const type = 'tab-panel -' + data.id + (selected ? ' -active' : '');

            return (
              <div
                key={'panel-' + i}
                id={'panel-' + data.id}
                aria-hidden={selected ? 'false' : 'true'}
                aria-labelledby={'tab-' + data.id}
                className={type}
              >
                {selected ? (
                  <div className="content">
                    {data.id === 'info' && (
                      <div className="info-wrapper">
                        <ul className="table-list -listing">
                          <li>
                            <div className="column">Betalingsstatus</div>
                            <div className="column">
                              <span>{data.order.paymentstatusid} </span>
                              <span className="shy italic">({data.order.paymentstatus})</span>
                            </div>
                          </li>
                          <li>
                            <div className="column">Betalingsid</div>
                            <div className="column no-wrap">{(data.content as any).payerid}</div>
                          </li>
                          <li>
                            <div className="column">Distribusjonsstatus</div>
                            <div className="column">
                              <span>{data.order.distributionstatusid} </span>
                              <span className="shy italic">({data.order.distributionstatus})</span>
                            </div>
                          </li>
                          <li>
                            <div className="column">Resent</div>
                            <div className="column">{data.order.isresendt ? 'Ja' : 'Nei'}</div>
                          </li>
                          <li>
                            <div className="column">Betalingsmåte</div>
                            <div className="column">
                              <span>{data.order.cardtype} </span>
                              <span className="italic shy">({(data.content as any).maskednumber})</span>
                            </div>
                          </li>
                          <li>
                            <div className="column">Plattform</div>
                            <div className="column">
                              {data.order.clientuseragent}, {data.order.apposversion}
                            </div>
                          </li>
                          <li>
                            <div className="column">Total sum</div>
                            <div className="column">{data.order.totalprice},-</div>
                          </li>
                          <li>
                            <div className="column">MVA</div>
                            <div className="column">
                              {parseFloat((data.content as any).vatamount + '').toFixed(2)}
                              ,-
                            </div>
                          </li>
                          <li>
                            <div className="column">Sone</div>
                            <div className="column">
                              <span>{data.order.nrofzones} </span>
                              <span className="shy italic">
                                (
                                {data.order.zonefrom === data.order.zoneto
                                  ? data.order.zoneto
                                  : [data.order.zonefrom, data.order.zoneto].join(' - ')}
                                )
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="column">NOD ordrestatus</div>
                            <div className="column">
                              <span>{data.order.nodorderstatusid} </span>
                              <span className="shy italic">({data.order.nodorderstatus})</span>
                            </div>
                          </li>
                          <li>
                            <div className="column">NOD sist endret</div>
                            <div className="column no-wrap">
                              {convertToHumanDate((data.content as any).nodlastchangedatetimestamp)}
                            </div>
                          </li>
                          <li>
                            <div className="column">NOD ordregruppe ID</div>
                            <div className="column no-wrap">{(data.content as any).nodordergroupid}</div>
                          </li>
                          <li>
                            <div className="column">Avtale-ID</div>
                            <div className="column no-wrap">{(data.content as any).agreementid}</div>
                          </li>
                          <li>
                            <div className="column">Betalingsmetode</div>
                            <div className="column no-wrap">{(data.content as any).methodofpaymentid}</div>
                          </li>
                          <li>
                            <div className="column">Database</div>
                            <div className="column no-wrap">{(data.content as any).requestorcodename}</div>
                          </li>
                          <li>
                            <div className="column">Bruker-ID</div>
                            <div className="column no-wrap">{(data.content as any).userid}</div>
                          </li>
                          <li>
                            <div className="column">Subscription-ID</div>
                            <div className="column no-wrap">{(data.content as any).subscriptionid}</div>
                          </li>
                        </ul>
                      </div>
                    )}
                    {data.id === 'log' && (
                      <div className="log-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen logg</div>
                        ) : (
                          <div>
                            <ul className="table-list home-table-list -break-to-single-column-when-md-p -log-table">
                              <li className="table-header">
                                <div className="column date">Dato</div>
                                <div className="column status">Status</div>
                                <div className="column comment">Kommentar</div>
                                <div className="column payex-error-code no-wrap">Feilkode</div>
                                <div className="column payex-description no-wrap">Beskrivelse</div>
                                <div className="column payex-transaction-status no-wrap -last">Bruker</div>
                              </li>
                              {data.content.map((src, j) =>
                                show[data.id] > j ? (
                                  <li key={'log-' + j} className={j % 2 ? 'odd-row' : 'event-row'}>
                                    <div className="column date no-wrap" aria-label="Dato">
                                      {convertToHumanDate(src._source.date)}
                                    </div>
                                    <div className="column status no-wrap" aria-label="Status">
                                      {src._source.orderstatus}
                                    </div>
                                    <div className="column comment -full" aria-label="Kommentar">
                                      {src._source.description}
                                    </div>
                                    <div className="column payex-error-code" aria-label="Feilkode">
                                      {src._source.payexerrorcode}
                                    </div>
                                    <div className="column payex-description" aria-label="Beskrivelse">
                                      {src._source.payexdescription}
                                    </div>
                                    <div className="column payex-transaction-status -last" aria-label="Bruker">
                                      {src._source.username}
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>
                            {show[data.id] < data.content.length && (
                              <div className="show-more-wrapper">
                                <button
                                  className="secondary-btn show-more-btn"
                                  onClick={e => {
                                    this._click(e, 'show-more', data.id);
                                  }}
                                >
                                  Vis mer
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {data.id === 'download' && (
                      <div className="download-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen nedlastingslogg</div>
                        ) : (
                          <div>
                            <ul className="table-list home-table-list -break-to-single-column-when-md-p">
                              <li className="table-header">
                                <div className="column date">Dato</div>
                                <div className="column status">Status</div>
                              </li>
                              {data.content.map((src, j) =>
                                show[data.id] > j ? (
                                  <li key={'log-' + j} className={j % 2 ? 'odd-row' : 'event-row'}>
                                    <div className="column date no-wrap" aria-label="Dato">
                                      {convertToHumanDate(src._source.date)}
                                    </div>
                                    <div className="column status -full" aria-label="Status">
                                      {src._source.nodorderstatus}
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>

                            {show[data.id] < data.content.length && (
                              <div className="show-more-wrapper">
                                <button
                                  className="secondary-btn show-more-btn"
                                  onClick={e => {
                                    this._click(e, 'show-more', data.id);
                                  }}
                                >
                                  Vis mer
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {data.id === 'order' && (
                      <div className="order-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen ordre</div>
                        ) : (
                          <HomeTableList
                            sorted=""
                            list={data.content}
                            callback={(arg1, arg2, arg3) => {
                              if (arg2 === 'show-more') {
                                this._click(arg2, arg2, arg3)
                              } else {
                                this.props.callback(arg1, arg2, arg3);
                              }
                            }
                            }
                            parent={parent}
                            showMore={show[data.id]}
                            productId={productId}
                          />
                        )}
                      </div>
                    )}
                    {data.id === 'citybike' && (
                      <div className="order-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen bysykkelordre</div>
                        ) : (
                          <div>
                            <ul className="table-list home-table-list -break-to-single-column-when-md-p">
                              <li className="table-header">
                                <div className="column order-id">Billettnummer</div>
                                <div className="column bike-id no-wrap">Sykkel-ID</div>
                                <div className="column price">Beløp</div>
                                <div className="column price">Refundert</div>
                                <div className="column price">Gjenstående</div>
                                <div className="column description">Beskrivelse</div>
                                <div className="column date">Turstart</div>
                                <div className="column date">Turslutt</div>
                                <div className="column date">Betalingsstatus</div>
                                <div className="column refund">Refunder</div>
                                <div className="column refunded-by">Refundert av</div>
                                <div className="column refund-comment">Merknad</div>
                              </li>
                              {data.content.map((src, j) =>
                                show[data.id] > j ? (
                                  <li key={'log-' + j}>
                                    <div className="column order-id no-wrap" aria-label="OrdreId">
                                      {src._source.OrderId}
                                    </div>
                                    <div className="column bike-id" aria-label="SykkelId">
                                      {src._source.BikeId}
                                    </div>
                                    <div className="column price" aria-label="Beløp">
                                      {src._source.Amount}
                                    </div>
                                    <div className="column price" aria-label="Beløp">
                                      {src._source.RemainingAmount ? (Number(src._source.Amount) - Number(src._source.RemainingAmount)).toFixed(2) : '-'}
                                    </div>
                                    <div className="column price" aria-label="Beløp">
                                      {src._source.RemainingAmount ? src._source.RemainingAmount : '-'}
                                    </div>
                                    <div className="column description" aria-label="Beskrivelse">
                                      {src._source.Description}
                                    </div>
                                    <div className="column date no-wrap" aria-label="Turstart">
                                      {convertToHumanDate(src._source.TripStart)}
                                    </div>
                                    <div className="column date no-wrap" aria-label="Turslutt">
                                      {convertToHumanDate(src._source.TripEnd)}
                                    </div>
                                    <div className="column date no-wrap" aria-label="Betalingsstatus">
                                      {src._source.OrderStatus}
                                    </div>
                                    <div className="column refund" aria-label="Refunder">
                                      {src._source.OrderStatus === 'FINISH' && data.order.platform_version !== 'v2' && (
                                        <button
                                          title="Refunder penger"
                                          className="icon-btn -refund -blue"
                                          onClick={e => {
                                            this.props.callback(e, 'citybikerefund', src);
                                          }}
                                        >
                                          <span className="aria-visible">Refunder ordre</span>
                                        </button>
                                      )}                                      
                                      {src._source.OrderStatus === 'FINISH' && src._source.app_platform_version === 'v2' && (
                                        <a href={`https://ksp${envSuffix}.${domain}/V2/order/citybike/` + src._source.OrderId} className="icon-btn -refund -blue"></a>
                                      )}
                                    </div>
                                    <div className="column refunded-by" aria-label="Refundert av">
                                      {src._source.creditedby}
                                    </div>
                                    <div className="column refund-comment" aria-label="Merknad">
                                      {src._source.comment}
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>
                            {show[data.id] < data.content.length && (
                              <div className="show-more-wrapper">
                                <button
                                  className="secondary-btn show-more-btn"
                                  onClick={e => {
                                    this._click(e, 'show-more', data.id);
                                  }}
                                >
                                  Vis mer
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {data.id === "blacklist" && (
                      <BlacklistLogComponent logEntries={data.content} />
                    )}
                    {data.id === 'appinstancelog' && (
                      <div className="appinstancelog-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen log</div>
                        ) : (
                          <div>
                            <ul className="table-list home-table-list -break-to-single-column-when-md-p">
                              <li className="table-header">
                                <div className="column date">Dato</div>
                                <div className="column status">Status</div>
                              </li>
                              {data.content.map((src, j) =>
                                show[data.id] > j ? (
                                  <li key={'log-' + j}>
                                    <div className="column date no-wrap" aria-label="Dato">
                                      {convertToHumanDate(src._source.datelog)}
                                    </div>
                                    <div className="column status -full" aria-label="Status">
                                      {src._source.status}
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>
                            {show[data.id] < data.content.length && (
                              <div className="show-more-wrapper">
                                <button
                                  className="secondary-btn show-more-btn"
                                  onClick={e => {
                                    this._click(e, 'show-more', data.id);
                                  }}
                                >
                                  Vis mer
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {data.id === 'agreement' && (
                      <div className="agreement-wrapper">
                        {data.content.length === 0 ? (
                          <div className="empty-text">Ingen betalingsavtale</div>
                        ) : (
                          <div>
                            <ul className="table-list home-table-list -break-to-single-column-when-md-p">
                              <li className="table-header">
                                <div className="column payment">Betalingsmåte</div>
                                <div className="column date">Opprettet</div>
                                <div className="column date">Utløp</div>
                                <div className="column" />
                              </li>
                              {data.content.map((src, j) =>
                                show[data.id] > j ? (
                                  <li key={'log-' + j}>
                                    <div className="column payment" aria-label="Betaling">
                                      <span
                                        title={src._source.name || src._source.cardtype}
                                        className={'icon-btn -' + (src._source.cardtype || 'none').toLowerCase()}
                                      >
                                        <span className="aria-visible">{src._source.cardtype}</span>
                                      </span>
                                    </div>
                                    <div className="column date no-wrap" aria-label="Opprettet">
                                      {convertToHumanDate(src._source.datecreated)}
                                    </div>
                                    <div className="column date no-wrap" aria-label="Utløp">
                                      {convertToHumanDate(src._source.expirationdate)}
                                    </div>
                                    <div className="column -full" aria-label="Handling">
                                      {src._source.agreementid && hasWriteAccess ? (
                                        <button
                                          title="Slett betalingsmåte"
                                          className="secondary-btn"
                                          onClick={e => {
                                            this.props.callback(
                                              e,
                                              'deletepayment',
                                              (src as unknown) as SearchResult<OrderSource>
                                            ); //NB, dette er et hack, for å slippe å skrive om hele editerings-boksen.
                                          }}
                                        >
                                          <span aria-hidden="true">Slett</span>
                                          <span className="aria-visible">
                                            Slett betalingsmåte {src._source.cardtype}
                                          </span>
                                        </button>
                                      ) : null}
                                    </div>
                                  </li>
                                ) : null
                              )}
                            </ul>
                            {show[data.id] < data.content.length && (
                              <div className="show-more-wrapper">
                                <button
                                  className="secondary-btn show-more-btn"
                                  onClick={e => {
                                    this._click(e, 'show-more', data.id);
                                  }}
                                >
                                  Vis mer
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  private isShowMore = (key: string, data: keyof ShowState | 'info'): data is keyof ShowState => key === 'show-more';

  _click = (e: SyntheticEvent, key: 'tab' | 'show-more', data: keyof ShowState | 'info') => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (key === 'tab') {
      if (data !== this.state.active) {
        this.setState({ active: data });
      }
    } else if (this.isShowMore(key, data)) {
      const show = this.state.show;
      show[data] = (show[data] || 0) + (this.state.increasingShowMore || 20);
      const scrolled = getDocumentScrollPosition();
      this.setState({ show: show });
      scrollBodyTop(scrolled[1]);
      setTimeout(() => {
        scrollBodyTop(scrolled[1]);
      }, 20);
    }
  };
}
