import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { AccessToken } from '../accessToken/actionTypes';
import authConfig from '../authConfig';
import { doesUserHaveCityBikeRight, getEnvName, hasWriteAccess } from '../common/General';
import { Company } from '../constants';
import { RootState } from './interfaces';
import { MenuItem } from './MenuItem';

const name = 'Kundesenterportal';

const doesUserHaveSendTicketRight = (accessToken: AccessToken) => {
  return hasWriteAccess(accessToken);
}

const getLogoClass = (companyId: number) => {
  let logoSuffix = 'kolumbus';

  switch (companyId) {
    case Company.AKT:
      logoSuffix = 'akt';
      break;
    case Company.Brakar:
      logoSuffix = 'brakar';
      break;
    case Company.Ostfold:
      logoSuffix = 'ostfold';
      break;
    default:
      logoSuffix = 'kolumbus';
      break;
  }

  return `logo-link -${logoSuffix}`;
}

const Header: React.SFC = () => {
  const accessToken = useSelector((state: RootState) => state.accessToken);

  const hasSendTickets = doesUserHaveSendTicketRight(accessToken);
  const hasCityBike = doesUserHaveCityBikeRight(accessToken);
  const { authService } = useOktaAuth();

  const logout = async () => {
    const idToken = await authService.getIdToken();

    // Clear local session
    await authService.logout();
    const redirectUri = window.location.origin;

    // Clear remote session
    if (idToken) {
      window.location.href = `${authConfig.issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
    }
  }

  const getMenuList = (): MenuItem[] => {
    const defaultMenuItems = [
      { id: 'home', name: 'Forside', url: '/home', reg: /\/home/i },
      { id: 'export', name: 'Eksport', url: '/export', reg: /\/export/i },
      { id: 'hjelp', name: 'Feilhjelp', url: '/help', reg: /\/help/i },
      { id: 'info', name: 'Informasjon', url: '/info', reg: /\/info/i }
    ];

    if (hasSendTickets) {
      defaultMenuItems.push({
        id: 'ticketCounter',
        name: 'Billettluke',
        url: '/billettluke',
        reg: /\/billettluke/i
      });
    }
    if (hasCityBike) {
      defaultMenuItems.push({
        id: 'CityBike',
        name: 'Bysykkel',
        url: '/citybike',
        reg: /\/citybike/i
      });
    }

    return defaultMenuItems;
  }



  const env = getEnvName();
  const menuList = getMenuList();

  return (
    <div className="main-header">
      <div className="account-info-wrapper">
        <div className="cnt layout-outer" title={'Innlogget: ' + (accessToken.FullName || accessToken.sub)}>
          {(accessToken.FullName || accessToken.sub).replace(/@.*/, '')}
        </div>
      </div>
      <div className="layout-outer">
        <nav className="navigation-menu" role="navigation">
          <div className="navigation-header">
            <Link className={getLogoClass(accessToken.CompanyId)} to={{ pathname: '/home' }}>
              {env !== 'prod' && (
                <div className="corner-ribbon top-right sticky red">{(env || 'dev').toUpperCase()}</div>
              )}

              <h1>
                <span>{name}</span>
              </h1>
            </Link>
            <button
              className="menu-btn account-btn -logout no-wrap"
              onClick={logout}
            >
              <span>Logg ut</span>
            </button>
          </div>
          <div className="navigation-content">
            <ul className="menu-list">
              {menuList.map(menu => {
                const type = 'menu-btn -' + menu.id;
                return (
                  <li key={menu.id}>
                    <NavLink
                      className={type}
                      to={{
                        pathname: menu.url
                      }}
                      activeClassName="-active"
                    >
                      <div>{menu.name}</div>
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header;
