import { useOktaAuth } from '@okta/okta-react';
import { Container, Icon, ProgressRadial } from '@ruter-ds/rds-components';
import { CellPhoneArrowRightIcon, CrossIcon } from "@ruter-ds/rds-icons";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MediaType, Order, OrderAndTicket, ProductTemplate, SearchResult, TicketApiClient } from 'ruter-billettluke';
import PhoneColumn from '../../common/table/PhoneColumn/PhoneColumn';
import PriceColumn from '../../common/table/PriceColumn/PriceColumn';
import StatusColumn from '../../common/table/StatusColumn/StatusColumn';
import YesNoColumn from '../../common/table/YesNoColumn/YesNoColumn';
import TicketZonesComponent from '../../common/text/TicketZones';
import './Order.scss';

interface RouteParams {
  id: string;
}

const getMediaType = (mediaType: MediaType) => {
  if (mediaType === "TRAVELCARD") {
    return "Reisekort";
  }
  if (mediaType === "MOBILETICKET") {
    return "Mobilbillett";
  }
  return "Ukjent billettbærer";
}

const formatDate = (date: Date) => {
  return moment(date).format("DD.MM.YYYY HH:mm:ss");
}

const renderDetailItem = (label: string, value: string) => {
  return (
    <>
      <dd>{label}:</dd>
      <dt>{value}</dt>
    </>
  );
}

const OrderComponent: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { authService } = useOktaAuth();
  const [order, setOrder] = useState<Order | null>(null);
  const [tickets, setTickets] = useState<SearchResult<OrderAndTicket> | null>(null);
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | null>(null);
  const [resendTicketId, setResendTicketId] = useState(undefined);
  const [cancelTicketId, setCancelTicketId] = useState(undefined);

  const getNrOfZonesGetAll = (productTemplateId: number) => {
    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (!productTemplate) {
      return 99;
    }

    return productTemplate.nrOfZonesGetAll;
  }

  const getProduct = (productTemplateId: number) => {
    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (!productTemplate) {
      return '';
    }
    return productTemplate.name.nb;
  }

  const getZones = (order: Order) => {
    const nrOfZonesGetAll = getNrOfZonesGetAll(order.productTemplateId);

    const input = {
      zoneType: order.zoneType,
      nrOfZones: order.nrOfZones,
      zones: order.zones,
      nrOfZonesGetAll: nrOfZonesGetAll,
    }
    return TicketZonesComponent(input);
  }

  const resendCode = (event, ticketId) => {
    event.stopPropagation();
    setResendTicketId(ticketId);

    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    ticketApi.tickets.resendPickupCode(ticketId).then(() => {
      setResendTicketId(undefined);
    })
  }

  const cancelTicket = (event, ticketId) => {
    event.stopPropagation();
    setCancelTicketId(ticketId);

    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    ticketApi.tickets.cancelPending(ticketId).then(() => {
      const orderAndTicket = tickets.matches.find(x => x.ticket.id === ticketId);
      orderAndTicket.ticket.status = 'cancelled';
      setTickets(tickets);
      setCancelTicketId(undefined);
    })
  }

  const renderRow = (ticketAndOrder: OrderAndTicket) => {
    return (
      <tr key={ticketAndOrder.ticket.id}>
        <td>{ticketAndOrder.ticket.id}</td>
        <PriceColumn price={ticketAndOrder.ticket.productPrice} />
        <td>{ticketAndOrder.ticket.pickupCode}</td>
        <PhoneColumn phone={ticketAndOrder.ticket.phone} phoneCountryCode={ticketAndOrder.ticket.phoneCountryCode} />
        <YesNoColumn yes={ticketAndOrder.ticket.downloadedToPhone} />
        <StatusColumn status={ticketAndOrder.ticket.status} />
        <td className="actions">
          {ticketAndOrder.ticket.status === 'pending' && (
            <div>
              <button
                className="resend"
                data-test-id="resend-button"
                title="Send hentekode"
                type="button"
                onClick={(event) => resendCode(event, ticketAndOrder.ticket.id)}
              >
                {(resendTicketId && resendTicketId === ticketAndOrder.ticket.id) && (
                  <ProgressRadial isIndeterminate size="small" />
                )}
                {!(resendTicketId && resendTicketId === ticketAndOrder.ticket.id) && (
                  <Icon component={<CellPhoneArrowRightIcon />} size="small" />
                )}

              </button>
              <button
                className="cancel"
                data-test-id="cancel-ticket-button"
                title="Kanseller billett"
                type="button"
                onClick={(event) => cancelTicket(event, ticketAndOrder.ticket.id)}
              >
                {(cancelTicketId && cancelTicketId === ticketAndOrder.ticket.id) && (
                  <ProgressRadial isIndeterminate size="small" />
                )}
                {!(cancelTicketId && cancelTicketId === ticketAndOrder.ticket.id) && (
                  <Icon component={<CrossIcon />} size="small" />
                )}

              </button>
            </div>
          )}
        </td>
      </tr>
    )
  }

  useEffect(() => {
    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    Promise.all([ticketApi.orders.getById(id), ticketApi.tickets.getByOrderId(id, "", 1, 10000), ticketApi.productTemplates.getAll()]).then((results) => {
      const [orderResult, ticketsResult, productTemplatesResult] = results;

      setOrder(orderResult);
      setTickets(ticketsResult);
      setProductTemplates(productTemplatesResult)
    });
  }, [authService, id])

  if (!tickets || !order || !productTemplates) {
    return null;
  }

  return (
    <Container width="l" className="order-page">
      <h1>Ordre</h1>
      <dl>
        {renderDetailItem("Bestilt av", order.orderedBy)}
        {renderDetailItem("Dato", formatDate(order.purchaseDate))}
        {renderDetailItem("Billettbærer", getMediaType(order.mediaType))}
        {renderDetailItem("Produkt", getProduct(order.productTemplateId))}
        {renderDetailItem("Soner", getZones(order))}
        {getZones(order) !== "Alle soner" && renderDetailItem("Antall soner", order.nrOfZones.toString())}
        {renderDetailItem("Antall", `${order.ticketCount}/${order.requestedTicketCount}`)}
        {renderDetailItem("Fakturaref", order.invoiceReference)}
        {renderDetailItem("Kommentar", order.personalReference)}
      </dl>

      <h2>Billetter</h2>
      <table>
        <thead>
          <tr>
            <th>Billettnr.</th>
            <th>Pris</th>
            <th>Hentekode</th>
            <th>Telefon</th>
            <th>Hentet</th>
            <th>Status</th>
            <th>Handlinger</th>
          </tr>
        </thead>
        <tbody>
          {tickets.matches.map(renderRow)}
        </tbody>
      </table>
    </Container>
  );
}

export default OrderComponent