import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AjaxErrorObject, RootState, Search } from './app/interfaces';
import {
  searchFailed,
  searchLoading,
  searchSuccess
} from './oos-action-creators';
import oosAjax, { CityBikeData, PostSapiData, SearchData } from './oosAjax';

/******************************************************************************
 ******************************************************************************/
type Data<T, K extends PropertyKey> = T extends Record<K, (data: infer D) => void> ? D : never;
type Actions = 'search' | 'getOrderById' | 'getAppById' | 'getStatistic' | 'getAppinstance';
export const search = <A extends Actions>(
  data: Data<typeof oosAjax, A>,
  callback?: (response: any) => void,
  action?: A
) => (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
  if (typeof callback !== 'function' || ('allowLoading' in data && (data as any).allowLoading)) {
    dispatch(searchLoading());
  }
  const key = action || 'search';

  return oosAjax[key](data)
    .then((response: Search) => {
      if (typeof callback === 'function') {
        callback(response);
      } else {
        dispatch(searchSuccess(response));
      }
    })
    .catch((error: AjaxErrorObject) => {
      if (typeof callback === 'function') {
        callback({ error: error });
      } else {
        dispatch(searchFailed(error));
      }
    });
};

export type SapiActions =
  | 'refund'
  | 'moveticket'
  | 'deletepayment'
  | 'sendreceipt'
  | 'deletephone'
  | 'sendpickupcode'
  | 'openreconstruct'
  | 'blockreconstruct'
  | 'cancelorder'
  | 'toggleemail';

export type CityBikeActions = 'citybikeblacklist' | 'citybikeremoveblacklist' | 'citybikerefund';

export const postSAPI = (data: PostSapiData, callback: (response: unknown) => void, action: SapiActions) => (
  dispatch: ThunkDispatch<RootState, void, AnyAction>
) =>
  oosAjax
    .postSAPI(data, action)
    .then(response => {
      if (typeof callback === 'function') {
        callback(response);
      }
    })
    .catch(error => {
      if (typeof callback === 'function') {
        callback({ error: error });
      }
    });

export const cityBikeSearch = (searchData: SearchData) => (dispatch: ThunkDispatch<RootState, void, AnyAction>) => oosAjax.getCityBikeOrders(searchData).then((response) => {
      dispatch(searchSuccess(response));
  })
    .catch((error: AjaxErrorObject) => {
        dispatch(searchFailed(error));
    })

export const cityBikeAction = (
  data: CityBikeData,
  callback: (response: unknown) => void,
  action: CityBikeActions
) => () =>
  oosAjax
    .cityBikeAction(action, data)
    .then(res => callback(res))
    .catch(error => callback({ error: error }));
