import * as Sentry from "@sentry/browser";
import Axios from "axios";
import JwtDecode from "jwt-decode";
import { AccessToken } from "./accessToken/actionTypes";
import { OktaAuthService } from './app/interfaces';

let oktaAuthService: OktaAuthService = null;

async function redirectUserToLogin() {
  await oktaAuthService.login(window.location.href);
}

async function createConfiguration(headers: any) {
  if (!oktaAuthService) {
    throw new Error('ApiClient: oktaAuthService has not been set, you need use the "setAccessToken" before sending api requests.')
  }

  const accessToken = await oktaAuthService.getAccessToken();

  if (!accessToken) {
    await redirectUserToLogin();
  }

  const headersWithAuth = {
    ...headers,
    Authorization: `Bearer ${accessToken}`
  }

  return {
    headers: headersWithAuth
  }
}

// thrown error gets swallowed, perhaps by ReduxThunk
function logError(e) {
  console.error(e);
  Sentry.setExtra('details', e.response);
  Sentry.captureException(new Error(`${e.response.config.method}:${e.response.config.url} [${e.response.status}] ${e.response.statusText}`));
}

function handleError(e) {
  if(e?.response?.status === 401){
    redirectUserToLogin();
  }
  logError(e);
  throw e;
}

class ApiClient {
  // TODO: replace once there are types supplied from @okta/react
  setAuthenticationService(authenticationService: OktaAuthService) {
    oktaAuthService = authenticationService;
  }

  async get(url: string, headers?: any) {
    try {
      const configuration = await createConfiguration(headers);
      return await Axios.get(url, configuration);
    } catch (e) {
      handleError(e);
    }
  }

  async post(url: string, data?: any, headers?: any) {
    try {
      const configuration = await createConfiguration(headers);
      return await Axios.post(url, data, configuration);
    } catch (e) {
      handleError(e);
    }
  }

  async delete(url: string, headers?: any) {
    try {
      const configuration = await createConfiguration(headers);
      return await Axios.delete(url, configuration);
    } catch (e) {
      handleError(e);
    }
  }

  async getDecodedAccessToken() {
    const accessToken = await oktaAuthService.getAccessToken();
    return JwtDecode(accessToken) as AccessToken;
  }
}

export default new ApiClient();