import { Security } from '@okta/okta-react';
import * as Sentry from "@sentry/browser";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import { configureStore } from './app/store';
import authConfig from './authConfig';
import { getEnvName } from './common/General';

Sentry.init({
  dsn: "https://d702528a6c8842ff9904115bfa9783e9@o277218.ingest.sentry.io/5274314",
  environment: getEnvName(),
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Security {...authConfig}>
      <Router>
        <App />
      </Router>
    </Security>
  </Provider>,
  document.getElementById('app'),
)