import React from 'react';
import { Suggestion } from '../common/suggestion/Suggestion';
import { InfoList } from '../static/data/InfoList';
import './Info.scss';

export default class Info extends React.Component {
  constructor(props) {
    super(props);

    const list = InfoList.sort((a, b) => {
      const x = a.name;
      const y = b.name;
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.state = {
      list: list,
      infoList: JSON.parse(JSON.stringify(list))
    };
  }

  render() {
    const { list, infoList } = this.state;

    return (
      <div className="info-page-wrapper">
        <div className="suggestion-search-wrapper">
          <div className="suggestion-search-header">
            <div className="cnt">
              <h2>Portal informasjon</h2>
            </div>
            <div className="cnt">
              <Suggestion
                label="Feilmelding kode søk"
                list={list}
                ref="suggestion"
                placeholder="Søk"
                maxSearch={list.length}
                searchKeys={['name', 'text']}
                callback={this._searchCallback}
              />
            </div>
          </div>
          {infoList.length === 0 ? (
            <div className="empty-text">Ingen treff</div>
          ) : (
            <ul className="info-list">
              {infoList.map(src => (
                <li key={src.id} className={'info-item paper -' + src.id}>
                  <h3 className="name">{src.name}</h3>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: src.text
                    }}
                  ></div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  _searchCallback = (key, data) => {
    if (key !== 'search-suggestion') {
      return;
    }
    const list = JSON.parse(JSON.stringify(this.state.list));
    if (!data.text) {
      this.setState({ infoList: list });
    } else if ((data.matchedList || []).length === 0) {
      this.setState({ infoList: [] });
    } else {
      const reduced = data.matchedList.reduce((prev, src) => {
        prev[src.id] = src;
        return prev;
      }, {});

      const out = list.filter(src => (reduced[src.id] ? true : false));
      this.setState({ infoList: out });
    }
  };
}
