import { accessTokenDecoded } from './accessToken/actions';
import { AccessToken } from './accessToken/actionTypes';
import apiClient from './apiClient';
import { AppSearch, BlackListLog, CitybikeOrderSource, OrderSearch, SearchListResult } from './app/interfaces';
import { getCityBikeIndex } from './common/General';
import { CITYBIKE_URL, Company, SAPI_URL, SEARCH_URL } from './constants';
import { CityBikeActions, SapiActions } from './oos-actions';
import getRequestorCodeFromToken from './requestorCode';
import { ErrorSAPIcodes, ErrorSAPIlist, ErrorSAPImapping } from './static/data/ErrorSAPIlist';

interface StringIndexSignature {
  [index: string]: string;
}

interface ErrorSapiMapping {
  id: string;
  headline: string;
  text: string;
  splited: string[];
  source: typeof ErrorSAPImapping[0];
}

interface RedcedErrorSAPIMapping {
  [index: string]: ErrorSapiMapping[];
}

interface FieldValue {
  field: string;
  value: number | string;
}

export type SearchTypes = 'orders' | 'appinstances' | 'citybike_orders' | 'brakar_citybike_orders';

export interface SearchData {
  pathname?: string;
  type: SearchTypes;
  text: string;
  filters?: FieldValue[];
  from?: number;
  sort?: string;
  size?: number;
  forceUrl?: string;
}

interface GetOrderByIdData {
  id: string;
  pid: string;
}

export interface AppIdData {
  id: string;
  pid: string;
  allowLoading: boolean;
  uid?: string;
  platformVersion?: string;
}

export interface PostSapiData {
  comment: string;
  orderid: string;
  amount?: number;
  appid?: string;
  email?: string;
  agreementid?: string;
  mobileinstanceid?: string;
  instanceid?: string;
  country?: string;
  phone?: string;
}

interface SAPIRequest {
  Description: string;
  Reason: 'reason-not-specified';
  Username: string;
  OrderID?: number;
  IsFeeApplied?: boolean;
  ChargeFee?: number;
  CreditAmount?: number;
  MobileInstanceID?: string;
  Email?: string;
  AgreementID?: string;
  EmailBlockedStatus?: boolean;
  InstanceID?: string;
  BlockReconstructFrom?: boolean;
  MobilePhoneNumberCountryCode?: string;
  MobilePhoneNumber?: string;
}

interface GetStatisticsData {
  category: 'ticket' | 'payment' | 'status';
  filters: any[];
}

interface ReducedFilterList {
  filters: Filterlist[];
}

interface Filterlist {
  gte: unknown;
  lte: unknown;
}

interface GetStatisticsRequest {
  index?: string;
  field?: string;
  filters?: unknown[];
}

class OOSAjax {
  private reducedErrorSAPIlist: StringIndexSignature;
  private reducedErrorSAPIcodes: StringIndexSignature;
  private reducedErrorSAPImapping: RedcedErrorSAPIMapping;

  constructor() {
    this.reducedErrorSAPIlist = ErrorSAPIlist.reduce((prev: StringIndexSignature, data) => {
      prev[data.id + ''] = data.text;
      return prev;
    }, {});

    this.reducedErrorSAPIcodes = ErrorSAPIcodes.reduce((prev: StringIndexSignature, data) => {
      prev[data.id + ''] = data.text;
      return prev;
    }, {});

    this.reducedErrorSAPImapping = ErrorSAPImapping.reduce((prev: RedcedErrorSAPIMapping, data) => {
      const splited = ((data.id || '') + '').split('-');
      const pin = splited[0];
      if (!pin) {
        return prev;
      }

      if (!prev[pin]) {
        prev[pin] = [];
      }
      const headline = this.reducedErrorSAPIcodes[data.headline + ''] || '';
      prev[pin].push({
        id: data.id,
        headline: headline,
        text: this.reducedErrorSAPIcodes[data.text + ''] || headline || '',
        splited: splited,
        source: data
      });
      return prev;
    }, {});
  }

  getDecodedAccessToken = () => (window as any).decodedAccessToken as AccessToken


  search = (data: SearchData) => {
    const param = data || ({} as SearchData);
    const url = SEARCH_URL;
    const request = {
      filters: (param.filters || []).concat([
        {
          field: 'requestorcode',
          value: getRequestorCodeFromToken(),
        }
      ]),
      index: param.type || 'orders',
      from: param.from || 0,
      size: param.size || 10,
      sort: param.sort || 'orderid:desc',
      q: param.text || ''
    };

    if (param.type === 'appinstances') {
      const propsToRemove: (keyof typeof request)[] = ['from', 'size', 'sort'];
      propsToRemove.forEach(key => {
        delete request[key];
      });
      request.filters[request.filters.length - 1].field = 'apptypeid';
    }

    const fullUrl = param.forceUrl || url + (param.pathname || '/query');
    return apiClient.post(fullUrl, request).then(response => response.data);
  };

  getOrderById = (data: GetOrderByIdData): Promise<OrderSearch> => {
    const param = data || ({} as GetOrderByIdData);
    const url = SEARCH_URL;
    const id = param.id || '--';
    const q = 'orderid:' + id;
    const pid = param.pid || '--'; // qp = 'paymenttransactionsid:'+pid;
    const promises = [
      apiClient.get(url + '/get?id=' + id + '&index=orders&size=10000'),
      apiClient.get(url + '/get?id=' + pid + '&index=payments&size=10000'),
      apiClient.post(url + '/query', {
        q: q,
        index: 'orderslog',
        sort: 'date:desc',
        size: '10000'
      }),
      apiClient.post(url + '/query', {
        q: q,
        index: 'ordersdownloadlog',
        sort: 'date:desc',
        size: '10000'
      })
    ];

    return Promise.all(promises).then(result => ({
      order: result[0].data,
      payment: result[1].data,
      log: result[2].data,
      download: result[3].data
    }));
  };

  async getBlackListByEndUserId(endUserId: string, companyId: number) {
    const kameratSuffix = companyId == Company.Brakar ? "-brakar" : "-kolumbus";
    return (await apiClient.get(`${CITYBIKE_URL}/api${kameratSuffix}/blacklistlog/${endUserId}?start=0&size=1000`)).data as unknown as Array<BlackListLog>;
  }

  getAppById = (data: AppIdData): Promise<AppSearch> => {
    const param = data || ({} as AppIdData);
    const url = SEARCH_URL;
    const id = param.id || '--';
    const uid = param.uid || 'xx';
    const qp = 'mobileinstanceid:' + id;

    const promises = [apiClient.get(url + '/get?id=' + id + '&index=appinstances&size=10000')]
    if (data.platformVersion != "v2") {
      promises.push(
        apiClient.post(url + '/query', {
          q: id,
          index: 'orders',
          sort: 'orderid:desc',
          size: '10000'
        }),
        apiClient.post(url + '/query', {
          q: qp,
          index: 'payments',
          sort: 'datepaid:desc',
          size: '10000'
        }),
        apiClient.post(url + '/query', {
          q: uid,
          index: 'paymentagreements',
          sort: 'datecreated:desc',
          size: '10000'
        }),
        apiClient.post(url + '/query', {
          q: 'username:' + id,
          index: 'appinstanceslog',
          sort: 'datelog:desc',
          size: '10000'
        })
      );
    }

    return Promise.all(promises).then(result => {
      const searchResult: AppSearch = {
        appinstance: result[0].data,
        order: result.length > 1 ? result[1].data : null,
        payment: result.length > 2 ? result[2].data : null,
        agreement: result.length > 3 ? result[3].data : null,
        log: result.length > 4 ? result[4].data : null
      };

      const endUserId = searchResult.appinstance._source.citybike_enduserid;
      const accessToken = this.getDecodedAccessToken();
      if (endUserId && (accessToken.CompanyId == 500 || accessToken.CompanyId == 102) ) {
        const data: SearchData = {
          text: 'EndUserId:' + endUserId,
          type: getCityBikeIndex(accessToken.CompanyId),
          size: 10000,
        };

        const cityBikeQueries = [
          this.getCityBikeOrders(data),
          this.getBlackListByEndUserId(endUserId, accessToken.CompanyId),
        ]

        return Promise.all(cityBikeQueries as any).then((result): AppSearch =>
        ({
          ...searchResult,
          citybikeorders: result[0] as SearchListResult<CitybikeOrderSource>,
          blackListLogs: result[1] as Array<BlackListLog>,
        })) as Promise<AppSearch>
      } else {
        return searchResult;
      }
    });
  };

  getCityBikeOrders = (param: SearchData): Promise<SearchListResult<CitybikeOrderSource>> => {
    const url = SEARCH_URL;
    return apiClient.post(url + (param.pathname || '/query'), {
      index: param.type,
      from: param.from || 0,
      size: param.size || 10,
      sort: param.sort || 'Timestamp:desc',
      q: param.text || ''
    })
      .then(result => result.data);
  };

  getAppinstance = (data: AppIdData) => {
    const param = data || ({} as AppIdData);
    const url = SEARCH_URL;
    const id = param.id || '--';
    return apiClient.get(url + '/get?id=' + id + '&index=appinstances').then(response => response.data);
  };

  postSAPI = (data: PostSapiData, action: SapiActions) => {
    const param = data || ({} as PostSapiData);
    let url = SAPI_URL;
    const sapiError = this.reducedErrorSAPIlist;
    const sapiMapping = this.reducedErrorSAPImapping;
    const request: SAPIRequest = {
      Description: param.comment,
      Reason: 'reason-not-specified',
      Username: this.getDecodedAccessToken().sub
    };

    switch (action) {
      case 'refund':
        request.OrderID = parseInt(param.orderid);
        request.IsFeeApplied = false;
        request.ChargeFee = 0;
        request.CreditAmount = param.amount;
        url += 'CreditOrder';
        break;
      case 'moveticket':
        request.OrderID = parseInt(param.orderid);
        request.IsFeeApplied = false;
        request.MobileInstanceID = param.appid;
        url += 'ReconstructOrder';
        break;
      case 'sendreceipt':
        request.OrderID = parseInt(param.orderid);
        //request.IsFeeApplied = false;
        request.Email = param.email;
        delete request.Reason;
        url += 'SendReceipt';
        break;
      case 'deletepayment':
        request.AgreementID = param.agreementid; //paymentOptionsAgreementId,
        url += 'DeleteAgreement';
        break;
      case 'toggleemail':
        request.MobileInstanceID = param.mobileinstanceid;
        request.Email = param.email;
        request.EmailBlockedStatus = true; //action === 'blockemail';
        url += 'UpdateEmailBlockedStatus';
        break;
      case 'deletephone':
        delete request.Description;
        delete request.Reason;
        request.InstanceID = param.instanceid;
        url += 'DeleteAppPhoneNumber';
        break;
      case 'openreconstruct':
      case 'blockreconstruct':
        request.InstanceID = param.instanceid;
        request.BlockReconstructFrom = action === 'blockreconstruct';
        url += 'BlockReconstructMobileApp';
        break;
      case 'cancelorder':
        request.OrderID = parseInt(param.orderid);
        url += 'CancelOrder';
        break;
      case 'sendpickupcode':
        request.MobilePhoneNumberCountryCode = param.country;
        request.MobilePhoneNumber = param.phone;
        request.OrderID = parseInt(param.orderid);
        url += 'SendPickupCode';
        break;
    }

    return new Promise((resolve, reject) => {
      return apiClient
        .post(url, request)
        .then(() => resolve({ success: 'Success' }))
        .catch(error => {
          const headers = error.headers || {};
          const code = headers.ReturnCode || headers.returncode || '';
          const msg =
            code && sapiError[code]
              ? sapiError[code]
              : code && sapiMapping[code]
                ? sapiMapping[code][0].text
                : (error.data || {}).Message || '';
          return reject({ error: 'Failed', message: msg, code: code });
        });
    });
  };

  getStatistic = (data: GetStatisticsData) => {
    const param = data || ({} as GetStatisticsData);
    const category = param.category || 'ticket';
    const url = SEARCH_URL;
    const request: GetStatisticsRequest = {};

    const oFilter = {
      field: 'requestorcode',
      value: this.getDecodedAccessToken().CompanyId || 500
    };
    const pFilter = {
      field: 'paymentaccountused',
      value: (this.getDecodedAccessToken().CompanyId || 500) === 500 ? 16 : 17
    };

    if (category === 'ticket') {
      request.index = 'orders';
      request.field = 'tickettype.keyword';
    } else if (category === 'payment') {
      request.index = 'payments';
      request.field = 'cardtype.keyword';
    } else if (category === 'status') {
      request.index = 'orders';
      request.field = 'orderstatus.keyword';
    }

    const base = category === 'payment' ? pFilter : oFilter;
    const list = (param.filters || []).reduce((prev: ReducedFilterList[], filter) => {
      request.filters = [filter, base];
      prev.push(JSON.parse(JSON.stringify(request)));
      return prev;
    }, []);

    if (list.length === 0) {
      request.filters = [base];
      list.push(JSON.parse(JSON.stringify(request)));
    }

    const promises = list.map(r => apiClient.post(url + '/aggs', r));

    return Promise.all(promises).then(result =>
      result.map((d: any, i) => {
        const f = list.length > 1 && list[i] && list[i].filters ? list[i].filters[0] : null;
        const n = f ? [f.gte, f.lte].join('-') : '';
        return { data: d.data, name: n };
      })
    );
  };

  cityBikeAction = (action: CityBikeActions, data: CityBikeData) => {

    const decodeAccessToken = this.getDecodedAccessToken();
    console.info(data.platformVersion)
    console.info(data.orderid)
    const v2Suffix = (data.platformVersion && data.platformVersion.toLowerCase() === "v2" || data.orderid && data.orderid.indexOf("-") > 0) ? "-v2" : "";
    const kamerat = decodeAccessToken.CompanyId == Company.Brakar ? "-brakar" : "-kolumbus"
    let url = `${CITYBIKE_URL}/api${kamerat}${v2Suffix}`;
    const InstanceId = data.instanceid;

    const cityBikeHeaders = {
      'Content-Type': 'application/json; charset=UTF-8',
      InstanceID: InstanceId,
      OktaID: decodeAccessToken.sub,
    }

    switch (action) {
      case 'citybikeblacklist': {
        url += '/blacklist/' + data.endUserId;

        const formData = {
          reason: data.comment,
          reasonId: data.reasonId
        };
        return apiClient.post(url, formData, cityBikeHeaders).then(() => ({ success: 'Success' }));
      }
      case 'citybikeremoveblacklist': {
        url += '/unblacklist/' + data.endUserId;
        const body = {
          message: data.comment,
        }
        return apiClient.post(url, body, cityBikeHeaders).then(() => ({ success: 'Success' }));
      }
      case 'citybikerefund': {
        const { orderid, comment, referenceId } = data;
        const postData = {
          orderId: orderid,
          amount: data.amount,
          refundedBy: decodeAccessToken.sub,
          orderLineId: referenceId,
          comment,
        }

        const headers = {
          'Content-Type': 'application/json; charset=UTF-8',
          InstanceID: InstanceId,
          OktaID: decodeAccessToken.sub
        }

        url += '/refund';
        console.info(url);
        return apiClient.post(url, postData, headers).then(() => ({ success: 'Success' }));
      }
    }
  };
}

export interface CityBikeData {
  endUserId: string;
  instanceid: string;
  amount: number;
  comment?: string;
  reasonId?: string;
  orderid?: string;
  referenceId?: string;
  platformVersion?: string;
}

const oosAjax = new OOSAjax();

export default oosAjax;
