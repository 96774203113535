import React from 'react';
import { convertToHumanDate } from '../General';
import { AppEditingTypes, AppSource, CitybikeOrderSource, OrderEditingTypes, OrderSource } from '../../app/interfaces';

interface EditingTableListProps {
  type: AppEditingTypes | OrderEditingTypes | 'citybikerefund';
  source: AppSource | OrderSource | CitybikeOrderSource;
}

function isOrder(source: OrderSource | AppSource | CitybikeOrderSource): source is OrderSource {
  return 'type' in source && source?.type === 'orders';
}

function isApp(source: OrderSource | AppSource | CitybikeOrderSource): source is AppSource {
  return 'type' in source && source?.type === 'appinstances';
}

export const getLastUsedDate = (source: AppSource): string => {
  if(source.citybike_enduserid) {
    return convertToHumanDate(source.createddate);
  } 
  return convertToHumanDate(source.lastused);
}

export const getCreatedDate = (source: AppSource): string => {
  if(source.citybike_enduserid) {
    return convertToHumanDate(source.lastused);
  } 
  return convertToHumanDate(source.createddate);
}

export const EditingTableList: React.FunctionComponent<EditingTableListProps> = ({ type, source }) => (
  <div className="edition-table-list information-wrapper">
    <div className="cnt">
      {isOrder(source) ? (
        type === 'deletepayment' ? (
          <ul className="table-list -listing">
            <li>
              <div className="column">Opprettet</div>
              <div className="column">{convertToHumanDate(source.datecreated)}</div>
            </li>
            <li>
              <div className="column">Utløp</div>
              <div className="column">{convertToHumanDate(source.expirationdate)}</div>
            </li>
            <li>
              <div className="column">Betalingsmåte</div>
              <div className="column">
                <span title={source.cardtype} className={'icon-btn -' + (source.cardtype || 'none').toLowerCase()}>
                  <span className="aria-visible">{source.cardtype}</span>
                </span>
              </div>
            </li>
          </ul>
        ) : (
          <ul className="table-list -listing">
            <li>
              <div className="column">Billettnummer</div>
              <div className="column">{source.orderid}</div>
            </li>
            <li>
              <div className="column">App-id</div>
              <div className="column no-wrap">{source.mobileinstanceid}</div>
            </li>
            <li>
              <div className="column">Oppr. beløp</div>
              <div className="column">{source.totalprice},-</div>
            </li>
          </ul>
        )
      ) : isApp(source) ? (
        <ul className="table-list -listing">
          <li>
            <div className="column">App-id</div>
            <div className="column">{source.AppId || source.instancename}</div>
          </li>
          <li>
            <div className="column">App-versjon</div>
            <div className="column">{source.appversion}</div>
          </li>
          <li>
            <div className="column">Plattform</div>
            <div className="column">{source.appplatform}</div>
          </li>
          <li>
            <div className="column">Plattformversjon</div>
            <div className="column">{source.appplatformversion}</div>
          </li>
        </ul>
      ) : (
        <ul className="table-list -listing">
          <li>
            <div className="column">Billettnummer</div>
            <div className="column">{source.OrderId}</div>
          </li>
          <li>
            <div className="column">Beløp</div>
            <div className="column">{source.Amount},-</div>
          </li>
        </ul>
      )}
    </div>
    <div className="cnt">
      {isOrder(source) ? (
        type === 'deletepayment' ? null : (
          <ul className="table-list -listing">
            <li>
              <div className="column">Gyldig fra</div>
              <div className="column no-wrap">{convertToHumanDate(source.startdate)}</div>
            </li>
            <li>
              <div className="column">Gyldig til</div>
              <div className="column no-wrap">{convertToHumanDate(source.expirationdate)}</div>
            </li>
            {type === 'sendpickupcode' && (
              <li>
                <div className="column">Hentekode</div>
                <div className="column no-wrap">{source.prepurchasedpickupcode}</div>
              </li>
            )}
          </ul>
        )
      ) : isApp(source) ? (
        <ul className="table-list -listing">
          <li>
            <div className="column">Opprettet</div>
            <div className="column">{getCreatedDate(source)}</div>
          </li>
          <li>
            <div className="column">Sist brukt</div>
            <div className="column">{getLastUsedDate(source)}</div>
          </li>
          <li>
            <div className="column">Sperret</div>
            <div className="column">{source.appblocked ? 'Ja' : 'Nei'}</div>
          </li>
          <li>
            <div className="column">Tlf.</div>
            <div className="column">{source.mobilephonenumbercountrycode || ''}</div>
          </li>
        </ul>
      ) : null}
    </div>
  </div>
);
