import { Button } from '@ruter-ds/rds-components';
import React, { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/interfaces';
import { getEnvName } from '../common/General';
import { Company } from '../constants';
import './findCityBike.scss';

export const FindCityBike: React.FC = () => {
  const company = useSelector((state: RootState) => state.accessToken.CompanyId);
  const [cityBikeSearchValue, setCityBikeSearchValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    const pattern = new RegExp('[^0-9]');
    if (pattern.test(cityBikeSearchValue)) {
      setErrorMessage('Må være 4 siffer, andre tegn er ikke tillatt.');
    } else {
      setErrorMessage('');
    }
  }, [cityBikeSearchValue]);

  const addLeadingZeros = () => {
    let leadingZeros = '';
    for (let i = cityBikeSearchValue.length; i < 4; i++) {
      leadingZeros += '0';
    }
    setCityBikeSearchValue(leadingZeros + cityBikeSearchValue);
  };

  const openCityBikeView = (e: FormEvent) => {
    addLeadingZeros();
    if (errorMessage.length > 0) {
      e.preventDefault();
    }
  };

  const env = getEnvName();
  let citybikeUrl = env === "prod" ? `https://www.kolumbus.citybike.cloud/bikes/support` : `https://www.kolumbus.betabike.no/bikes/support`

  if(company === Company.Brakar){
    citybikeUrl = 'https://www.brakar.citybike.cloud/bikes/support';
  }

  return (
    <div className="find-city-bike">
      <h1>Bysykkelinformasjon</h1>

      <form
        action={citybikeUrl}
        method="get"
        onSubmit={openCityBikeView}
        target="cityBikeView"
      >
        <label htmlFor="visualId">Søk etter bysykkel-ID</label>
        <div className="find-bike-wrapper">
          <input
            type="text"
            onBlur={addLeadingZeros}
            inputMode="numeric"
            size={4}
            maxLength={4}
            value={cityBikeSearchValue}
            onChange={e => setCityBikeSearchValue(e.target.value)}
            autoComplete="off"
            autoCorrect="off"
            className="textfield"
            id="visualId"
            name="visualId"
            placeholder="ID"
          />
          <Button type="submit" text="Finn sykkel" skin="info" />
        </div>
        <div className="input-error-message" role="alert">
          {errorMessage}
        </div>
      </form>
    </div>
  );
};
