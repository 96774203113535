import React from 'react';
import { TicketStatus } from 'ruter-billettluke';
import getTicketStatusText from '../../text/TicketStatus';
import './StatusColumn.scss';

interface Props {
  status: TicketStatus
}

const StatusColumn: React.FC<Props> = ({status}) => {
  return (
    <td>
      <div className="status-column">
        <div className={`status-icon ${status}`} />
        <div className="status-text">{getTicketStatusText(status)}</div>
      </div>
    </td>
  );
}

export default StatusColumn;
