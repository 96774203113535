export const ACCESS_TOKEN_DECODED = 'ACCESS_TOKEN_DECODED';

export interface AccessToken {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: string;
  uid: string;
  scp: Array<string>;
  sub: string;
  CompanyId: number;
  readonly?: boolean;
  FullName: string;
}

interface AccessTokenDecode {
  type: typeof ACCESS_TOKEN_DECODED;
  payload: AccessToken;
}

export type AcessTokenActionTypes = AccessTokenDecode;