import React from 'react';
import classNames from 'classnames';

//const Textfield = ({input, meta, input: { value, onChange, onBlur },  ...custom}) => {   // eslint-disable-line no-unused-vars
const Textfield = ({ input, meta, ...custom }) => {
  // eslint-disable-line no-unused-vars
  const isInvalid = !!((meta.touched || custom.forceTouched) && (meta.error || meta.warning));
  const value = input.value || '';
  const fieldId = custom.id || 'textfield-' + new Date().getTime() + '-' + Math.floor(Math.random() * 1000 + 1);
  const classes = classNames('input-content -textfield', {
    '-required': custom.required,
    '-invalid': isInvalid,
    '-inline-textfield': custom.inline
  });

  const rest = [
    'placeholder',
    'autoComplete',
    'spellCheck',
    'autoCapitalize',
    'autoCorrect',
    'required',
    'onFocus',
    'onKeyUp',
    'onKeyDown',
    'onBlur',
    'data',
    'disabled',
    'maxLength',
    'minLength'
  ].reduce((prev, key) => {
    if (custom[key]) {
      prev[key] = custom[key];
    }
    return prev;
  }, {});

  return (
    <div className={classes}>
      {custom.label && (
        <label htmlFor={fieldId} className="input-label">
          {custom.label}
        </label>
      )}
      <input
        {...input}
        id={fieldId}
        value={value}
        type={custom.type || 'text'}
        aria-invalid={isInvalid}
        {...rest}
        className={'textfield' + (isInvalid ? ' -invalid' : '') + (custom.small ? ' -small' : '')}
      />
      {isInvalid && (
        <div className="input-error-message" role="alert">
          {meta.error || meta.warning}
        </div>
      )}
    </div>
  );
};

export default Textfield;
