const CLIENT_ID = process.env.CLIENT_ID || '0oapxdasixdMvZjZ90h7';
const ISSUER = process.env.ISSUER || 'https://dev-262210.oktapreview.com/oauth2/default';

export default {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile'],
  disableHttpsCheck: window.origin.indexOf("localhost") !== -1,
  pkce: false,
};