import {
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  SEARCH_LOADING,
  OPEN_EDITING,
  CLOSE_EDITING,
  AllOosActions
} from './oos-action-creators';
import { Oos, Search } from './app/interfaces';

const initialState: Oos = {
  user: null,
  search: {} as Search,
  waitingFor: null,
  error: null,
  zones: [],
  profiles: [],
  fileMeta: {},
  sapi: null,
  type: '',
  editing: null
};

const assign = (state: Oos, changes: Partial<Oos>): Oos => Object.assign({}, state, changes);

export const oosReducer = (state: Oos = initialState, action: AllOosActions): Oos => {
  switch (action.type) {
    case SEARCH_LOADING:
      return assign(state, {
        waitingFor: action.type
      });
    case SEARCH_FAILED:
      return assign(state, {
        error: (action.error || {}).data || {
          code: 0,
          message: 'Connection error.'
        },
        type: action.type,
        waitingFor: null
      });
    case SEARCH_SUCCESS:
      return assign(state, {
        search: action.search,
        waitingFor: null
      });
    case OPEN_EDITING:
      return assign(state, {
        editing: action.data
      });
    case CLOSE_EDITING:
      return assign(state, {
        editing: null
      });
    default:
      return state;
  }
};
