export const ProductIdBrakar = {
  '16001': 'Brakar 30 dager Voksen',
  '16002': 'BrakarS 30 dager Voksen',
  '16003': 'Brakar 7 dager Voksen',
  '16004': 'Student Buskerud 7 dager Student',
  '16005': 'Ruter 30 dager Voksen',
  '16006': 'Ruter 7 dager Voksen',
  '16007': 'Brakar Ungdom Ungdom',
  '16008': 'Brakar 30 dager Barn',
  '16009': 'BrakarS 7 dager Voksen',
  '16011': 'Student Buskerud 30 dager Student',
  '16012': 'BrakarS 30 dager Student',
  '16014': 'BrakarS 7 dager Student',
  '16015': 'Ruter 30 dager Barn',
  '16016': 'Ruter 7 dager Barn',
  '16017': 'Ruter 30 dager Honnør',
  '16018': 'Ruter 7 dager Honnør',
  '16031': 'Brakar 24-timer Voksen',
  '16032': 'Brakar 24-timer Barn',
  '16033': 'Brakar 24-timer Honnør',
  '16037': 'Brakar 24-timer Sykkel',
  '16039': 'Brakar 365 dager Barn',
  '16045': 'Brakar 365 dager Honnør',
  '16051': 'Enkeltbillett Buskerud Voksen',
  '16052': 'Enkeltbillett Buskerud Barn',
  '16053': 'Enkeltbillett Buskerud Honnør',
  '16055': 'Enkeltbillett Buskerud Hund',
  '16056': 'Enkeltbillett Buskerud Sykkel',
  '16061': 'Enkeltbillett B200 Voksen',
  '16062': 'Enkeltbillett B200 Barn',
  '16063': 'Enkeltbillett B200 Honnør',
  '16065': 'Enkeltbillett B200 Hund',
  '16066': 'Enkeltbillett B200 Sykkel',
  '16071': 'Enkeltbillett Ruter Voksen',
  '16072': 'Enkeltbillett Ruter Barn',
  '16073': 'Enkeltbillett Ruter Honnør',
  '16076': 'Enkeltbillett Ruter Sykkel',
  '16081': 'Enkeltbillett Ruter på B200 Voksen',
  '16082': 'Enkeltbillett Ruter på B200 Barn',
  '16083': 'Enkeltbillett Ruter på B200 Honnør',
  '16086': 'Enkeltbillett Ruter på B200 Sykkel',
  '16090': 'Ansattbillett',
  '16091': 'Ansattbillett',
  '16092': 'Ansattbillett',
  '16093': 'Ansattbillett',
  '16116': 'Brakar BUSS-TOG 30 dager Voksen',
  '16117': 'Brakar BUSS-TOG 30 dager Student',
  '16118': 'Brakar BUSS-TOG 7 dager Voksen',
  '16120': 'Brakar200 30 dager Voksen',
  '16122': 'Brakar200 7 dager Voksen',
  '16123': 'Brakar200 30 dager Ungdom',
  '16124': 'Brakar200 Student 30 dager Student',
  '16125': 'Brakar200 7 dager Ungdom',
  '16126': 'Brakar200 Student 7 dager Student',
  '16127': 'Brakar200s 30 dager Barn',
  '16128': 'Brakar200s 30 dager Honnør',
  '16129': 'Brakar200s 7 dager Barn',
  '16130': 'Brakar200s 30 dager Voksen',
  '16131': 'Brakar200s 30 dager Ungdom',
  '16132': 'Brakar200s 30 dager Student',
  '16133': 'Brakar200s 365 dager Honnør',
  '16135': 'Brakar200s 7 dager Voksen',
  '16136': 'Brakar200s 7 dager Ungdom',
  '16137': 'Brakar200s 7 dager Student',
  '16141': 'Nattbuss Konnerud Voksen',
  '16142': 'Nattbuss Mjøndalen Voksen',
  '16143': 'Nattbuss Kniveåsen Voksen',
  '16144': 'Nattbuss Lier Voksen',
  '16145': 'Nattbuss Vikersund Voksen',
  '16146': 'Nattbuss Røyken Voksen',
  '16165': 'Bybillett Voksen', 
  '16166': 'Bybillett Barn', 
  '16167': 'Bybillett Honnør', 
  '': ''
};
