import { TicketCategory } from "../../app/interfaces";


export const TicketCategoryList: Array<TicketCategory> = [
  {
    id: 'notexpireddate',
    name: 'Gyldig',
    field: 'expirationdate',
    type: 'category'
  },
  {
    id: 'expireddate',
    name: 'Ugyldig',
    field: 'expirationdate',
    type: 'category'
  },
  {
    id: 'startedticket',
    name: 'Startet',
    field: 'startdate',
    type: 'category'
  },
  {
    id: 'notstartedticket',
    name: 'Ustartet',
    field: 'startdate',
    type: 'category'
  }
];
