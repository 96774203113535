import { useOktaAuth } from '@okta/okta-react';
import { parse } from "query-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TicketApiClient } from "ruter-billettluke";
import { RootState } from "./app/interfaces";


function usePaginatedFetch<T>(entity: string) {
  const [pageSize, setPageSize] = useState(10);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [queryInput, setInputQuery] = useState("");
  const [query, setQuery] = useState("");
  const [error, setError] = useState(false);
  const [result, setResult] = useState<T | null>(null);
  const location = useLocation();
  const history = useHistory();
  const { authService } = useOktaAuth();
  const companyId = useSelector((state: RootState) => state.accessToken.CompanyId);

  const fetch = () => {
    setShouldUpdate(true);
  };

  // fetch page and q from location, or set defaults
  useEffect(() => {
    if (!initialized) {
      const { q: queryFromUrl, page: pageFromUrl, pagesize: pageSizeFromUrl } = parse(location.search);

      let page = 0;
      let query = "";
      let pageSize = 10;

      if (queryFromUrl && typeof queryFromUrl === "string") {
        query = queryFromUrl;
      }
      if (pageFromUrl && typeof pageFromUrl === "string") {
        const pageNumber = Number(pageFromUrl);
        if (pageNumber >= 0) {
          page = pageNumber;
        }
      }

      if (pageSizeFromUrl && typeof pageSizeFromUrl === "string") {
        const pageSizeNumber = Number(pageSizeFromUrl);
        if (pageSizeNumber >= 0) {
          pageSize = pageSizeNumber;
        }
      }

      setPage(page);
      setPageSize(pageSize);
      setInputQuery(query);
      setQuery(query);
      setInitialized(true);
    }
  }, [location.search, initialized]);

  // fetch data
  useEffect(() => {
    if (initialized || shouldUpdate) {
      const newLocation =`${location.pathname}?q=${query}&page=${page}&pagesize=${pageSize}` 
      const oldLocation = `${location.pathname}${location.search}`;

      if(newLocation !== oldLocation) {
        history.replace(newLocation);
        return;
      }

      const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');

      let fetchFunc: any = ticketApi.orders.getByCompanyId;

      if(entity === "tickets"){
        fetchFunc = ticketApi.tickets.getByCompanyId;
      }

      fetchFunc(companyId.toString(), query, page +1, pageSize).then((x) => {
        setResult(x as unknown as T);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
    }
  }, [initialized, page, query, shouldUpdate, history, location.pathname, pageSize, entity, companyId, location.search, authService]);

  const fetchWithQuery = (query: string) => {
    setPage(0);
    setInputQuery(query);
    setQuery(query);
  };

  return { loading, result, page, queryInput, setPage, fetchWithQuery, setInputQuery, fetch, setPageSize, error, pageSize };
}

export default usePaginatedFetch;
