import { useOktaAuth } from '@okta/okta-react';
import { Container } from '@ruter-ds/rds-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { OrderTicketWizard, Tenant } from 'ruter-billettluke';
import { RootState } from '../../app/interfaces';
import { Company } from '../../constants';

const OrderTicket: React.FC = () => {
  const companyId = useSelector((state: RootState) => state.accessToken.CompanyId);
  const { authService } = useOktaAuth();

  const getAgreeementNumber = () => {
    let companyPart = '';
    if (companyId === Company.AKT) {
      companyPart = 'AKT';
    } else if (companyId === Company.Brakar) {
      companyPart = 'BRAKAR';
    } else if (companyId === Company.Kolumbus) {
      companyPart = 'KOLUMBUS';
    } else if (companyId === Company.Ostfold) {
      companyPart = 'OESTFOLD';
    } else {
      throw new Error('invalid companyid or this companyid does not have access to billettluke')
    }

    return `${companyPart}-1-3477400186`;
  }

  return (
    <Container width="xs">
      <OrderTicketWizard
        agreementNumber={getAgreeementNumber()}
        errorLink="https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/"
        header="Send billetter"
        authService={authService}
        tenant={Tenant.Ruter}
        apiBasePath='/proxy/billettluke'
      />
    </Container>
  );
};

export default OrderTicket;