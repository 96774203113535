export const ProductIdAKT = {
  '26001': 'Enkeltbillett voksen',
  '26006': 'Enkeltbillett barn',
  '26011': 'Enkeltbillett honnør',
  '26031': 'Periodebillett 24 timer voksen',
  '26036': 'Periodebillett 24 timer barn',
  '26041': 'Periodebillett 24 timer honnør',
  '26051': 'Periodebillett 24 timer Agder voksen',
  '26053': 'Periodebillett 24 timer Agder barn',
  '26055': 'Periodebillett 24 timer Agder honnør',
  '26061': 'Periodebillett 30 dager voksen',
  '26066': 'Periodebillett 30 dager Kristiansandområdet voksen',
  '26067': 'Periodebillett 30 dager Kristiansandområdet barn',
  '26068': 'Periodebillett 30 dager Kristiansandområdet honnør',
  '26069': 'Periodebillett 30 dager Kristiansandområdet ungdom',
  '26070': 'Periodebillett 30 dager Kristiansandområdet ung voksen',
  '26071': 'Periodebillett 30 dager Agder voksen',
  '26076': 'Periodebillett 30 dager Agder barn',
  '26081': 'Periodebillett 30 dager Agder honnør',
  '26086': 'Periodebillett 30 dager Agder ungdom',
  '26091': 'Periodebillett 30 dager Agder ung voksen',
  '26101': 'Nattbuss Kristiansandregionen voksen',
  '26111': 'Nattbuss Aust-Agder voksen',
  '26121': 'Periodebillett 7 dager voksen',
  '26126': 'Periodebillett 7 dager barn',
  '26131': 'Periodebillett 7 dager honnør',
  '26151': 'Periodebillett 7 dager Agder voksen',
  '26156': 'Periodebillett 7 dager Agder barn',
  '26161': 'Periodebillett 7 dager Agder honnør',
  '': ''
};



