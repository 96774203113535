import React from 'react';
import { convertToHumanDate } from '../common/General';

interface Window {
  oosVersion: Version;
}

interface Version {
  shortHash: string;
  committedOn: number;
}

const Footer: React.SFC = () => {
  const version = (window as unknown as Window).oosVersion;

  return (
    <div className="main-footer">
      <div className="layout-outer">
        {version
          ? (<div className="main-version-information">
            <ul>
              <li>
                <span className="as-label">short-hash</span>
                <span className="as-value">{version.shortHash}</span>
              </li>
              <li>
                <span className="as-label">Dato</span>
                <span className="as-value">{convertToHumanDate(new Date(version.committedOn * 1000)) }</span>
              </li>
            </ul>
          </div>)
          : null}
      </div>
    </div>
  )
}

export default Footer;