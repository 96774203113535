import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/interfaces';
import { getCityBikeIndex } from '../common/General';
import { Loading } from '../common/loading/Loading';
import Pagination from '../common/Pagination/Pagination';
import { Company } from '../constants';
import { SearchData } from '../oosAjax';
import { CityBikeProps } from './cityBike';
import './cityBikeOrders.scss';
import { CityBikeOrdersTable } from './cityBikeOrdersTable';


const getDefaultSearchDate = (companyId: Company): SearchData => ({
    type: getCityBikeIndex(companyId),
    sort: 'Timestamp:desc',
    size: 15,
    from: 0,
    text: ''
})



export const CityBikeOrders: React.FC<CityBikeProps> = ({ actions, cityBikeOrderHits, loading }) => {
  const company = useSelector((state: RootState) => state.accessToken.CompanyId )
  const defaultSearchData = getDefaultSearchDate(company);


  const [searchValue, setSearchValue] = React.useState('');
  
  const [searchData, setSearchData] = React.useState<SearchData>(defaultSearchData);
  React.useEffect(() => {
    actions.cityBikeSearch(searchData);
  }, [searchData, actions]);

  const list = cityBikeOrderHits?.hits || [];
  const total = cityBikeOrderHits?.total || 0;

  const pageSize = searchData.size || 15;
  const searchFrom = searchData.from || 0;
  const activePage = Math.floor(searchFrom / pageSize) + 1
  const totalNumberOfPages = Math.ceil(total / pageSize)

  const changePage = (pageNumber: number) => {
    setSearchData({
      ...searchData,
      from: (pageNumber - 1) * pageSize,
    });
  }

  const submitSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchValue.trim().length) {
      setSearchData({ ...defaultSearchData, text: 'OrderId:' + searchValue });
    } else {
      setSearchData({ ...defaultSearchData });
    }
  };

  const resetSearch = () => {
    setSearchValue('');
    setSearchData({ ...defaultSearchData });
  };

  return (
    <div>
      <h1>Bysykkelordre</h1>
      <form name="search" noValidate onSubmit={submitSearch}>
        <div className="search-wrapper">
          <input
            type="text"
            className="textfield"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
            placeholder="Søk etter Billettnummer"
          />
          <div className="submit-wrapper">
            <div className="reset-button">
              <button type="button" className="icon-btn -cross" title="Nullstille søket" onClick={resetSearch}>
                <span className="aria-visible">Nullstille søket</span>
              </button>
            </div>
            <button type="submit" className="secondary-btn -blue -fill">
              Søk
            </button>
          </div>
        </div>
      </form>
      {loading ? (
        <Loading />
      ) : list.length === 0 ? (
        <div className="home-content">
          <div className="empty-text">Ingen treff</div>
        </div>
      ) : (
            <div className="home-content">
              <CityBikeOrdersTable orders={list} refundAction={actions.refundDialog} />
              <Pagination onPageChange={changePage} activePage={activePage} totalNumberOfPages={totalNumberOfPages} pageSize={pageSize} />
              <div className="list-information">
                <div className={'shy italic small-text' + (total ? '' : ' hide')}>Total: {total}</div>
                <div className="sort-option-list" />
              </div>
            </div>
          )}
    </div>
  );
};
