import { AccessToken, ACCESS_TOKEN_DECODED, AcessTokenActionTypes } from "./actionTypes";

const initialState = null;

export default function accessTokenReducer(state: AccessToken | null = initialState, action: AcessTokenActionTypes): AccessToken {
  switch (action.type) {
    case ACCESS_TOKEN_DECODED:
      return { ...action.payload }
    default: 
      return state;
  }
}