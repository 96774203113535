import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { oosReducer } from '../oos-reducers';
import accessTokenReducer from '../accessToken/reducer';


export default function createRootReducer() {
  return combineReducers({
    oos: oosReducer,
    form: formReducer,
    accessToken: accessTokenReducer
  });
} 