import PropTypes from 'prop-types';
import React from 'react';

export const SuggestionTagbtn = ({ id, text, callback, title, type }) => {
  let pin = id || 'auto_' + new Date().getTime() + Math.floor(Math.random() * 10000 + 1);
  let tag = 'tag-btn' + (type ? ' ' + type : '');

  return (
    <div className={tag} id={pin}>
      <button
        className="icon-btn -cross tag-closer"
        aria-hidden="true"
        title="Slett"
        onClick={e => {
          callback(e, 'remove-suggestion-tabbtn', id);
        }}
      ></button>
      <div className="tag-cnt" title={title || ''}>
        {text}
      </div>
    </div>
  );
};

SuggestionTagbtn.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};
