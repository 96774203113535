import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { convertToHumanDate, getFormattedPhoneNumber } from '../common/General';

export const ViewOrderTableList = ({ source, productId }) => {
  const countryCode = source.mobilephonenumbercountrycode || '';
  const phoneNumber = source.mobilephonenumber || '';

  const formattedPhone = getFormattedPhoneNumber(`${countryCode}${phoneNumber}`);
  
  return (
    <div className="view-order-content">
      <div className="cnt">
        <ul className="table-list -listing">
          <li>
            <div className="column">Billettype</div>
            <div className="column">
              {(productId || {})[(source.productid || '') + ''] !== '' ? (
                <div title={source.tickettypeid + ' (' + source.tickettype + ')'}>
                  <span>{source.productid} </span>
                  <span className="italic shy">({productId[(source.productid || '') + '']})</span>
                </div>
              ) : (
                  <div>
                    <span>{source.tickettypeid} </span>
                    <span className="italic shy">({source.tickettype})</span>
                  </div>
                )}
            </div>
          </li>
          <li>
            <div className="column">Antall reisende</div>
            <div className="column">
              <span>{source.nrofpassengers} </span>
            </div>
          </li>
          <li>
            <div className="column">Billettkategori</div>
            <div className="column">
              <span>{source.typeid} </span>
              <span className="italic shy">({source.typename})</span>
            </div>
          </li>
          <li>
            <div className="column">Status</div>
            <div className="column">
              <span>{source.orderstatusid} </span>
              <span className="shy italic">({source.orderstatus})</span>
            </div>
          </li>
          <li>
            <div className="column">App-ID</div>
            <div className="column">
              <Link
                className="no-wrap link"
                to={
                  '/viewappinfo?id=' +
                  (source.payermobileinstanceid || source.mobileinstanceid) +
                  '&pid=' +
                  source.paymenttransactionsid
                }
              >
                {source.payermobileinstanceid || source.mobileinstanceid}
              </Link>
              <span className="italic shy">{source.appversion ? ', ' + source.appversion : ''}</span>
            </div>
          </li>
          <li>
            <div className="column">Mottaker App-ID</div>
            <div className="column">
              {source.mobileinstanceid && source.payermobileinstanceid !== source.mobileinstanceid ? (
                <Link
                  className="no-wrap link"
                  to={'/viewappinfo?id=' + source.mobileinstanceid + '&pid=' + source.paymenttransactionsid}
                >
                  {source.mobileinstanceid}
                </Link>
              ) : null}
            </div>
          </li>
          <li>
            <div className="column">Hentekode</div>
            <div className="column">{source.prepurchasedpickupcode}</div>
          </li>
          <li>
            <div className="column">Mobilnummer</div>
            <div className="column">
              {formattedPhone}
            </div>
          </li>
          <li>
            <div className="column">E-post</div>
            <div className="column">{source.email}</div>
          </li>
          <li>
            <div className="column">Original ordre</div>
            <div className="column">
              {source.parentorderid ? (
                <Link
                  className="no-wrap link"
                  to={'/vieworder?id=' + source.parentorderid + '&pid=' + (source.paymenttransactionsid || '')}
                >
                  {source.parentorderid}
                </Link>
              ) : null}
            </div>
          </li>
          <li>
            <div className="column">Resent ordre</div>
            <div className="column">
              {source.resendorderid ? (
                <Link
                  className="no-wrap link"
                  to={'/vieworder?id=' + source.resendorderid + '&pid=' + (source.paymenttransactionsid || '')}
                >
                  {source.resendorderid}
                </Link>
              ) : null}
            </div>
          </li>
        </ul>
      </div>
      <div className="cnt">
        <ul className="table-list -listing">
          <li>
            <div className="column">Bestilt dato</div>
            <div className="column no-wrap">{convertToHumanDate(source.dateordered)}</div>
          </li>
          <li>
            <div className="column">Betalt dato</div>
            <div className="column no-wrap">{convertToHumanDate(source.datepaid)}</div>
          </li>
          <li>
            <div className="column">Eksportert dato</div>
            <div className="column no-wrap">{convertToHumanDate(source.dateexported)}</div>
          </li>
          <li>
            <div className="column">Gyldig fra</div>
            <div className="column no-wrap">{convertToHumanDate(source.startdate)}</div>
          </li>
          <li>
            <div className="column">Gyldig til</div>
            <div className="column no-wrap">{convertToHumanDate(source.expirationdate)}</div>
          </li>
          <li>
            <div className="column">Kansellert dato</div>
            <div className="column no-wrap">{convertToHumanDate(source.datecanceled)}</div>
          </li>
          <li>
            <div className="column">Siste mulige startdato</div>
            <div className="column no-wrap">{convertToHumanDate(source.laststartdate)}</div>
          </li>
        </ul>
      </div>
    </div>
  )
}

ViewOrderTableList.propTypes = {
  //callback: PropTypes.func.isRequired,
  source: PropTypes.object.isRequired
};
