import { string, number, arrayOf } from 'prop-types';

const language = {
  zoneOne: '{zoneFrom}',
  zoneTwo: '{zoneFrom} - {zoneTo}',
  allZones: 'Alle soner',
  unknown: 'Ukjent',
  radiusZones: '{zoneCount} soner fra {zoneFrom}',
};

function validateProps(zoneType, nrOfZones, zones, nrOfZonesGetAll) {
  if (!nrOfZonesGetAll || typeof (nrOfZonesGetAll) !== 'number' || nrOfZonesGetAll < 1 || Math.ceil(nrOfZonesGetAll) !== nrOfZonesGetAll) {
    throw new Error('nrOfZonesGetAll must be a positive integer');
  }
  
  if (zoneType !== 'Radius' && zoneType !== 'ZoneToZone') {
    throw new Error('TicketZonesComponent requires a prop named zoneType to be set to either Radius or ZoneToZone');
  }

  if (!nrOfZones || typeof (nrOfZones) !== 'number' || nrOfZones < 1 || Math.ceil(nrOfZones) !== nrOfZones) {
    throw new Error('nrOfZones must be a positive integer');
  }

  if (!zones || !Array.isArray(zones) || zones.length < 1 || zones.some((x) => typeof (x) !== 'string')) {
    throw new Error('zones must be a nonempty array of strings');
  }
}

export function TicketZonesComponent({
  zoneType,
  nrOfZones,
  zones,
  nrOfZonesGetAll,
}) {
  validateProps(zoneType, nrOfZones, zones, nrOfZonesGetAll);

  if(nrOfZones >= nrOfZonesGetAll) {
    return language.allZones;
  }

  if (zoneType === 'ZoneToZone') {
    if (nrOfZones === 1) {
      return language.zoneOne.replace('{zoneFrom}', zones[0]);
    }
    if (nrOfZones > 1) {
      if (zones.length < 2) {
        return language.unknown;
      }

      const zone1 = zones[0];
      const zone2 = zones[1];
      return language.zoneTwo.replace('{zoneFrom}', zone1).replace('{zoneTo}', zone2);
    }
  }
  if (zoneType === 'Radius') {
    if (nrOfZones === 1) {
      return language.zoneOne.replace('{zoneFrom}', zones[0]);
    }
    return language.radiusZones.replace('{zoneFrom}', zones[0]).replace('{zoneCount}', nrOfZones);
  }
}

TicketZonesComponent.propTypes = {
  zones: arrayOf(string).isRequired,
  nrOfZones: number.isRequired,
  zoneType: string.isRequired,
};

export default TicketZonesComponent;
