import React from 'react';
import './Issue.scss';
import { HelpIssue, HelpIssueList } from '../static/data/HelpIssueList';

const renderIssue = (issue: HelpIssue) => {
  return (
    <div className="issue">
      <h2>{issue.title}</h2>
      <div className="date">{issue.date}</div>

      {issue.text.length > 0 && (
        <>
          <div className="issue-title">SAK</div>
          <div className="issue-content">{issue.text.map((t) => <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>)}</div>
        </>
      )}


      <div className="comment-title">KOMMENTAR / WORKAROUND</div>
      <div className="comment-content">{issue.comment.map((t) => <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>)}</div>
    </div>
  );
}

const Issue: React.FC = () => {
  return (
    <div className="help-issue">
      <h1>Kjente problemstillinger</h1>
      {HelpIssueList.map(renderIssue)}
    </div>
  );
}

export default Issue;