export const InfoList = [
  {
    id: 'refund',
    name: 'Refunder',
    text:
      'Bare ordre som kan refunderes vil ha knapp ‘Refunder’. Det er ferdigutregnet restbeløp i feltet for beløp, dette er beregnet ut fra antall resterende dager, men kan overskrives. Merknad er påkrevd. Billetter blir kansellert fra dagens dato, men det kan gå helt til neste dag før den forsvinner fra kundens app.'
  },
  {
    id: 'view-order-info',
    name: 'Vise Ordre – Info',
    text:
      'Under fanbladet Info på Vis Ordre siden vil det være informasjon som kan brukes til å finne ut mer om ordren.'
  },
  {
    id: 'view-order-log',
    name: 'Vise Ordre – Logg',
    text:
      'Under fanbladet Logg på Vis Ordre siden vil det være informasjon som kan brukes til å finne ut mer om hva som har skjedd med ordren.'
  },
  {
    id: 'send-pickup-code',
    name: 'Send hentekode',
    text:
      'Hvis kunden har kjøpt billett til andre vil det generes en hentekode. Vi kan hjelpe med å resende denne hentekoden på SMS. Vi kan bare sende hentekode til Skandinaviske numre.'
  },
  {
    id: 'send-receipt',
    name: 'Send kvittering',
    text:
      'Bare ordre som kan få tilsendt kvittering vil ha knapp for ‘Send kvittering’. Kvitteringen sendes til epostadressen du skriver inn i epost feltet.'
  },
  {
    id: 'cancel-order',
    name: 'Kansellere',
    text:
      'Bare ordre som kan kanselleres vil ha en knapp ‘Kanseller’. Billetter blir kansellert fra dagens dato, men det kan gå helt til neste dag før den forsvinner fra kundens app. Hvis du kansellerer en billett vil ikke kunden få refundert kjøpet.'
  },
  {
    id: 'view-app-info',
    name: 'Vise Appinformasjon',
    text:
      'På siden du kommer til når du trykker på en AppId vil du se litt informasjon om appen og telefonen denne er lastet ned på. Her kan du også Sperre epost og Slette mobilnummer.'
  },
  {
    id: 'delete-phone',
    name: 'Slett mobilnummer',
    text:
      'Hvis kunden har lagt inn telefonnummer i appen vil man kunne slette dette fra siden Vis Appinformasjon vha knappen ‘Slett mobilnummer’. Mobilnummeret blir ikke slette med det samme men det blir datomarkert for sletting og vil bli slettet etter to uker.'
  },
  {
    id: 'block-email',
    name: 'Sperre epost',
    text:
      'Hvis en kunde legger inn feil epostadresse og eieren av denne synes det er slitsomt å motta kvitteringer på epost kan vi sperre en bestemt appId fra å sende kvitteringer til en bestemt epostadresse. Dette vil ikke vises noe sted, men kunden vil få feilmelding i appen dersom den forsøker å sende kvittering til den sperrede eposten.'
  },
  {
    id: 'delete-payment',
    name: 'Slette betalingsmåte',
    text:
      'Under Vise Appinformasjon -> Betaling kan man se de betalingsmåtene (kort) som kunden har lagt til i appen og kan slette disse hvis kunden ønsker. Da vil det ikke være mulig å benytte seg av denne betalingsmåten i apen og det vil vises en feilmelding.'
  },
  {
    id: 'view-order-for-app',
    name: 'Vise ordreliste for AppId',
    text: 'Under Vise Appinformasjon -> Ordre liste kan du se alle ordrene som er knyttet til AppIden.'
  },
  {
    id: 'view-payment',
    name: 'Vise betalingsavtaler',
    text: 'Under Vise Appinformasjon -> Betaling kan vi se betalingsmåtene kunden har lagt inn i appen.'
  },
  {
    id: 'error-page',
    name: 'Oppslag feilkode',
    text: 'Det er en egen side der man kan søke etter <a href="/help" class="link">feilkoder</a>.'
  }
];
