import { parse } from 'query-string';
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppEditingTypes, AppSearch, AppSource, CitybikeOrderSource, OosEditing, OrderEditingTypes, OrderSource, RootState, SearchResult } from '../app/interfaces';
import { getEnvName, getProductIdData, hasWriteAccess } from '../common/General';
import { Loading } from '../common/loading/Loading';
import { TabContent, Tablist } from '../common/tablist/Tablist';
import { Company } from '../constants';
import { openEditing } from '../oos-action-creators';
import { search } from '../oos-actions';
import { AppIdData, SearchData } from '../oosAjax';
import './ViewApp.scss';
import { ViewAppTableList } from './ViewAppTableList';


interface ViewAppProps {
  productId: unknown;
  actions: any;
  storage: any;
  hasWriteAccess: boolean,
  company: number,
}

class ViewApp extends React.Component<ViewAppProps & RouteComponentProps> {
  UNSAFE_componentWillMount(): void {
    const { actions, location, company } = this.props;
    const { id = '', pid = '' } = parse(location.search);

    const data: AppIdData = { id: id as string, pid: pid as string, allowLoading: true };

    actions.getAppinstance(data, (result: SearchResult<{ userprofileid: string, platform_version: string | undefined }>) => {
      data.uid = (result._source || {}).userprofileid;
      data.platformVersion = result._source.platform_version
      actions.getAppById(data);
    });
  }

  render(): JSX.Element {
    const { storage, actions, productId, hasWriteAccess, company } = this.props;
    const search = storage?.search as AppSearch;

    if (!search?.appinstance?._source) {
      return storage.waitingFor ? <Loading /> : <div className="empty-text">App finnes ikke.</div>;
    }
    const source = search.appinstance._source;
    const tablist: TabContent[] = [];
    if (search.order) {
      tablist.push({
        id: 'order',
        name: 'Ordreliste',
        order: source,
        content: search.order.hits?.hits || []
      });
    }

    if (search.log?.hits) {
      tablist.push({
        id: 'appinstancelog',
        name: 'Logger',
        order: source,
        content: search.log.hits?.hits || []
      });
    }

    if (search.agreement?.hits) {
      tablist.push({
        id: 'agreement',
        name: 'Betalingsavtale',
        order: source,
        content: search.agreement?.hits.hits || []
      });
    }

    const endUserId = source.citybike_enduserid;
    const envName = getEnvName();
    let origin = envName === "prod" ? 'https://www.kolumbus.citybike.cloud' : 'https://www.kolumbus.betabike.no';

    if (company === Company.Brakar) {
      origin = 'https://www.brakar.citybike.cloud/'
    }

    const seeBikeUrl = `${origin}/bikes/support?endUserId=${endUserId}`;
    const seePreviousBikesUrl = `${origin}/sessions/history?endUserId=${endUserId}`;

    if (search.citybikeorders?.hits?.hits?.length) {
      tablist.push({
        id: 'citybike',
        name: 'Bysykkel',
        order: source,
        content: search.citybikeorders.hits?.hits || []
      });
    }

    if (search.blackListLogs?.length) {
      tablist.push({
        id: 'blacklist',
        name: 'Svartelistelogg',
        order: source,
        content: search.blackListLogs || []
      });
    }

    const envSuffix = envName == 'prod' ? "" : envName == 'dev' ? ".dev" : ".test";
    const domain = envName == 'dev' ? "transhub.io" : "kollektivkameratene.no";
    const newKsp = `https://ksp${envSuffix}.${domain}/V2/app/${search.appinstance._id}`;
    const v2App = source.platform_version === 'v2';
    return (
      <div className="view-app-wrapper">
        <div className="view-app-header">
          <div className="cnt">
            <h2>App-ID : {source.AppId || source.instancename}</h2>
          </div>
        </div>
        <div className="view-information-wrapper view-order-information">
          {!v2App && (
            <ViewAppTableList source={source} />
          )}
          {v2App && (
            <div className='empty-text'>NB: Denne er en nyere app som må behandles i den
              <b> <a href={newKsp} className='underline'>nye Kundesenterportalen</a></b>, og vi viser derfor bare bysykkel-informasjon her.
              <p>Refusjon, svartelisting etc må også gjøres i den nye Kundesenterportalen</p>
            </div>
          )}
          <div className="action-holder">
            {hasWriteAccess && source.platform_version != 'v2' && (
              <button
                title="Sperre epost"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'toggleemail', source);
                }}
              >
                <span>Sperre epost</span>
              </button>
            )}
            {source.mobilephonenumbercountrycode && hasWriteAccess ? (
              <button
                title="Slett telefonnummer"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'deletephone', source);
                }}
              >
                <span aria-hidden="true">Slett telefonnummer</span>
                <span className="aria-visible">Slett telefonnummer {source.mobilephonenumbercountrycode}</span>
              </button>
            ) : null}
            {endUserId && (company === Company.Brakar || company === Company.Kolumbus) && source.citybike_blacklist && !v2App ? (
              <button
                title="Opphev svartelisting"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'citybikeremoveblacklist', source);
                }}
              >
                <span>Opphev svartelisting</span>
              </button>
            ) : null}
            {endUserId && (company === Company.Brakar || company === Company.Kolumbus) && !source.citybike_blacklist && !v2App ? (
              <button
                title="Svartelist bruker"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'citybikeblacklist', source);
                }}
              >
                <span>Svartelist bruker</span>
              </button>
            ) : null}
            {endUserId && (company === Company.Brakar || company === Company.Kolumbus) ? (
              <>
                <a
                  href={seeBikeUrl}
                  role="button"
                  title="Se bysykkel"
                  className="secondary-btn -blue -fill"
                  target="cityBikeView"
                  rel="noopener norefferer"
                >
                  <span>Se bysykkel</span>
                </a>

                <a
                  href={seePreviousBikesUrl}
                  role="button"
                  title="Tidligere sykler"
                  className="secondary-btn -blue -fill"
                  target="cityBikeView"
                  rel="noopener norefferer"
                >
                  <span>Tidligere sykler</span>
                </a>
              </>
            ) : null}
          </div>
        </div>
        <Tablist
          list={tablist}
          callback={this._click}
          actions={actions}
          parent="viewapp"
          productId={productId}
          hasWriteAccess={hasWriteAccess}
        />
      </div>
    );
  }

  private _click = (
    e: SyntheticEvent,
    key: OrderEditingTypes | AppEditingTypes | 'citybikerefund',
    data: AppSource | SearchResult<OrderSource> | SearchResult<CitybikeOrderSource>
  ) => {
    if (e) {
      e.preventDefault();
    }

    const { actions } = this.props;

    switch (key) {
      case 'deletephone':
      case 'openreconstruct':
      case 'blockreconstruct':
      case 'toggleemail':
      case 'citybikeblacklist':
      case 'citybikeremoveblacklist':
        actions.openEditing({
          type: key,
          app: data as AppSource
        });
        break;
      case 'refund':
      case 'moveticket':
      case 'sendreceipt':
      case 'deletepayment':
        actions.openEditing({
          type: key,
          order: data as SearchResult<OrderSource>
        });
        break;
      case 'citybikerefund':
        actions.openEditing({
          type: key,
          cbOrder: data as SearchResult<CitybikeOrderSource>
        });
        break;
      default:
        console.warn('Unknown action:', key, data);
    }
  };
}


const mapStateToProps = (state: RootState) => {
  return {
    hasWriteAccess: hasWriteAccess(state.accessToken),
    storage: state.oos,
    productId: getProductIdData(state.accessToken.CompanyId),
    company: state.accessToken.CompanyId,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, AnyAction>) => ({
  actions: {
    search: (data: SearchData, callback: (response: any) => void) => dispatch(search(data, callback, 'search')),
    getAppinstance: (data: AppIdData, callback: (result: SearchResult<{ userprofileid: string }>) => void) => dispatch(search(data, callback, 'getAppinstance')),
    getAppById: (data: AppIdData, callback?: () => void) => dispatch(search(data, callback, 'getAppById')),
    openEditing: (data: OosEditing) => dispatch(openEditing(data)),
    dispatch,
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewApp));
