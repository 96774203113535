/* eslint no-template-curly-in-string: 0 */

export const ErrorSAPIlist = [
  {
    id: '840',
    text: 'Bruker har ikke lov å utføre aksjonen. Skyldes her at app er blokkert'
  },
  { id: '999', text: 'SAPI: Generell feilmelding' },
  { id: '201', text: 'SAPI: Fant ikke.' },
  { id: '400', text: 'Input feil.' },
  { id: '853', text: 'Kreditering er ikke tillatt' },
  { id: '978', text: 'Kreditering feilet' },
  { id: '977', text: 'Sending til NOD feilet' },
  {
    id: '1978',
    text: 'Kreditering er ikke støttet. Pr nå er ikke denne aktuel, ble brukt for Vipps'
  }
];

export const ErrorSAPImapping = [
  { id: 'SAPI', headline: '92', text: '500092' },
  { id: '5026-x', headline: '91', text: '500091' },
  { id: '1074-4-5', headline: '4', text: '50004' },
  { id: '5011-x-x', headline: '55', text: '500055' },
  { id: '1174-23-14', headline: '5', text: '50005' },
  { id: '1074-23-14', headline: '5', text: '50005' },
  { id: '1174-4-5', headline: '4', text: '50004' },
  { id: '1067-4-x', headline: '5', text: '50005' },
  { id: '1167-4-x', headline: '5', text: '50005' },
  { id: '1009-x-x', headline: '53', text: '500053' },
  { id: '1010-x-x', headline: '22', text: '500022' },
  { id: '5010-x-x', headline: '55', text: '500055' },
  { id: '5012-x', headline: '79', text: '500079' },
  { id: '5013-x-x', headline: '21', text: '500021' },
  { id: '875-x-x', headline: '58', text: '500058' },
  { id: '5014-x', headline: '94', text: '500094' },
  { id: '5015-x', headline: '61', text: '500061' },
  { id: '5016-x', headline: '62', text: '500062' },
  { id: '5017-x-x', headline: '63', text: '500063' },
  { id: '5018-x-x', headline: '21', text: '500021' },
  { id: '5020-x-x', headline: '25', text: '500025' },
  { id: '252-x-27', headline: '65', text: '500065' },
  { id: '409-38-3', headline: '59', text: '500059' },
  { id: '882-6-56', headline: '67', text: '500067' },
  { id: '4009-6-15', headline: '71', text: '500071' },
  { id: '5022-x', headline: '72', text: '500072' },
  { id: '1964-4-x', headline: '68', text: '500068' },
  { id: '881-x-x', headline: '74', text: '500074' },
  { id: '960-x-x', headline: '75', text: '500075' },
  { id: '5023-x', headline: '77', text: '500077' },
  { id: '5024-x', headline: '78', text: '500078' },
  { id: '5025-x', headline: '95', text: '500095' },
  { id: '5027-x', headline: '93', text: '500093' },
  { id: '200-x-66', headline: '9', text: '50009' },
  { id: '415-4-x', headline: '73', text: '500073' },
  { id: '201-x-66', headline: '86', text: '500086' },
  { id: '221-x-66', headline: '31', text: '500031' },
  { id: '400-x-66', headline: '85', text: '500085' },
  { id: '2962-6-15', headline: '69', text: '500069' },
  { id: '2963-x-x', headline: '69', text: '500069' },
  { id: '201-15-38', headline: '87', text: '500087' },
  { id: '974-4-68', headline: '52', text: '500052' },
  { id: '200-27-36', headline: '-1', text: '50002' },
  { id: '431-4-x', headline: '3', text: '50003' },
  { id: '967-4-x', headline: '52', text: '500052' },
  { id: '962-4-x', headline: '4', text: '50004' },
  { id: '975-4-7', headline: '4', text: '50004' },
  { id: '965-4-x', headline: '6', text: '50006' },
  { id: '980-4-7', headline: '7', text: '50007' },
  { id: '981-4-7', headline: '7', text: '50007' },
  { id: '995-4-7', headline: '7', text: '50007' },
  { id: '980-3-7', headline: '7', text: '50007' },
  { id: '977-3-10', headline: '11', text: '500011' },
  { id: '852-27-36', headline: '12', text: '500012' },
  { id: '971-4-5', headline: '13', text: '500013' },
  { id: '977-4-x', headline: '13', text: '500013' },
  { id: '972-4-5', headline: '13', text: '500013' },
  { id: '973-4-5', headline: '13', text: '500013' },
  { id: '964-23-15', headline: '47', text: '500047' },
  { id: '981-3-7', headline: '14', text: '500014' },
  { id: '414-4-x', headline: '15', text: '500015' },
  { id: '857-27-36', headline: '46', text: '500046' },
  { id: '904-x-x', headline: '45', text: '500045' },
  { id: '911-x-x', headline: '16', text: '500016' },
  { id: '963-16-35', headline: '44', text: '500044' },
  { id: '854-27-36', headline: '17', text: '500017' },
  { id: '974-23-14', headline: '52', text: '500052' },
  { id: '406-x-3', headline: '42', text: '500042' },
  { id: '413-4-x', headline: '82', text: '500082' },
  { id: '200-15-39', headline: '89', text: '500089' },
  { id: '204-x-3', headline: '42', text: '500042' },
  { id: '201-15-39', headline: '89', text: '500089' },
  { id: '951-4-x', headline: '82', text: '500082' },
  { id: '201-13-34', headline: '25', text: '500025' },
  { id: '201-x-3', headline: '42', text: '500042' },
  { id: '201-13-35', headline: '25', text: '500025' },
  { id: '406-3-4', headline: '42', text: '500042' },
  { id: '201-16-35', headline: '25', text: '500025' },
  { id: '999-x-x', headline: '18', text: '500018' },
  { id: '206-x-3', headline: '42', text: '500042' },
  { id: '850-x-3', headline: '41', text: '500041' },
  { id: '990-12-33', headline: '18', text: '500018' },
  { id: '839-x-x', headline: '48', text: '500048' },
  { id: '964-4-x', headline: '39', text: '500039' },
  { id: '201-31-37', headline: '36', text: '500036' },
  { id: '201-27-36', headline: '36', text: '500036' },
  { id: '995-27-36', headline: '18', text: '500018' },
  { id: '995-3-7', headline: '18', text: '500018' },
  { id: '410-4-x', headline: '35', text: '500035' },
  { id: '978-x-3', headline: '19', text: '500019' },
  { id: '963-16-41', headline: '34', text: '500034' },
  { id: '406-15-39', headline: '89', text: '500089' },
  { id: '251-1-26', headline: '82', text: '500082' },
  { id: '409-27-36', headline: '81', text: '500081' },
  { id: '963-13-34', headline: '34', text: '500034' },
  { id: '251-4-26', headline: '82', text: '500082' },
  { id: '251-10-27', headline: '82', text: '500082' },
  { id: '991-12-33', headline: '18', text: '500018' },
  { id: '200-8-18', headline: '82', text: '500082' },
  { id: '200-8-29', headline: '8', text: '50008' },
  { id: '222-10-27', headline: '82', text: '500082' },
  { id: '251-9-28', headline: '82', text: '500082' },
  { id: '251-4-28', headline: '82', text: '500082' },
  { id: '903-x-x', headline: '26', text: '500026' },
  { id: '997-23-16', headline: '25', text: '500025' },
  { id: '997-23-15', headline: '25', text: '500025' },
  { id: '951-23-15', headline: '25', text: '500025' },
  { id: '411-4-x', headline: '81', text: '500081' },
  { id: '432-4-x', headline: '81', text: '500081' },
  { id: '400-4-x', headline: '81', text: '500081' },
  { id: '433-4-1', headline: '81', text: '500081' },
  { id: '992-x-x', headline: '82', text: '500082' },
  { id: '400-15-39', headline: '90', text: '500090' },
  { id: '993-x-x', headline: '82', text: '500082' },
  { id: '997-x-x', headline: '25', text: '500025' },
  { id: '400-4-9', headline: '80', text: '500080' },
  { id: '433-4-8', headline: '81', text: '500081' },
  { id: '400-4-7', headline: '81', text: '500081' },
  { id: '400-3-7', headline: '81', text: '500081' },
  { id: '400-x-24', headline: '85', text: '500085' },
  { id: '400-15-38', headline: '88', text: '500088' },
  { id: '400-21-38', headline: '88', text: '500088' },
  { id: '400-10-27', headline: '85', text: '500085' },
  { id: '400-12-33', headline: '81', text: '500081' },
  { id: '401-12-33', headline: '81', text: '500081' },
  { id: '402-12-33', headline: '81', text: '500081' },
  { id: '400-13-34', headline: '81', text: '500081' },
  { id: '401-27-36', headline: '81', text: '500081' },
  { id: '402-27-36', headline: '81', text: '500081' },
  { id: '403-27-36', headline: '81', text: '500081' },
  { id: '404-27-36', headline: '81', text: '500081' },
  { id: '405-27-36', headline: '81', text: '500081' },
  { id: '406-27-36', headline: '81', text: '500081' },
  { id: '407-27-36', headline: '81', text: '500081' },
  { id: '408-27-36', headline: '81', text: '500081' },
  { id: '901-x-x', headline: '82', text: '500082' },
  { id: '902-x-x', headline: '82', text: '500082' },
  { id: '906-x-x', headline: '82', text: '500082' },
  { id: '907-x-x', headline: '82', text: '500082' },
  { id: '991-x-x', headline: '82', text: '500082' },
  { id: '400-23-16', headline: '84', text: '500084' },
  { id: '400-27-36', headline: '81', text: '500081' },
  { id: '400-x-17', headline: '81', text: '500081' },
  { id: '963-4-x', headline: '34', text: '500034' },
  { id: '963-23-15', headline: '34', text: '500034' },
  { id: '963-13-35', headline: '34', text: '500034' },
  { id: '422-27-36', headline: '33', text: '500033' },
  { id: '201-10-27', headline: '32', text: '500032' },
  { id: '221-21-38', headline: '31', text: '500031' },
  { id: '221-x-24', headline: '31', text: '500031' },
  { id: '221-10-27', headline: '31', text: '500031' },
  { id: '221-15-38', headline: '31', text: '500031' },
  { id: '200-10-27', headline: '30', text: '500030' },
  { id: '932-12-33', headline: '54', text: '500054' },
  { id: '932-x-23', headline: '29', text: '500029' },
  { id: '851-3-10', headline: '28', text: '500028' },
  { id: '206-3-10', headline: '28', text: '500028' },
  { id: '409-3-10', headline: '27', text: '500027' },
  { id: '406-3-10', headline: '27', text: '500027' },
  { id: '204-3-10', headline: '27', text: '500027' },
  { id: '201-3-10', headline: '27', text: '500027' },
  { id: '995-4-43', headline: '25', text: '500025' },
  { id: '995-4-9', headline: '25', text: '500025' },
  { id: '995-4-8', headline: '25', text: '500025' },
  { id: '995-4-x', headline: '25', text: '500025' },
  { id: '961-16-41', headline: '24', text: '500024' },
  { id: '931-x-x', headline: '23', text: '500023' },
  { id: '971-23-14', headline: '82', text: '500082' },
  { id: '976-23-16', headline: '25', text: '500025' },
  { id: '962-23-15', headline: '21', text: '500021' },
  { id: '984-4-7', headline: '81', text: '500081' },
  { id: '400-8-18', headline: '25', text: '500025' },
  { id: '977-27-36', headline: '82', text: '500082' },
  { id: '412-4-x', headline: '82', text: '500082' },
  { id: '912-4-x', headline: '83', text: '500083' },
  { id: '409-x-3', headline: '20', text: '500020' },
  { id: '409-3-4', headline: '25', text: '500025' },
  { id: '985-7-20', headline: '14', text: '500014' },
  { id: '973-23-14', headline: '25', text: '500025' },
  { id: '855-x-x', headline: '17', text: '500017' },
  { id: '400-x-23', headline: '25', text: '500025' },
  { id: '400-23-14', headline: '25', text: '500025' },
  { id: '972-23-14', headline: '25', text: '500025' },
  { id: '5021-x', headline: '-1', text: '-1' },
  { id: '5019-x-x', headline: '-1', text: '-1' },
  { id: '983-3-7', headline: '-1', text: '-1' },
  { id: '983-5-7', headline: '-1', text: '-1' },
  { id: '980-5-7', headline: '-1', text: '-1' },
  { id: '983-4-7', headline: '-1', text: '-1' },
  { id: '400-5-7', headline: '-1', text: '-1' },
  { id: '420-23-16', headline: '-1', text: '-1' },
  { id: '200-x-30', headline: '-1', text: '-1' },
  { id: '50-x-13', headline: '-1', text: '-1' },
  { id: '982-3-7', headline: '-1', text: '-1' },
  { id: '982-5-7', headline: '-1', text: '-1' },
  { id: '982-4-7', headline: '-1', text: '-1' },
  { id: '995-5-7', headline: '-1', text: '-1' },
  { id: '984-3-7', headline: '-1', text: '-1' },
  { id: '984-5-7', headline: '-1', text: '-1' },
  { id: '983-3-10', headline: '-1', text: '-1' },
  { id: '909-5-11', headline: '-1', text: '-1' },
  { id: '400-5-11', headline: '-1', text: '-1' },
  { id: '983-x-3', headline: '-1', text: '-1' },
  { id: '981-5-7', headline: '-1', text: '-1' },
  { id: '50-x-x', headline: '-1', text: '-1' },
  { id: '941-x-3', headline: '-1', text: '-1' },
  { id: '201-16-41', headline: '-1', text: '-1' },
  { id: '400-16-41', headline: '-1', text: '-1' },
  { id: '400-4-6', headline: '-1', text: '-1' },
  { id: '400-3-6', headline: '-1', text: '-1' },
  { id: '400-5-6', headline: '-1', text: '-1' },
  { id: '414-3-10', headline: '-1', text: '-1' },
  { id: '983-3-4', headline: '-1', text: '-1' },
  { id: '810-3-10', headline: '-1', text: '-1' }
];

export const ErrorSAPIcodes = [
  { id: '52', text: 'Betaling avvist' },
  { id: '53', text: 'App er sperret' },
  { id: '22', text: 'En feil har oppstått' },
  { id: '85', text: 'En feil har oppstått' },
  { id: '26', text: 'En feil har oppstått' },
  { id: '54', text: 'E-postadresse er sperret' },
  { id: '55', text: 'Tidsavbrudd' },
  { id: '57', text: 'En feil har oppstått' },
  { id: '58', text: 'For mange forsøk' },
  { id: '65', text: 'Kjøp ikke mulig' },
  { id: '61', text: 'Oppgradering feilet' },
  { id: '59', text: 'Billetten kan ikke kanselleres' },
  { id: '66', text: 'En feil har oppstått' },
  { id: '77', text: 'Ugyldige data' },
  { id: '78', text: 'Oppgradering kreves' },
  { id: '62', text: 'En sikkerhetsfeil har oppstått' },
  { id: '63', text: 'Nettverksfeil' },
  { id: '64', text: 'En feil har oppstått' },
  { id: '67', text: 'Ikke aktivert' },
  { id: '71', text: 'Strex ble ikke lagt til' },
  { id: '72', text: 'En feil har oppstått' },
  { id: '68', text: 'Beløpsgrense oversteget' },
  { id: '74', text: 'Automatisk fornyelse stoppet' },
  { id: '75', text: 'Billett kan ikke kjøpes' },
  { id: '69', text: 'Mobilbetaling ikke tilgjengelig' },
  { id: '91', text: 'Feil ved lesing av reisekort' },
  { id: '80', text: 'En feil har oppstått' },
  { id: '81', text: 'En feil har oppstått' },
  { id: '82', text: 'En feil har oppstått' },
  { id: '73', text: 'En feil har oppstått' },
  { id: '83', text: 'En feil har oppstått' },
  { id: '93', text: 'Ugyldig kort' },
  { id: '84', text: 'En feil har oppstått' },
  { id: '79', text: 'Tidsavbrudd' },
  { id: '86', text: 'Fant ikke reisestrekning' },
  { id: '87', text: 'Finner ikke stoppested' },
  { id: '88', text: 'En feil har oppstått' },
  { id: '89', text: 'En feil har oppstått' },
  { id: '90', text: 'En feil har oppstått' },
  { id: '92', text: 'Oppdatering påkrevd' },
  { id: '4', text: 'Betaling feilet' },
  { id: '1', text: '-' },
  { id: '2', text: '-' },
  { id: '3', text: 'Antall reisende er for høyt' },
  { id: '5', text: 'Betaling feilet' },
  { id: '6', text: 'Betaling feilet' },
  { id: '7', text: 'Billett ikke levert' },
  { id: '8', text: 'En feil har oppstått' },
  { id: '9', text: 'Fant ikke reisestrekning' },
  { id: '48', text: 'Ikke tilgjengelig' },
  { id: '11', text: 'Billett ikke levert' },
  { id: '12', text: 'Billett kan ikke automatisk gjenopprettes' },
  { id: '13', text: 'En feil har oppstått' },
  { id: '14', text: 'En feil har oppstått' },
  { id: '15', text: 'En feil har oppstått' },
  { id: '16', text: 'En feil har oppstått' },
  { id: '17', text: 'En feil har oppstått' },
  { id: '34', text: 'Feil passord' },
  { id: '35', text: 'Feil pris' },
  { id: '39', text: 'For mange forsøk' },
  { id: '44', text: 'Oppgi passord' },
  { id: '18', text: 'En feil har oppstått' },
  { id: '19', text: 'En feil har oppstått' },
  { id: '20', text: 'En feil har oppstått' },
  { id: '21', text: 'En feil har oppstått' },
  { id: '23', text: 'En feil har oppstått' },
  { id: '24', text: 'En feil har oppstått' },
  { id: '25', text: 'En feil har oppstått' },
  { id: '27', text: 'Endring av starttidspunkt feilet' },
  { id: '28', text: 'Endring av starttidspunkt ikke lenger tillatt' },
  { id: '29', text: 'E-postadresse er sperret' },
  { id: '30', text: 'Fant ikke fra-stoppested' },
  { id: '31', text: 'Fant ikke reisestrekning' },
  { id: '32', text: 'Fant ikke til-stoppested' },
  { id: '33', text: 'Feil app-ID' },
  { id: '36', text: 'Finner ikke billetter' },
  { id: '37', text: 'For mange forsøk' },
  { id: '38', text: 'For mange forsøk' },
  { id: '40', text: 'For mange forsøk' },
  { id: '41', text: 'Ikke tillatt å kansellere billett' },
  { id: '42', text: 'Kansellering av billett feilet' },
  { id: '45', text: 'Oppgradering kreves' },
  { id: '46', text: 'Passord mangler' },
  { id: '47', text: 'Passordet er sperret' },
  { id: '49', text: 'Betaling avvist' },
  { id: '94', text: 'En feil har oppstått' },
  { id: '95', text: 'En feil har oppstått' },
  {
    id: '500052',
    text:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Om problem vedvarer, bruk en annen betalingsmåte. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500053',
    text:
      'Denne appen er blitt gjenopprettet på en annen enhet, og er derfor sperret. For kjøp på denne enheten, må du legge inn betalingskort på nytt. Ved problemer ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500022',
    text:
      'Deaktiver fingeravtrykk og aktiver på nytt. Ved problemer ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500085',
    text:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, avinstaller ${appName} og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500026',
    text:
      '${appName} støtter ikke denne plattformen. Avinstaller ${appName} og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500054',
    text:
      'Kontaktskjema er ikke sendt. Sjekk at e-postadressen er korrekt. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500055',
    text:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500057',
    text:
      'Prøv igjen senere. Om problemet vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode}  og app-ID ${appid}.'
  },
  {
    id: '500058',
    text:
      'Funksjonen er midlertidig sperret. Sjekk at du har riktig hentekode, og prøv igjen senere. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500065',
    text: 'Kjøp av periodebillett til andre er ikke lenger tilgjengelig.'
  },
  {
    id: '500061',
    text:
      'Avinstaller ${appName} og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  { id: '500059', text: 'Billetten er allerede tatt i bruk.' },
  {
    id: '500066',
    text: 'Prøv igjen. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500077',
    text: 'Prøv igjen. Om problemet vedvarer, velg reise direkte i ${appName}.'
  },
  {
    id: '500078',
    text: 'App-versjonen du vil kjøpe fra, er ikke støttet.  Vennligst oppgrader.'
  },
  {
    id: '500062',
    text:
      'Avinstaller ${appName} og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500063',
    text:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500064',
    text: 'Prøv igjen. Om problem vedvarer, kontakt ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500067',
    text:
      'Automatisk fornyelse er gjelder allerede i bruk av for en annen billett. Du kan ikke ha dette på flere billetter samtidig.'
  },
  {
    id: '500071',
    text:
      'Ditt abonnement er sperret for mobilbetaling. Kontakt din mobiloperatør for å fjerne sperren. (Feilkode ${feilkode}.)'
  },
  {
    id: '500072',
    text: '${appName} får ikke åpnet mCASH. Vennligst bytt til en annen betalingsmåte.'
  },
  {
    id: '500068',
    text:
      'Dette kjøpet vil innebære at du overstiger beløpsgrensen til Strex. Velg en annen betalingsmåte. (Feilkode ${feilkode} og app-ID ${appid}.)'
  },
  {
    id: '500074',
    text:
      'Betaling feilet. Kjøp ny billett og start automatisk fornyelse på nytt. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500045',
    text: 'Din versjon av ${appName} er ikke lenger støttet. Vennligst oppgrader.'
  },
  {
    id: '500075',
    text:
      'Du må aktivere betalingskortet ditt med 3D Secure. For å gjøre det må du oppgradere ${appName} til 3.5, bruke en nyere telefon, eller du kan bruke et reisekort. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500069',
    text:
      'Ditt mobilabonnement kan ikke brukes til betaling. Velg en annen betalingsmåte.(Feilkode ${feilkode} og app-ID ${appid}.)'
  },
  {
    id: '500091',
    text: 'Prøv igjen. (Feilkode ${feilkode} og app-ID ${appid}.)'
  },
  {
    id: '500080',
    text: 'Sone ikke lenger gyldig. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500081',
    text:
      'Avinstaller ${appName} og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500082',
    text:
      'Prøv igjen. Om problem vedvarer, bruk en annen betalingsmåte enn ${appName}. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500073',
    text: 'Prøv igjen. Legg inn stoppesteder (Fra og til) eller Soner på nytt.(Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500083',
    text: 'Prøv igjen med en tidligere startdato. (Feilkode ${feilkode},   app-ID ${appid}.)'
  },
  { id: '500093', text: 'Dette kortet er ikke et gyldig reisekort' },
  {
    id: '500084',
    text:
      'Prøv igjen. Om problem vedvarer, kan en løsning være å gå til Innstillinger og Endre passord. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500079',
    text:
      'Dårlig eller ingen forbindelse. Prøv et sted med bedre signaler, eller bytt til annet Wi-Fi. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  { id: '500086', text: 'Prøv igjen med et annet stoppested i til-feltet.' },
  {
    id: '500087',
    text:
      'Velg et annet stoppested, eller oppgi hvilke soner du skal kjøpe billett for. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500088',
    text:
      'Prøv igjen med et annet stoppested eller en annen sone. Om problem vedvarer, kjøp enkeltbillett. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500089',
    text: 'Tilleggsbillett er ikke tilgjengelig. Kjøp enkeltbillett. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500090',
    text: 'Prøv igjen. Om problem vedvarer, kjøp enkeltbillett. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500092',
    text: 'Kjøp av periodebilletter krever ny versjon av appen. Vennligst oppdater ${appName} i [Play Store/App Store].'
  },
  {
    id: '50004',
    text:
      'Kjøpet er ikke gjennomført. Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  { id: '50001', text: '-' },
  { id: '50002', text: 'Feil app-ID. Prøv igjen.' },
  {
    id: '50003',
    text: 'Maksimalt antall reisende på en billett er endret. Reduser antallet og prøv igjen.'
  },
  {
    id: '50005',
    text:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '50006',
    text:
      'Kjøpet er ikke gjennomført. Prøv igjen. Om problem vedvarer, legg inn bankkort på nytt eller bruk en annen betalingsmåte. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '50007',
    text:
      'Billett er ikke levert. Gjennomfør kjøpet på nytt. Eventuell dobbeltbetaling krediteres automatisk. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '50008',
    text:
      'Prøv igjen eller oppgi hvilke soner du skal kjøpe billett for. Om problem vedvarer, bruk en annen betalingsmåte enn ${appName}. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  { id: '50009', text: 'Prøv igjen med et annet stoppested i fra-feltet.' },
  {
    id: '500048',
    text: 'Billett ikke lenger tilgjengelig for registrert telefonnummer. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500011',
    text: 'Prøv igjen senere. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500012',
    text:
      'Selvbetjent gjenoppretting av billett er kun mulig to ganger. Ta kontakt med ${cs} for gjenoppretting av billett. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500013',
    text:
      'Er beløpet reservert, tilbakeføres det automatisk. Prøv igjen eller bruk en annen betalingsmåte. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500014',
    text: 'Prøv igjen senere. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500015',
    text:
      'Slett betalingsmåte og legg inn på nytt. Om problem vedvarer, avinstaller ${appName} og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500016',
    text: 'Mobilens dato/klokkeslett er feil. Korrekt tid er ${currentTime}. Vennligst korriger.'
  },
  {
    id: '500017',
    text:
      'Overføring av billetter fra din billett-app er sperret. Har du spørsmål, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  { id: '500034', text: 'Prøv igjen.' },
  { id: '500035', text: 'Prisen er endret. Bekreft igjen.' },
  { id: '500039', text: 'Passordet er sperret. Velg nytt passord.' },
  { id: '500044', text: 'Feil passord. Prøv igjen.' },
  {
    id: '500018',
    text: 'Prøv igjen. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500019',
    text:
      'Tilbakebetaling kunne ikke gjennomføres. Ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500020',
    text: 'Vennligst ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500021',
    text: 'Prøv igjen. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500023',
    text:
      'Din billett-app er sperret. Har du spørsmål, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500024',
    text: 'Du har valgt et for høyt beløp. Velg et lavere beløp. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500025',
    text:
      'Prøv igjen. Om problem vedvarer, avinstaller ${appName} og installer på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500027',
    text: 'Prøv igjen. Om problem vedvarer, kanseller og kjøp ny billett. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500028',
    text:
      'Du kan starte billetten nå, eller kansellere den og eventuelt kjøpe ny billett. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500029',
    text:
      'Kvittering er ikke sendt. Sjekk at e-postadressen er korrekt. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  { id: '500030', text: 'Prøv igjen med et annet stoppested i fra-feltet.' },
  {
    id: '500031',
    text: 'Prøv igjen med et annet stoppested i fra- eller til-feltet.'
  },
  { id: '500032', text: 'Prøv igjen med et annet stoppested i til-feltet.' },
  {
    id: '500033',
    text:
      'Du har lagt inn din nye app-ID istedenfor din gamle som billetten skal gjenopprettes fra. Prøv igjen. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500036',
    text:
      'Det ser ikke ut til at du har billetter som kan gjenopprettes. Prøv igjen. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500037',
    text: 'Passordet er midlertidig sperret. Prøv igjen etter kl. <kort klokkeformat tt.mm>.'
  },
  {
    id: '500038',
    text: 'Passordet er sperret. Velg nytt passord, og legg inn betalingskort på nytt.'
  },
  {
    id: '500040',
    text: 'Passordet er midlertidig sperret. Prøv igjen etter kl. <kort klokkeformat tt.mm>.'
  },
  {
    id: '500041',
    text: 'Vennligst ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500042',
    text: 'Vennligst ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500046',
    text:
      'App-en du skal gjenopprette fra, mangler passord. Har du aktive billetter du ønsker gjenopprettet, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  { id: '500047', text: 'Velg nytt passord.' },
  {
    id: '500049',
    text:
      'Kjøp er avvist på grunn av for mange kjøp i perioden. Prøv et annet kort. Om problem vedvarer, ta kontakt med ${cs}. Oppgi feilkode ${feilkode} og app-ID ${appid}.'
  },
  {
    id: '500094',
    text:
      'Prøv igjen. Om problem vedvarer, avinstaller ${appName} og installer appen på nytt. Gjenopprett automatisk ved oppstart, eller senere under Innstillinger. Velger du gjenoppretting fra Innstillinger, trenger du app-ID-en under. (Feilkode ${feilkode}, app-ID ${appid}.)'
  },
  {
    id: '500095',
    text: 'Prøv igjen. (Feilkode ${feilkode} og app-ID ${appid}.)'
  }
];
