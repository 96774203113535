import React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../common/loading/Loading';
import { convertToHumanDate } from '../common/General';

export const HomeAppinstanceWidget = ({ config }) =>
  config && config.text ? (
    <div className="widget appinstances-search-widget">
      {config.loading ? (
        <Loading text={config.loading} small={true} />
      ) : (config.result || []).length === 0 ? (
        <div className="empty-text">Ingen app instanser treff.</div>
      ) : (
        <ul className="appinstance-list">
          {config.result.map((data, i) => {
            let source = data._source || {};
            let id = data._id || source.instancename;
            return (
              <li key={'home-appinstance-list-' + i}>
                <Link
                  className="no-wrap link"
                  to={'/viewappinfo?id=' + id + '&pid=' + (source.paymenttransactionsid || '')}
                >
                  <div className="instance-name">{source.instancename || id}</div>
                  <div className="instance-created-date" title="Opprettet dato">
                    <span>Opprettet: </span> <span>{convertToHumanDate(source.createddate)}</span>
                  </div>
                  <div className="instance-channel">
                    <span title={source.appplatform} className={'icon-btn -' + (source.appplatform || 'none')}>
                      <span className="aria-visible">{source.appplatform}</span>
                    </span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  ) : null;
