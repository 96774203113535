import React from 'react';
import { SuggestionTagbtn } from './SuggestionTagbtn';
import './Suggestion.scss';

export class Suggestion extends React.Component {
  static defaultProps = {
    mode: { timerSearch: 0, previous: {}, timerBlur: {} }
  };

  constructor(props) {
    super(props);
    this.state = {
      id: props.fieldId || 'suggestion-' + new Date().getTime(),
      searchText: props.searchText || '',
      searchKeys: props.searchKeys || ['name'],
      storage: {},
      focusIndex: -1,
      maxSearch: props.maxSearch || 8,
      selectedList: props.selectedList || [],
      matchedList: [],
      matchStart: props.matchStart === true || props.dropdownMenu === true,
      single: props.single === true || props.dropdownMenu === true,
      loading: false,
      contactList: JSON.parse(JSON.stringify(props.list || [])).filter(data => {
        if (data.ID && !data.id) {
          data.id = data.ID;
        }
        if (data.Name && !data.name) {
          data.name = data.Name;
        }
        if (data.id && !data.name) {
          data.name = data.id;
        }

        return data.id && data.name;
      })
    };
  }

  render() {
    const { label, placeholder, dropdownMenu, fieldName } = this.props;
    const { id, selectedList, matchedList, storage, focusIndex, searchText, loading } = this.state;

    const inputName = fieldName || 'suggestion';

    return (
      <div className="suggestion-wrapper input-content -fieldset">
        {label && <label htmlFor={id}>{label}</label>}
        <div className={'suggestion-cnt' + (dropdownMenu ? ' -dropdown-menu' : '')}>
          {selectedList.length > 0 && (
            <ul className="suggestion-holder">
              {' '}
              {selectedList.map((suggestion, i) => (
                <li key={'message-suggestion-' + i} className="inline-block">
                  <SuggestionTagbtn
                    text={suggestion.name || suggestion.text}
                    id={suggestion.id}
                    callback={this._click}
                    type="-white"
                  />
                </li>
              ))}{' '}
            </ul>
          )}
          <div className={'suggestion-field-wrapper' + (searchText ? ' -has-search-text' : '')}>
            <input
              type="text"
              name={inputName}
              id={id}
              className="textfield"
              ref="searchField"
              spellCheck="false"
              autoComplete="off"
              autoCorrect="off"
              placeholder={placeholder || ''}
              onKeyUp={e => {
                this._keyup(e);
              }}
              onKeyDown={e => {
                this._keydown(e);
              }}
              onFocus={e => {
                this._focus(e);
              }}
              onBlur={e => {
                this._blur(e);
              }}
            />
            <div className="suggestion-widget">
              {loading && (
                <div className="loader-wrapper">
                  <div className="loader -small"></div>
                </div>
              )}
              {matchedList.length > 0 && !loading ? (
                <ul className="matched-list">
                  {' '}
                  {matchedList.map((matched, i) => (
                    <li key={'message-matched-' + i}>
                      <button
                        tabIndex="-1"
                        className={
                          'matched-item' +
                          (storage[matched.id] ? ' -selected' : '') +
                          (i === focusIndex ? ' -focus' : '')
                        }
                        onClick={e => {
                          this._clickMatchedItem(e, matched);
                        }}
                      >
                        {matched.name}
                      </button>
                    </li>
                  ))}{' '}
                </ul>
              ) : (
                this.props.mode.previous[this.state.id] && <div className="empty-list">No matched</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.mode.unMount = false;
    }, 250);
  }

  componentWillUnmount() {
    this.props.mode.unMount = true;
  }

  /****************************************************************************
  === PUBLIC FUNCTION ===
  ****************************************************************************/
  keydown = e => {
    this._keydown(e);
  };
  keyup = e => {
    this._keyup(e);
  };
  focus = e => {
    this._focus(e);
  };
  blur = e => {
    this._blur(e);
  };
  search = text => {
    this._search(text);
  };
  setLoading = loading => {
    this.setState({ loading: loading || false });
  };

  /****************************************************************************
  === INTERNAL FUNCTION ===
  ****************************************************************************/
  _keydown = e => {
    const code = e.keyCode;
    if (code === 13) {
      const { focusIndex, matchedList } = this.state;
      if (matchedList[focusIndex]) {
        if (e) {
          e.preventDefault();
        }
      }
    }
  };

  _keyup = e => {
    const target = e.target;
    const value = target.value || '';
    const code = e.keyCode;
    clearTimeout(this.props.mode.timerSearch || 0);

    if (code === 13) {
      const { focusIndex, matchedList } = this.state;
      if (matchedList[focusIndex]) {
        if (e) {
          e.preventDefault();
        }

        const selectedList = this._addSuggestion(matchedList[focusIndex]);
        if (this.props.dropdownMenu) {
          const field = this.refs.searchField;
          if (selectedList && selectedList[0]) {
            field.value = selectedList[0].name;
            this._selectText(0, selectedList[0].name.length, field);
            this.closeWidget();
            //this._search( selectedList[0].name, true );
          } else {
            field.value = '';
          }
        }
      }
    } else if (code === 38 || code === 40) {
      const { focusIndex, matchedList } = this.state;
      if (matchedList.length) {
        let next = focusIndex + (code === 40 ? 1 : -1);
        if (next < 0) {
          next = matchedList.length - 1;
        } else if (next >= matchedList.length) {
          next = 0;
        }
        this.setState({ focusIndex: next });
      }
    } else if (this.props.mode.previous[this.state.id] !== value) {
      this.props.mode.timerSearch = setTimeout(() => {
        this._search(value);
      }, 300);
    }
  };

  _focus = e => {
    const target = e.target;
    const value = target.value || '';
    clearTimeout(this.props.mode.timerBlur[this.state.id] || 0);
    this._search(value);
  };

  _blur = e => {
    if (e) {
      e.preventDefault();
    }

    clearTimeout(this.props.mode.timerBlur[this.state.id] || 0);
    this.props.mode.timerBlur[this.state.id] = setTimeout(() => {
      if (this.props.mode.unMount) {
        return;
      }

      this.closeWidget();
      //this.props.mode.previous[this.state.id] = '';
      //this.setState({'matchedList': [], 'focusIndex': -1});
    }, 200);
  };

  _click = (e, key, data) => {
    if (e) {
      e.preventDefault();
    }

    if (key === 'remove-suggestion-tabbtn') {
      this._removeSuggestion(data);
    }
  };

  _search = (text, ignorDropdownMenu) => {
    const searched = result => {
      this.setState({
        matchedList: result.matchedList,
        focusIndex: -1,
        searchText: result.text,
        loading: false
      });

      if (typeof this.props.callback === 'function') {
        this.props.callback('search-suggestion', {
          text: result.text,
          reg: result.reg,
          matchedList: result.matchedList
        });
      }

      if (this.props.dropdownMenu && !ignorDropdownMenu) {
        this._searchForwardDropdownMenu(text, result);
      }
      this.props.mode.previous[this.state.id] = result.text;
    };

    if (typeof this.props.searchOverwrite === 'function') {
      return this.props.searchOverwrite(text, response => {
        searched(response);
      });
    }

    const { contactList, maxSearch, searchKeys, matchStart } = this.state;
    const list = JSON.parse(JSON.stringify(contactList));
    let i = 0;
    const matchedList = [];
    const reg = matchStart ? this._createRegexp(text, 1, 1, 2) : this._createRegexp(text, 1, 1, 1);

    while (i < list.length && matchedList.length < maxSearch) {
      const data = list[i] || {};
      const found = !text || searchKeys.find(key => ((data[key] || '').match(reg) ? true : false));

      if (found) {
        data.reg = reg;
        matchedList.push(data);
      }
      i++;
    }
    searched({ text: text, reg: reg, matchedList: matchedList });
  };

  _searchForwardDropdownMenu = (text, result) => {
    let name = '';
    if (text && result.matchedList && result.matchedList[0]) {
      if (!this.state.storage[result.matchedList[0].id]) {
        this._addSuggestion(result.matchedList[0]);
        name = result.matchedList[0].name;
      }
    } else if (
      (!text || (result.matchedList || []).length === 0) &&
      this.state.selectedList &&
      this.state.selectedList[0]
    ) {
      this._removeSuggestion(this.state.selectedList[0].id);
    }

    if (name || (this.state.selectedList && this.state.selectedList[0])) {
      if (text.length >= this.props.mode.previous[this.state.id].length) {
        const field = this.refs.searchField;
        const value = name || this.state.selectedList[0].name;

        field.value = value;
        this._selectText(text.length, value.length, field);
      }
    }
  };

  _clickMatchedItem = (e, matched) => {
    if (e) {
      e.preventDefault();
    }

    const field = this.refs.searchField;
    if (field) {
      field.focus();
    }

    if (!matched) {
      return;
    }
    const selectedList = this._addSuggestion(matched);

    if (this.props.dropdownMenu) {
      const field = this.refs.searchField;
      if (selectedList && selectedList[0]) {
        field.value = selectedList[0].name;
        this._selectText(0, selectedList[0].name.length, field);
        this.closeWidget();
      } else {
        field.value = '';
        this._search('');
      }
    }
  };

  _addSuggestion = (suggestion, ignorCallback) => {
    if (!suggestion || !suggestion.id || !suggestion.name) {
      return;
    }

    if (!ignorCallback && typeof this.props.beforeAddSuggestion === 'function') {
      const forward = this.props.beforeAddSuggestion(suggestion);
      if (forward === false) {
        return;
      }
    }

    let { selectedList, storage } = this.state;
    const { single } = this.state;
    if (storage[suggestion.id]) {
      return this._removeSuggestion(suggestion.id);
    } else if (single && selectedList && selectedList[0]) {
      this._removeSuggestion(selectedList[0].id, null, true);
      selectedList = [];
      storage = {};
    }

    storage[suggestion.id] = true;
    selectedList.push(suggestion);
    this.setState({ selectedList: selectedList, storage: storage });

    if (!ignorCallback && typeof this.props.callback === 'function') {
      this.props.callback('add-suggestion', suggestion);
    }

    return selectedList;
  };

  _removeSuggestion = (id, ignorCallback, ignorUpdateState) => {
    if (!id) {
      return;
    }
    const { selectedList, storage } = this.state;
    delete storage[id]; //storage[id] = false;
    const list = selectedList.filter(data => data.id !== id);

    if (!ignorUpdateState) {
      this.setState({ selectedList: list, storage: storage });
    }

    if (!ignorCallback && typeof this.props.callback === 'function') {
      this.props.callback('remove-suggestion', id);
    }

    return list;
  };

  _createRegexp = (text, g, i, b, f) => {
    if (text === '*') {
      return /.*/;
    }
    const v = text.replace(/\*/, '.*').replace(/\+/g, '\\+');
    const m = g && i ? 'gi' : g || i ? (g ? 'g' : 'i') : '';
    const s = b ? (b === 2 ? '^' : b === 3 ? '(^|/|\\s+|,)' : '(^|/|\\s+)') : '';
    const e = f ? (f === 2 ? '$' : f === 3 ? '($|/|\\s+|,)' : '($|/|\\s+)') : '';
    return new RegExp(s + '(' + v + ')' + e, m);
  };

  _selectText = (start, end, field) => {
    if (!field) return;

    const value = field.value || '';
    if (isNaN(end)) {
      end = value.length;
    }

    const interval = [isNaN(start) ? 0 : start, isNaN(end) ? 0 : end];
    if (!isNaN(interval[0]) && !isNaN(interval[1])) {
      if (field.setSelectionRange) {
        // Firefox and other gecko based browsers
        field.setSelectionRange(interval[0], interval[1]);
      } else if (field.createTextRange) {
        // Internet Explorer
        const range = field.createTextRange();
        range.collapse(true);
        range.moveEnd('character', interval[0]);
        range.moveStart('character', interval[1]);
        range.select();
      } else if (field.selectionStart) {
        // Other browsers
        field.selectionStart = interval[0];
        field.selectionEnd = interval[1];
      }
    }
  };

  /****************************************************************************
  === PUBLIC function ====
  ****************************************************************************/
  closeWidget = () => {
    this.props.mode.previous[this.state.id] = '';
    this.setState({ matchedList: [], focusIndex: -1 });
  };

  getSelectedList = () => this.state.selectedList || [];

  setSelectedList = list => {
    if ((list || []).length === 0) {
      return;
    }

    const pin = {};
    const { contactList, storage } = this.state;
    contactList.forEach(src => {
      pin[src.id || src.ID] = src;
    });
    (list || []).forEach(data => {
      const id = data.id || data.ID;
      const src = storage[id] ? null : pin[id];
      if (src) {
        this._addSuggestion(src);
      }
    });
  };

  addSuggestion = suggestion => {
    if (suggestion) {
      this._addSuggestion(suggestion);
    }
  };

  removeSuggestion = id => {
    if (id && typeof id === 'string') {
      this._removeSuggestion(id);
    }
  };
}
