import React from 'react';
import { BlackListLog } from "../../app/interfaces";
import { convertToHumanDate } from '../General';
import "./BlacklistLogComponent.scss";

interface Props {
  logEntries: Array<BlackListLog>;
}

const BLACKLIST_PAYMENT_FAILED = "BLACKLIST_PAYMENT_FAILED";
const BLACKLIST_NO_PAYMENT_METHOD = "BLACKLIST_NO_PAYMENT_METHOD";
const BLACKLIST_MANUAL = "BLACKLIST_MANUAL";
const BLACKLIST_OTHER = "BLACKLIST_OTHER";
const UNBLACKLIST_PAID = "UNBLACKLIST_PAID";
const UNBLACKLIST_PAYMENT_CANCELLED = "UNBLACKLIST_PAYMENT_CANCELLED";
const UNBLACKLIST_MANUAL = "UNBLACKLIST_MANUAL";
const UNBLACKLIST_OTHER = "UNBLACKLIST_OTHER";

const formatPerformedBy = (performedBy: string | null) => {
  if (performedBy === null) {
    return "System (automatisk)"
  } else {
    return performedBy;
  }
}

const getBlacklistTypeCssClass = (type: string) => {
  if (type === BLACKLIST_PAYMENT_FAILED || type === BLACKLIST_NO_PAYMENT_METHOD || type === BLACKLIST_MANUAL || type === BLACKLIST_OTHER) {
    return "event-type-icon blacklist";
  } else if (type === UNBLACKLIST_PAID || type === UNBLACKLIST_PAYMENT_CANCELLED || type === UNBLACKLIST_MANUAL || type === UNBLACKLIST_OTHER) {
    return "event-type-icon unblacklist";
  } else {
    return "event-type-icon unknown";
  }
}

const formatType = (type: string) => {
  switch (type) {
    case BLACKLIST_PAYMENT_FAILED: return "Svartelistet (betaling feilet)";
    case BLACKLIST_NO_PAYMENT_METHOD: return "Svartelistet (ingen betalingsmetode)";
    case BLACKLIST_MANUAL: return "Svartelistet (av bruker)";
    case BLACKLIST_OTHER: return "Svartelistet (annet)";

    case UNBLACKLIST_PAID: return "Svartelisting opphevet (betalt)";
    case UNBLACKLIST_PAYMENT_CANCELLED: return "Svartelisting opphevet (betaling kansellert)";
    case UNBLACKLIST_MANUAL: return "Svartelisting opphevet (av bruker)";
    case UNBLACKLIST_OTHER: return "Svartelisting opphevet (annett)";
    default: return type;
  }
}

const renderTableRow = (logEntry: BlackListLog, index: number) => {
  return (
    <li key={'log-' + index}>
      <div className="column no-wrap" aria-label="Ordre id">
        {logEntry.orderId}
      </div>
      <div className="column" aria-label="Ordredato">
        {convertToHumanDate(logEntry.header.timestamp)}
      </div>
      <div className="column" aria-label="Utført av">
        {formatPerformedBy(logEntry.performedBy)}
      </div>
      <div className="column" aria-label="Kommentar">
        {logEntry.comment}
      </div>
      <div className="column" aria-label="Type">
        <div className={getBlacklistTypeCssClass(logEntry.eventType)}></div>
        {formatType(logEntry.eventType)}
      </div>
    </li>
  )
}

const BlacklistLogComponent: React.FC<Props> = ({ logEntries }) => {
  return (<div className="blacklist-wrapper">
    {logEntries.length === 0 ? (
      <div className="empty-text">Ingen svartelistehendelser for denne brukeren</div>
    ) : (
        <>
          <ul className="table-list home-table-list -break-to-single-column-when-md-p">
            <li className="table-header">
              <div className="column">Billettnummer</div>
              <div className="column">Ordredato</div>
              <div className="column">Utført av</div>
              <div className="column">Kommentar</div>
              <div className="column">Type</div>
            </li>
            {logEntries.map(renderTableRow)}
          </ul>

        </>
      )}
  </div>)
}

export default BlacklistLogComponent;