import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import apiClient from '../apiClient';
import { RootState } from '../app/interfaces';
import { Calendar } from '../common/calendar/Calendar';
import { doesUserHaveCityBikeRight, getPaymentStorCode, hasWriteAccess } from '../common/General';
import { Company, SEARCH_URL } from '../constants';
import getRequestorCodeFromToken from '../requestorCode';
import './Export.scss';

const downloadOrdersFromTicketCounter = async (fromDate, toDate) => {
  const fromDateAsIso = fromDate.toISOString();
  const toDateAsIso = moment(toDate).endOf('day').toDate().toISOString();
  const token = await apiClient.getDecodedAccessToken();
  const result = await apiClient.get(`/proxy/billettluke/order/export/by-company-id/${token.CompanyId}/${fromDateAsIso}/${toDateAsIso}`);
  const orders = result.data.matches;

  const csvHeader = [
    "Kjøpsdato",
    "Antall billetter",
    "Produktnavn",
    "Total pris",
    "Total mva",
    "Fakturareferanse",
    "Kommentar",
  ].join(";");

  const createCsvLine = (order) => {
    return [
      order.purchaseDate,
      order.ticketCount,
      order.productName,
      order.totalPrice.toFixed(2).replace('.', ','),
      order.totalPriceVat.toFixed(2).replace('.', ','),
      order.invoiceReference,
      order.personalReference,
    ].join(';');
  }

  let csvFile = "data:text/csv;charset=utf-8,\uFEFF";
  csvFile += `${csvHeader}\r\n`;
  orders.forEach((row) => {
    const line = createCsvLine(row);
    csvFile += `${line}\r\n`;
  })

  const fileName = `Ordre fra billettluke eksport.csv`;
  const file = encodeURI(csvFile);
  const link = document.createElement("a");
  link.setAttribute("href", file);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

const downloadEndUserIdSearchLog = (fromDate, toDate, companyId) => {
  let index = ''

  if (companyId === Company.Kolumbus) {
    index = 'citybike_enduser_lookup_log';
  }
  if (companyId === Company.Brakar) {
    index = 'brakar_citybike_enduser_lookup_log';
  }

  if(!index) {
    throw new Error(`can only download EndUserIdSearchLog for Brakar or Kolumbus, not CompanyID=${companyId}`)
  }

  const request = [
    `index=${index}`,
    'filters=' +
    JSON.stringify([
      {
        field: 'timestamp',
        type: 'date',
        gte: fromDate,
        lte: toDate
      }
    ])
  ];

  const url = SEARCH_URL + '/export?' + request.join('&');

  apiClient.get(url).then((response) => {
    const fileName = `export_citybike_enduser_lookup_log-${new Date().toISOString()}.csv`
    const file = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response.data);
    const link = document.createElement('a');
    link.setAttribute('href', file);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  })
}

const downloadReportFromElasticSearch = (fromDate: string, toDate: string, field: string, storCode: number | string, index: string) => {
  const request = [
    'index=' + index,
    'filters=' +
    JSON.stringify([
      { field: field, value: storCode },
      {
        field: 'lastupdatedate',
        type: 'date',
        gte: fromDate,
        lte: toDate
      }
    ])
  ];

  const url = SEARCH_URL + '/export?' + request.join('&');

  apiClient.get(url).then((response) => {
    const fileName = `export_${index}-${new Date().toISOString()}.csv`
    const file = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(response.data);
    const link = document.createElement('a');
    link.setAttribute('href', file);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  })
}

const Export: React.FC = () => {
  const hasCityBike = useSelector((state: RootState) => doesUserHaveCityBikeRight(state.accessToken))
  const evict = (useSelector((state: RootState) => hasWriteAccess(state.accessToken) === false))
  const history = useHistory();
  const companyId = useSelector((state: RootState) => state.accessToken.CompanyId);

  if (evict) {
    history.push('/home');
  }

  const exportTypes = [
    { id: 'orders', name: 'Ordrer', field: 'requestorcode' },
    { id: 'orders-ticket-counter', name: "Ordre fra billettluke" },
    {
      id: 'payments',
      name: 'Betalinger',
      field: 'paymentaccountused'
    },
    {
      id: 'appinstances',
      name: 'App instanser',
      field: 'apptypeid'
    },
  ]

  if (hasCityBike) {
    exportTypes.push({
      id: 'citybike-enduser-lookup-log',
      name: 'EndUserId søkelogg',
    })
  }

  const shortcuts = [
    { id: '-today', name: 'Idag' },
    { id: '-yesterday', name: 'Igår' },
    { id: '-week', name: 'Denne uka' },
    { id: '-month', name: 'Denne måneden' }
  ]

  const [error, setError] = useState('');
  const [fromDateTimeStamp, setFromDateTimeStamp] = useState<number | null>(null);
  const [toDateTimeStamp, setToDateTimeStamp] = useState<number | null>(null);
  const [exportType, setExportType] = useState(exportTypes[0].id);


  const downloadFile = e => {
    if (e) {
      e.preventDefault();
    }

    const eData = exportTypes.find(data => data.id === exportType);

    if (!eData) {
      setError('Mangler eksport type')
      return;
    } else if (!fromDateTimeStamp || !toDateTimeStamp) {
      setError('Mangler dato');
      return;
    } else if (fromDateTimeStamp > toDateTimeStamp) {
      setError('Ugyldig dato');
      return;
    } else {
      setError('');
    }

    const storCode = eData.id === 'payments' ? getPaymentStorCode(companyId) : getRequestorCodeFromToken();

    const fromDate = new Date(fromDateTimeStamp);
    const toDate = new Date(toDateTimeStamp);

    const fromDateAsString = moment(fromDate).format('YYYY-MM-DD');
    const toDateAsString = moment(toDate).format('YYYY-MM-DD');


    if (exportType === "orders-ticket-counter") {
      downloadOrdersFromTicketCounter(fromDate, toDate);
    } else if (exportType === 'citybike-enduser-lookup-log') {
      downloadEndUserIdSearchLog(fromDateAsString, toDateAsString, companyId)
    } else {
      downloadReportFromElasticSearch(fromDateAsString, toDateAsString, eData.field, storCode, eData.id)
    }
  };

  const calendarCallback = data => {
    if (data.error) {
      setFromDateTimeStamp(null)
      setToDateTimeStamp(null);
      setError(data.error)
    } else {
      setFromDateTimeStamp(data.stampFrom)
      setToDateTimeStamp(data.stampTo);
      setError('')
    }
  };

  return (
    <div className="export-wrapper">
      <div className="export-header">
        <h2>Eksport av data</h2>
      </div>
      <div className="export-content">
        {error ? (
          <div className="summary-error" role="alert">
            {error}
          </div>
        ) : null}
        <div className="input-content export-type-content">
          <label htmlFor="export-type-selector">Type</label>
          <select className="select-box export-type-selector" id="export-type-selector" value={exportType} onChange={(e: ChangeEvent<HTMLSelectElement>) => setExportType(e.target.value)}>
            {exportTypes.map((data, i) => (
              <option key={'export-type-option-' + i} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </div>
        <div className={'input-content calendar-input-content'}>
          <Calendar
            clock={false}
            view={2}
            shortcuts={shortcuts}
            placeholder={['fra: dd.mm.åååå', 'til: dd.mm.åååå']}
            callback={calendarCallback}
          />
        </div>
        <div className="paragraph action-holder">
          <button
            className="secondary-btn -blue -fill"
            onClick={downloadFile}
          >
            Hent data
          </button>
        </div>
      </div>
    </div>
  );
};



export default Export;