export interface HelpIssue {
  title: string;
  type: string;
  date: string;
  text: Array<string>;
  comment: Array<string>;
}

export const HelpIssueList: Array<HelpIssue> = [
  {
    title: 'Kunde som har gjenopprettet og ikke ser gyldig billett i ny app',
    type: 'issue',
    date: '07.02.2019',
    text: [
      'for noen kunder går det feil under gjenoppretting og billetten som skulle blitt overført blir liggende som eksportert til NOD. Dersom man overfører billetten en gang til ser vi at det kan oppstå en feil som gjør at kunden enten ser billetten som kansellert eller ikke i det hele tatt. Den nye ordren du nettopp har overført får status overført'
    ],
    comment: [
      'Vær oppmerksom på om det ligger en resendt ordre før du overfører, sjekk status på denne. Dersom den har status eksportert til nod må du følge med når du overfører på nytt. Dersom kunden ikke får hentet ned billetten du overfører og den får type mobilbillett og ordrestatus Overført må du kreditere billetten hos betalingleverandør og be kunden kjøpe ny billett.',
      'Når du overfører på nytt mister vi linken til den ordren som ble resendt først, den nye overføringen tar plassen i resendt feltet.',
      'Blir sannsynligvis rettet i utrulling uke 9.'
    ]
  },

  {
    title: 'Kjøp til andre',
    type: 'issue',
    date: '07.02.2019',
    text: ['Noen kunder opplever at billetten får status kansellert hos mottaker i det billetten er hentet.'],
    comment: [
      'Krediter billetten for kunden så de kan kjøpe ny billett. Feilen vil bli rettet i neste oppgradering release av systemet det er planlagt utrulling av denne uke 9.'
    ]
  },

  {
    title: 'Noen kunder opplever at knappen for bekreft kjøp ikke blir grønn når de skal kjøpe billett.',
    type: 'issue',
    date: '05.02.2019',
    text: [
      'Vi har mistanke om at det kan ha noe med at kunden mister nettdekning å gjøre, og at man ikke får melding om det.'
    ],
    comment: [
      'Vi vurderer en egen 6.1.2 release for denne. Meld til support, husk å få med app versjon, plattform og plattformversjon. Få en beskrivelse fra kunden på hva som ble gjort. Hvilke valg har de gjort før de kom til knappen.'
    ]
  },

  {
    title: 'eSIM ikke støtter Bank-ID og lagring av telefonnummer',
    type: 'issue',
    date: '05.02.2019',
    text: [],
    comment: [
      'Dersom en kunde sier de ikke får kjøpt billett i appen og har eSIM kan det være utfordringen. Løsningen blir å betale med en betalingsmåte som ikke bruker bank-id. Evt må man lagre verifisere kortet i en app hvor man har vanlig sim-kort, for så å gjenopprette med app-id til telefonen med eSIM. Det er svært få som er med i pilotgruppen til Telenor.'
    ]
  },

  {
    title: 'Feilkode 231-3-23',
    type: 'issue',
    date: '',
    text: [
      'kommer når kunden forsøker å sende seg selv kvitteringer fra appen for billetter som er utløpt for seks mnd siden fordi ordrene er arkivert.'
    ],
    comment: [
      'be kunden sende sak inn med hvilke ordre det gjelder så får de kvittering på epost som sendes fra arkivet av drift.',
      'Evt Lage en sak på kunden og videresend til support, så sender de til drift som sender kunden kvitteringer fra arkivet.'
    ]
  },
  {
    title: 'Feil med passord ved henting av overført billett',
    type: 'issue',
    date: '13.12.208',
    text: [
      'Vi opplever at noen kunder får passordfeil når de skal hente billett som er overført fra kundesenteret.',
      '963-31-54 og 963-33-54',
      'OBS disse feilkodene ligger ikke i portalen pr idag!',
      '963=feil med passord',
      '31= hent billett siden i appen',
      '33= opprett passord siden i appen',
      '54= metode for SavePinCode'
    ],
    comment: [
      'Meld til support, husk å få med app versjon, plattform og plattformversjon. Få en beskrivelse fra kunden på hva som ble gjort.',
      'Ny app? gjenopprettet eller installert uten å gjenopprette?',
      'Lagt inn passord fra før? Får de lagt til betalingskort (noe som krever å lage eller ha passord)',
      'Vi jobber med å gjenskape feilen og trenger info fra dere'
    ]
  },

  {
    title: 'Duplikatbillett vises i appen',
    type: 'issue',
    date: '19.10.2018',
    text: [
      'En kunde forklarte at h*n forsøkte å kjøpe fikk feilmelding, forsøkte å kjøpe igjen. Da gikk det bra, men etterpå var det to billetter med samme ordrenummer i appen. I Siebel vises det som en. Kunden er kun trukket en gang. '
    ],
    comment: [
      'Spør hvilken feilmelding det er kunden får i appen.',
      'Annen info vi trenger:',
      'Skjermbilde som viser at det faktisk er to (eller flere billetter) i appen med samme ordrenummer',
      'app-id',
      'plattform (ios eller android)',
      'plattformversjon',
      'Har ikke egentlig noen betydning for kunden, de er ikke trukket to ganger så lenge det er kun en ordre i Siebel.'
    ]
  },

  {
    title: 'Samsung har på sine telefoner (f.o.m. S7) en løsning som heter App power monitor.',
    type: 'issue',
    date: '08.02.2018',
    text: [
      'Standard oppsett i denne appen er å hindre varslinger (og annen bakgrunnsaktivitet) fra alle apper som ikke har blitt brukt på 3 dager.',
      'Det betyr at kun brukere av Billettapp som har startet appen de siste 3 dagene vil motta varsling når billetten utløper.',
      '<i>Vanlig oppførsel på Android-telefoner er en innebygget strømsparingsfunksjon som slår seg på når det er lite strøm på telefonen (~15%). Denne funksjonen hindrer ikke brukeren i å motta varslinger fra Billettapp.</i>'
    ],
    comment: [
      'Vi har to løsninger på dette, kunden må selv gå inn i appen og endre på innstillingen:',
      'Innstillingene til appen finnes her: <b>Innstillinger</b> > <b>Enhetsvedlikehold</b> > <b>Batteri</b>',
      '<b>Løsning 1 - lage unntak for Billettapp</b>',
      'Nederst på siden er det et valg som heter Apper som ikke overvåkes. Ved å legge til Billettapp i denne listen vil appen få lov til å sende varslinger.',
      '<b>Løsning 2 - skru av strømovervåking<b>',
      'Det er mulig å skru av standard oppsett slik at ikke App power monitor hindrer noen apper i sende varslinger.',
      '<a class="link" href="https://ruterwiki.ruter.no/display/RUTERAPP/Samsung+App+power+monitor" target="_blank">Forklaring med bilder finner du her Samsung App power monitor</a>'
    ]
  },

  {
    title: 'Noen kunder opplever å ikke motta sms fra oss (avsender 2050)',
    type: 'issue',
    date: 'desember 2017',
    text: [
      'Dette gjelder både registrering av mobilnummer i appen og kjøp til andre billetter. Dette meldes til support som kan søke i logg for utsendelse.'
    ],
    comment: [
      '<b>For registrering av mobilnummer:</b> Det kan hjelpe å skru tlf av og på, og forsøke på nytt',
      '<b>For kjøp til andre:</b> Gå inn på billettoversikt: Annen telefon trykk på send sms på nytt, evt kan de oppgi hentekode til mottaker',
      //'<b>Bedrifts- og fribilletter:</b> Sjekk at ordren har status 21-klar til henting. Send hentekode fra Siebel eller oppgi hentekode til mottaker',
      //'Noen kunders telefoner er sperret for over takserte nummer (CPA meldinger) Enten om de ønsker det selv for ikke å kunne bli belastet, foreldre har sperret barnas mobil av samme grunn. De har ved en feil sendt stopp melding og dermed blitt sperret.',
      //'Det koster ikke penger å motta en sms fra 2050, men nummeret vårt er et CPA (Content Provider Access) nr, som vi kan velge å ha betalingstjeneste på (vi hadde det før på samme telefon nr var til sanntidsopplysning på sms)',
      '<b>support kan sjekke om tlfnr er blokkert for å motta meldinger fra 2050</b>'
    ]
  },

  {
    title: 'Mobilbillett: Statuser i Siebel etter nedetid',
    type: 'issue',
    date: '24 April 2017',
    text: [
      'Ved nedetid på mobilbilletten kan det oppstå følgefeil som skaper unormale ordrestatuser i Siebel. Vi skal selvsagt alltid sjekke appID og ordrenummer i Siebel, men i etterkant av nedetid bør vi være ekstra obs. Under følger en sjekkliste på ordrestatuser som vi må være oppmerksomme på.',
      '<b>Ordrestatuser:</b>',
      '- «Igangsatt» med betalingsstatus «ikke påbegynt»: Pengene kan være reservert.',
      '- «Eksportert til NOD». Pengene kan være reservert.',
      '- «Teknisk feil capture» . Pengene kan være reservert.',
      '- «Reservasjon påbegynt» - Pengene kan være trukket.',
      '- «Betaling gjennomført» med betalingsstatus «betaling feilet» og distribusjonsstatus «Klar for henting i NOD» Pengene kan være trukket.',
      'To linjer, den ene med ordrestatus «Betaling gjenbrukt» og den andre med ordrestatus «betaling ikke gjennomført», begge med like transaksjonsnummer. Pengene kan være trukket.',
      'Ordre betalt med Vipps kan ikke sjekkes i Netaxept, her må det opprettes sak. Alle saker som opprettes skal knyttes opp med navn, e-post og telefonnummer slik beskrevet i teksten «Saker med/uten kunde i Siebel». Til syvende og sist, hvis man er i tvil og man ikke har noen å spørre skal det også opprettes sak, det er bedre enn å anta.'
    ],
    comment: [
      '<b>Hva gjør vi?</b>',
      'Søk opp ordrenummer i netaxept. Alle ordre betalt med Vipps elelr strex ikke kan sjekkes mot Netaxept. det opprettes sak under tvil, slik beskrevet på intranettet under «Sak med/uten kunde i Siebel».',
      'Statuser i Netaxept',
      '- Reservert (opprett sak dersom ordre er mer enn 3 dager gammel).',
      '- Trukket',
      '- Kreditert'
    ]
  }
];
