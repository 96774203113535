import {TicketType} from '../../app/interfaces';

export const TicketTypeList: Array<TicketType> = [
  {
    id: 'Enkeltbillett',
    name: 'Enkeltbillett',
    field: 'tickettype.keyword'
  },
  {
    id: 'Periodebillett',
    name: 'Periodebillett',
    field: 'tickettype.keyword'
  }
];
