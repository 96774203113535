
import React from 'react';
import './PriceColumn.scss';
import { number } from 'prop-types';

function formatPrice(price) {
  return new Intl.NumberFormat('nb-NO', {
    style: 'currency', currency: 'NOK', minimumFractionDigits: 0, maximumFractionDigits: 0,
  }).format(price);
}

function PriceColumn(props) {
  const { price } = props;
  return (
    <td className="price-column">{formatPrice(price)}</td>
  );
}

PriceColumn.propTypes = {
  price: number.isRequired,
};

export default PriceColumn;
