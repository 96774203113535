import { Search, OosEditing, AjaxErrorObject } from './app/interfaces';

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const OPEN_EDITING = 'OPEN_EDITING';
export const CLOSE_EDITING = 'CLOSE_EDITING';

export const searchLoading = () => ({ type: SEARCH_LOADING } as const);
export const searchSuccess = (response: Search) => ({ type: SEARCH_SUCCESS, search: response } as const);
export const searchFailed = (error: AjaxErrorObject) => ({ type: SEARCH_FAILED, error: error } as const);
export const openEditing = (data: OosEditing) => ({ type: OPEN_EDITING, data: data } as const);
export const closeEditing = () => ({ type: CLOSE_EDITING } as const);

type searchLoadingAction = ReturnType<typeof searchLoading>;
type searchSuccessAction = ReturnType<typeof searchSuccess>;
type searchFailedAction = ReturnType<typeof searchFailed>;
type openEditingAction = ReturnType<typeof openEditing>;
type closeEditingAction = ReturnType<typeof closeEditing>;

export type AllOosActions =
  | searchLoadingAction
  | searchSuccessAction
  | searchFailedAction
  | openEditingAction
  | closeEditingAction
