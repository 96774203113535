
import { Button } from '@ruter-ds/rds-components';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { AccessToken } from '../accessToken/actionTypes';
import apiClient from '../apiClient';
import { failAlert } from '../common/toastr';
import { CITYBIKE_URL, Company } from '../constants';
import './EndUserIdSearch.scss';

interface FormValues {
  endUserId: {
    value: string;
  }
}

const getDecodedAccessToken = () => (window as any).decodedAccessToken as AccessToken

const EndUserIdSearch: React.FC = () => {
  const history = useHistory()
  const [endUserId, setEndUserId] = useState('');
  const [blurred, setBlurred] = useState(false);
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const findAppId = async (e: React.SyntheticEvent) => {

    e.preventDefault();
    if (!valid) {
      return
    }

    setLoading(true);

    const target = e.target as typeof e.target & FormValues;
    const endUserId = target.endUserId.value;

    const decodeAccessToken = getDecodedAccessToken();      
    const kamerat = decodeAccessToken.CompanyId == Company.Brakar ? "-brakar" : "-kolumbus"
    const url = `${CITYBIKE_URL}/api${kamerat}/identity/${endUserId}`;

    try {
      const result = await apiClient.get(url);
      const { instanceName } = result.data;
      history.push(`/viewappinfo?id=${instanceName}`);
    } catch (e) {
      if (e.toString().indexOf('404')) {
        failAlert(`Fant ikke EndUserId=${endUserId}`)
      } else {
        setLoading(false);
        failAlert(`Det skjedde en feil ved søk.`)
        throw e;
      }
    }
    setLoading(false);
  }

  const validate = (value: string) => {
    const validPattern = /^[A-Fa-f0-9]{20}$/
    setValid(validPattern.test(value))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndUserId(e.target.value);
    if (blurred) {
      validate(e.target.value);
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setBlurred(true);
    validate(e.target.value);
  }

  return (
    <form className="end-user-id-search" onSubmit={findAppId}>
      <label>Finn App-ID med End-User-ID</label>
      <div className="search-bar">
        <input
          type="text"
          size={4}
          value={endUserId}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete="off"
          autoCorrect="off"
          className={`textfield ${valid ? '' : ' error'}`}
          id="endUserId"
          name="endUserId"
          placeholder="End-User-Id"
        />
        <Button type="submit" text="Finn App-ID" skin="info" isLoading={loading} />

      </div>
      {!valid && <div className="error">EndUserId må være 20 tegn med bokstaver eller tall</div>}
    </form>
  )

}

export default EndUserIdSearch