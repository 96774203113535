import moment from 'moment';
import { stringify } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Calendar } from '../common/calendar/Calendar';
import { addEvent, convertDateToText, getWindowSize } from '../common/General';
import { Loading } from '../common/loading/Loading';
import { search } from '../oos-actions';
import './Graph.scss';

class Graph extends React.Component {
  constructor(props) {
    super(props);
    const config = this._getFilter(props);
    this.state = {
      interval: config.interval || [],
      error: '',
      size: [900, 300],
      colors: {
        'Betaling kansellert / Påbegynt / Betaling feilet': '#999999',
        'Annet betalingsmåte': '#999999',
        Enkeltbillett: '#913fc4',
        Periodebillett: '#DAA520',
        VISA: '#1E90FF',
        MasterCard: '#db4437',
        Vipps: '#009688',
        MobilePay: '#395697',
        'Bekreftet levert til mobil': '#9ACD32',
        Overført: '#FFDEAD',
        Kreditert: '#CD853F',
        'Betaling kansellert': '#BC8F8F',
        'Overføring påbegynt': '#2E8B57',
        Igangsatt: '#6A5ACD',
        'Starttidspunkt endret': '#FF6347',
        'Betaling ikke gjennomført': '#008080',
        'Eksportert til NOD': '#F0E68C',
        'Klar til henting': '#008000',
        'Henting fullført': '#6495ED',
        'Kreditering feilet': '#20B2AA',
        'AutoPay feilet': '#B0C4DE',
        'Betaling feilet': '#CD853F',
        'Feilet under eksport til NOD': '#EE82EE',
        'Betaling gjennomført': '#9400D3',
        'Henting fra NOD feilet': '#8E8E38',
        '': ''
        //'#9100c7', // purple
      },
      unkonws: {
        ticket: 'Betaling kansellert / Påbegynt / Betaling feilet',
        payment: 'Annet betalingsmåte'
      },
      shortcuts: [
        { id: '-week', name: 'Denne uka' },
        { id: '-month', name: 'Denne måneden' },
        { id: '-year', name: 'Dette året' }
      ],
      categorySelected: config.category || 'payment',
      categories: [
        { id: 'ticket', name: 'Billettype' },
        { id: 'payment', name: 'Betalingsmåte' },
        { id: 'status', name: 'Ordrestatus' }
      ],
      viewSelected: 'BarChart',
      graphToolView: [
        { id: 'BarChart', name: 'Søylediagram' },
        { id: 'LineChart', name: 'Linjediagram' }
        //{'id': 'PieChart ', 'name': 'Kake diagram'}
      ],
      periodSelected: config.period || '',
      periods: [
        { id: '', name: 'ingen periodeinndeling' },
        { id: 'hourly', name: 'Hver time' },
        { id: 'every-other-hour', name: 'Annehver time' },
        { id: 'daily', name: 'Daglig' },
        { id: 'every-other-day', name: 'Annehver dag' },
        { id: 'weekly', name: 'Ukentlig' },
        { id: 'every-other-week', name: 'Annehver uke' },
        { id: 'monthly', name: 'Månedlig' },
        { id: 'every-other-month', name: 'Annehver måned' },
        { id: 'yearly', name: 'Årlig' },
        { id: 'every-other-year', name: 'Annehvert år' }
      ],
      graphList: []
    };
  }

  render() {
    const { storage } = this.props;
    const {
      shortcuts,
      interval,
      size,
      graphList,
      categories,
      categorySelected,
      graphToolView,
      viewSelected,
      periods,
      periodSelected
    } = this.state;
    const loading = storage.waitingFor ? true : false;

    return (
      <div className="graph-wrapper">
        <div className="graph-content">
          <div className="graph-tool">
            <form name="search" noValidate onSubmit={this._submit}>
              <div className="graph-tool-wrapper">
                <div className="search-calendar-holder input-content">
                  <Calendar
                    clock={true}
                    view={2}
                    shortcuts={shortcuts}
                    placeholder={['fra', 'til']}
                    interval={interval}
                    callback={this._calendarCallback}
                    ref="calendar"
                  />
                </div>
                <div className="input-content period-content">
                  <label htmlFor="period-selector">Periodindeling</label>
                  <select
                    ref="periodGraph"
                    className="select-box period-selector"
                    id="period-selector"
                    value={periodSelected}
                    onChange={e => {
                      this._change(e, 'periodSelected');
                    }}
                  >
                    {periods.map((data, i) => (
                      <option key={'period-option-' + i} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-content order-type-content">
                  <label htmlFor="order-type-selector">Kategori</label>
                  <select
                    ref="categoryGraph"
                    className="select-box order-type-selector"
                    id="order-type-selector"
                    value={categorySelected}
                    onChange={e => {
                      this._change(e, 'categorySelected');
                    }}
                  >
                    {categories.map((data, i) => (
                      <option key={'order-type-option-' + i} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="submit-wrapper">
                  <button type="submit" className="secondary-btn -blue -fill">
                    Hent
                  </button>
                </div>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="graph-view">
              {graphList.map((graph, i) => {
                const type = 'paper graph-chart-wrapper' + (graph.type ? ' -' + graph.type : '');
                return (
                  <div key={'graph-chart-' + i} className={type}>
                    <div className="graph-chart-header">
                      <h2>
                        <span>{graph.title}</span>
                        {graph.time ? <span className="graph-time">{graph.time}</span> : null}
                      </h2>
                      <div className="graph-chart-tool">
                        <fiedset className="graph-chart-view-wrapper input-content">
                          <legend className="input-label">Vising:</legend>
                          <ul className="graph-chart-view-list">
                            {graphToolView.map((view, j) => (
                              <li key={'graph-chart-view-btn-' + j}>
                                <input
                                  type="radio"
                                  name="graph-chart-view-btn"
                                  id={view.id}
                                  className="input-radio"
                                  value={view.id}
                                  checked={viewSelected === view.id}
                                  onChange={e => {
                                    this._change(e, 'viewSelected');
                                  }}
                                />
                                <label htmlFor={view.id}>{view.name}</label>
                              </li>
                            ))}
                          </ul>
                        </fiedset>
                      </div>
                    </div>
                    <div className="graph-chart-content">
                      {viewSelected === 'BarChart' && (
                        <BarChart width={size[0]} height={size[1]} data={graph.data}>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="bottom" align="left" position="relative" />
                          {graph.bar.map((b, j) => (
                            <Bar
                              key={'chart-bar-' + i + '-' + j}
                              dataKey={b.key || b.name}
                              name={b.name}
                              fill={b.color}
                            />
                          ))}
                        </BarChart>
                      )}
                      {viewSelected === 'LineChart' && (
                        <LineChart width={size[0]} height={size[1]} data={graph.data}>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          {graph.bar.map((b, j) => (
                            <Line
                              key={'chart-bar-' + i + '-' + j}
                              type="monotone"
                              dataKey={b.key || b.name}
                              name={b.name}
                              fill={b.color}
                            />
                          ))}
                        </LineChart>
                      )}
                    </div>
                    <div className="graph-chart-footer" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    //let config = this._getFilter();
    this._getStatistic('startup');
    addEvent(
      () => {
        this._verifyWindowSize();
      },
      window,
      'resize'
    );
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const nSearch = nextProps.storage.search || {};
    const cSearch = this.props.storage.search || {};
    if (JSON.stringify(nSearch) !== JSON.stringify(cSearch)) {
      nextState.graphList = this._getGraphList(nextProps);
      nextState.copyList = JSON.parse(JSON.stringify(nextState.graphList));
    }
  }

  _click = (e) => {
    if (e) {
      e.preventDefault();
    }
  };

  _change = (e, key) => {
    const state = {};
    state[key] = e.currentTarget.value;
    this.setState(state);
  };

  _submit = e => {
    if (e) {
      e.preventDefault();
    }
    this._getStatistic();
  };

  _getStatistic = (modus, config) => {
    const { actions } = this.props;
    const { interval } = { ...this.state, ...(config || {}) };
    const data = {
      category: this.state.categorySelected || '',
      period: this.state.periodSelected || '',
      filters: []
    };

    if (interval && interval[0] && interval[1]) {
      data.interval = JSON.stringify(interval);
      data.filters = this._getFiltersPeriodDate(data.period, interval);
    }

    //console.log( data );

    if (modus !== 'startup') {
      const query = JSON.parse(JSON.stringify(data));

      if (query.filters) {
        query.filters = JSON.stringify(query.filters);
      }

      window.history.replaceState(null, null, `${window.location.pathname}?${stringify(query)}`);
    }
    actions.getStatistic(data);
  };

  _getFiltersPeriodDate = (period, interval) => {
    if (!interval || !interval[0] || !interval[1]) {
      return [];
    }

    const hourly = 60 * 60 * 1000;
    const daily = hourly * 24;
    const filters = [];
    if (period) {
      const start = new Date(interval[0]);
      const end = new Date(interval[1]);
      let increasing = 0;
      if (period === 'hourly' || period === 'every-other-hour') {
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end.setHours(end.getHours() + 1);
        increasing = period === 'hourly' ? hourly : hourly * 2;
      } else if (period === 'daily' || period === 'every-other-day') {
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end.setDate(end.getDate() + 1);
        increasing = period === 'daily' ? daily : daily * 2;
      } else if (period === 'weekly' || period === 'every-other-week') {
        let day = start.getDay() || 7;
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        start.setDate(start.getDate() - (day - 1));
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        day = end.getDay();
        end.setDate(end.getDate() + 7 - (day - 1));
        increasing = period === 'weekly' ? daily * 7 : daily * 7 * 2;
      } else if (period === 'monthly' || period === 'every-other-month') {
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        start.setDate(1);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end.setDate(1);
        end.setMonth(end.getMonth() + 1);
        increasing = period === 'weekly' ? -1 : -2;
      } else if (period === 'yearly' || period === 'every-other-year') {
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        start.setDate(1);
        start.setMonth(0);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        end.setDate(1);
        end.setMonth(0);
        end.setFullYear(end.getFullYear() + 1);
        increasing = period === 'yearly' ? -12 : -24;
      }

      const range = increasing ? [start.getTime(), end.getTime()] : [0, 0];
      let max = 5000;
      while (range[0] < range[1] && --max > 0) {
        const src = [range[0], range[0] + increasing];
        let tmp = null;
        if (increasing < 0) {
          tmp = new Date(range[0]);
          tmp.setMonth(tmp.getMonth() + increasing * -1);
          src[1] = tmp.getTime();
        }

        const dates = [new Date(src[0]), new Date(src[1])];
        let fDate = convertDateToText(dates[0], null, true, true);
        let tDate = convertDateToText(dates[1], null, true, true);

        fDate = fDate.replace(/\s+/, 'T');
        tDate = tDate.replace(/\s+/, 'T');
        filters.push({
          field: 'lastupdatedate',
          type: 'date',
          gte: fDate,
          lte: tDate
        });
        range[0] = src[1];
      }
    } else {
      const dates = [new Date(interval[0]), new Date(interval[1])];
      let fDate = convertDateToText(dates[0], null, true, true);
      let tDate = convertDateToText(dates[1], null, true, true);

      fDate = fDate.replace(/\s+/, 'T');
      tDate = tDate.replace(/\s+/, 'T');
      filters.push({
        field: 'lastupdatedate',
        type: 'date',
        gte: fDate,
        lte: tDate
      });
    }
    return filters;
  };

  _calendarCallback = data => {
    const { error } = this.state;

    const current = data.error ? [] : [data.stampFrom, data.stampTo];
    this.setState({
      interval: current,
      error: data.error ? error || data.error : ''
    });
  };

  _getGraphList = props => {
    const { storage } = props || this.props;
    const { unkonws, colors, categories, categorySelected } = this.state;
    const search = storage.search || {};
    const config = this._getFilter();

    const interval = config.interval || [];
    const end = convertDateToText(interval[1] ? new Date(interval[1]) : new Date(), '.', true);
    const start = interval[0] ? convertDateToText(new Date(interval[0]), '.', true) : '01.01.2017 00:00';

    const title = (categories.find(d => d.id === categorySelected) || {}).name || 'GRAPH';
    const out = {
      type: categorySelected,
      title: title,
      unit: 'ordre',
      time: '(' + start + ' - ' + end + ')',
      data: [],
      bar: [],
      pin: {}
    };

    search.forEach(result => {
      const source = result.data || {};
      if (!source.aggregations) {
        return;
      }

      const buckets = (source.aggregations.agg1 || {}).buckets || [];
      if (!buckets.length) {
        return;
      }

      const cnt = { name: result.name || '-', amt: 0 };
      buckets.forEach(bucket => {
        const name = bucket.key || unkonws[bucket.key] || 'Ukjent';
        cnt[bucket.key] = bucket.doc_count || 0;
        cnt.amt += cnt[bucket.key];
        if (!out.pin[bucket.key]) {
          out.pin[bucket.key] = 1;
          out.bar.push({
            name: name,
            color: colors[name] || colors[name.toLowerCase()] || colors[bucket.key] || '#FF0000'
          });
        }
      });
      out.data.push(cnt);
    });
    //console.log( out ); console.log( search );

    return [out];
  };

  _getFilter = props => {
    const { location } = props || this.props;
    const query = location.query || {};
    const config = {};
    const filters = query.filters ? JSON.parse(query.filters) : [];
    const reduced = filters.reduce((prev, data) => {
      if (data.type === 'date') {
        prev[data.field] = data;
      } else {
        prev[data.field] = {};
        (data.value || '').split(',').forEach(v => {
          prev[data.field][v + ''] = 1;
        });
      }
      return prev;
    }, {});

    if (query.interval) {
      config.interval = JSON.parse(query.interval);
    } else if (reduced.lastupdatedate) {
      moment.locale('nb');
      const gte = moment(reduced.lastupdatedate.gte).unix();
      const lte = moment(reduced.lastupdatedate.lte).unix();
      const fDate = new Date(gte * 1000);
      const tDate = new Date(lte * 1000);
      config.interval = [fDate.getTime(), tDate.getTime()];
    }

    config.category = query.category;
    config.period = query.period;
    return config;
  };

  _verifyWindowSize = () => {
    const size = getWindowSize();
    const now = this.state.size;
    let width = 900;
    if (size[0] < 950) {
      width = 750;
    }
    if (size[0] < 800) {
      width = 600;
    }
    if (size[0] < 650) {
      width = 400;
    }
    if (size[0] < 450) {
      width = 300;
    }

    if (now[0] !== width) {
      clearTimeout(this.resizeTimer || 0);
      this.setState({ size: [width, now[1]] });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    storage: state.oos,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStatistic: (data, callback) => dispatch(search(data, callback, 'getStatistic')),
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Graph));
