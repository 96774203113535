import React from 'react';
import "./TicketCounter.scss";
import { NavLink } from 'react-router-dom';

const TicketCounter: React.FC = () => {
  return (<div className="ticket-counter-menu">
    <ul>
      <li><NavLink activeClassName="active" exact to="/billettluke">Send billetter</NavLink></li>
      <li><NavLink activeClassName="active" to="/billettluke/ordre">Ordrehistorikk</NavLink></li>
      <li><NavLink activeClassName="active" to="/billettluke/billetter">Billetthistorikk</NavLink></li>
    </ul>
  </div>)
}

export default TicketCounter;