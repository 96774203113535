import { useOktaAuth } from '@okta/okta-react';
import { Container, Icon, Input, Message, ProgressRadial } from '@ruter-ds/rds-components';
import { CellPhoneArrowRightIcon, CrossIcon, ExclamationCircleIcon, SearchIcon } from '@ruter-ds/rds-icons';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { OrderAndTicket, SearchResult, TicketApiClient } from 'ruter-billettluke';
import Pagination from '../../common/Pagination/Pagination';
import PhoneColumn from '../../common/table/PhoneColumn/PhoneColumn';
import StatusColumn from '../../common/table/StatusColumn/StatusColumn';
import usePaginatedFetch from '../../usePaginatedFetch';
import "./Tickets.scss";

function renderLoading() {
  return (
    <tr>
      <td colSpan={8}>Laster inn billetter...</td>
    </tr>
  )
}

function renderNoOrders() {
  return (
    <tr>
      <td colSpan={8}>Fant ingen billetter.</td>
    </tr>
  )
}


const Tickets: React.FC = () => {
  const { result, loading, page, queryInput, pageSize, setPage, fetchWithQuery, setInputQuery, error } = usePaginatedFetch<SearchResult<OrderAndTicket>>("tickets");
  const [productTemplates, setProductTemplates] = useState(null);
  const { authService } = useOktaAuth();
  const [resendTicketId, setResendTicketId] = useState(undefined);
  const [cancelTicketId, setCancelTicketId] = useState(undefined);

  useEffect(() => {
    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    ticketApi.productTemplates.getAll().then(x => setProductTemplates(x));
  }, [authService]);
  
  const getProduct = (productTemplateId: number) => {
    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (!productTemplate) {
      return '';
    }
    return productTemplate.name.nb;
  }

  const resendCode = (event, ticketId) => {
    event.stopPropagation();
    setResendTicketId(ticketId);

    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    ticketApi.tickets.resendPickupCode(ticketId).then(() => {
      setResendTicketId(undefined);
    })
  }

  const cancelTicket = (event, ticketId) => {
    event.stopPropagation();
    setCancelTicketId(ticketId);

    const ticketApi = new TicketApiClient(authService, '/proxy/billettluke');
    ticketApi.tickets.cancelPending(ticketId).then(() => {
      const orderAndTicket = result.matches.find(x => x.ticket.id === ticketId);
      orderAndTicket.ticket.status = 'cancelled';
      setCancelTicketId(undefined);
    })
  }

  const renderRow = (row: OrderAndTicket) => {
    return (
      <tr key={row.ticket.id}>
        <td>{moment(row.order.purchaseDate).format('DD.MM.YYYY HH:mm:ss')}</td>
        <td>{row.ticket.id}</td>
        <PhoneColumn phoneCountryCode={row.ticket.phoneCountryCode} phone={row.ticket.phone} />
        <td>{getProduct(row.order.productTemplateId)}</td>
        <StatusColumn status={row.ticket.status} />
        <td>{row.order.invoiceReference}</td>
        <td>{row.order.personalReference}</td>
        <td className="actions">
          {row.ticket.status === 'pending' && (
            <div>
              <button
                className="resend"
                data-test-id="resend-button"
                title="Send hentekode"
                type="button"
                onClick={(event) => resendCode(event, row.ticket.id)}
              >
                {(resendTicketId && resendTicketId === row.ticket.id) && (
                  <ProgressRadial isIndeterminate size="small" />
                )}
                {!(resendTicketId && resendTicketId === row.ticket.id) && (
                  <Icon component={<CellPhoneArrowRightIcon />} size="small" />
                )}

              </button>
              <button
                className="cancel"
                data-test-id="cancel-ticket-button"
                title="Kanseller billett"
                type="button"
                onClick={(event) => cancelTicket(event, row.ticket.id)}
              >
                {(cancelTicketId && cancelTicketId === row.ticket.id) && (
                  <ProgressRadial isIndeterminate size="small" />
                )}
                {!(cancelTicketId && cancelTicketId === row.ticket.id) && (
                  <Icon component={<CrossIcon />} size="small" />
                )}

              </button>
            </div>
          )}
        </td>
      </tr>
    )
  }

  const totalNumberOfPages = result ? Math.floor(result.totalEntries / pageSize) + 1 : 1;
  const activePage = page + 1;

  if(productTemplates === null) {
    return null;
  }

  return (
    <Container width="l" className="order-history-page">
      <h1>Billetthistorikk</h1>
      <div className="search">
        <form onSubmit={(e) => { e.preventDefault(); fetchWithQuery(queryInput) }}>
          <Input
            placeholder={`Filtrer på telefonnummer eller kommentar`}
            iconRight={<SearchIcon />}
            value={queryInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputQuery(e.target.value)
            }}
            autoFocus={true}
          />
          <button type="submit" className="secondary-btn -blue -fill">Søk</button>
        </form>
      </div>

      {error && !loading && (
        <Message
          skin="danger"
          title="Det skjedde en feil ved henting av ordre"
          icon={<ExclamationCircleIcon />}
        >
        </Message>
      )}
      {!error && (
        <table>
          <thead>
            <tr>
              <th scope="col">Kjøpsdato</th>
              <th scope="col">Billettnr.</th>
              <th scope="col">Telefon</th>
              <th scope="col">Produkt</th>
              <th scope="col">Status</th>
              <th scope="col">Fakturaref.</th>
              <th scope="col">Kommentar</th>
              <th scope="col">Handlinger</th>
            </tr>
          </thead>
          <tbody>
            {loading && renderLoading()}
            {!loading && result && result.totalEntries === 0 && renderNoOrders()}
            {!loading && result && result.totalEntries > 0 && result.matches.map(renderRow)}
          </tbody>
        </table>
      )}
      <Pagination
        onPageChange={(page) => setPage(page - 1)}
        activePage={activePage}
        totalNumberOfPages={totalNumberOfPages}
        pageSize={pageSize}
      />

    </Container>
  )
}

export default Tickets;