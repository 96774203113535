import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { convertToHumanDate, isConfirmed, validMoveticketStatus, validRefundStatus } from '../common/General';


export const HomeTableList = ({ list, sorted, callback, parent, showMore, productId }) => {
  const accessToken = useSelector((state) => state.accessToken)

  return (
  <div className="home-table-list-wrapper">
    <ul className="table-list home-table-list">
      <li className="table-header">
        <div className="column order-id">
          {parent === 'viewapp' ? (
            <span>Billettnummer</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'orderid');
              }}
              className={
                'sort-btn' + (sorted === 'orderid:desc' ? ' -desc' : sorted === 'orderid:incr' ? ' -incr' : '')
              }
            >
              Billettnummer
            </button>
          )}
        </div>
        <div className="column app-id">
          {parent === 'viewapp' ? (
            <span>App-ID</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'mobileinstanceid');
              }}
              className={
                'sort-btn' +
                (sorted === 'mobileinstanceid:desc' ? ' -desc' : sorted === 'mobileinstanceid:incr' ? ' -incr' : '')
              }
            >
              App-ID
            </button>
          )}
        </div>
        <div className="column date">
          {parent === 'viewapp' ? (
            <span>Bestilt</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'dateordered');
              }}
              className={
                'sort-btn' + (sorted === 'dateordered:desc' ? ' -desc' : sorted === 'dateordered:incr' ? ' -incr' : '')
              }
            >
              Bestilt
            </button>
          )}
        </div>
        <div className="column ticket-type">
          {parent === 'viewapp' ? (
            <span>Billettype</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'tickettype');
              }}
              className={
                'sort-btn' + (sorted === 'tickettype:desc' ? ' -desc' : sorted === 'tickettype:incr' ? ' -incr' : '')
              }
            >
              Billettype
            </button>
          )}
        </div>
        <div className="column price">
          {parent === 'viewapp' ? (
            <span>Beløp</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'totalprice');
              }}
              className={
                'sort-btn' + (sorted === 'totalprice:desc' ? ' -desc' : sorted === 'totalprice:incr' ? ' -incr' : '')
              }
            >
              Beløp
            </button>
          )}
        </div>
        <div className="column channel">
          {parent === 'viewapp' ? (
            <span>Kanal</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'clientuseragent');
              }}
              className={
                'sort-btn' +
                (sorted === 'clientuseragent:desc' ? ' -desc' : sorted === 'clientuseragent:incr' ? ' -incr' : '')
              }
            >
              Kanal
            </button>
          )}
        </div>
        <div className="column status">
          {parent === 'viewapp' ? (
            <span>Status</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'orderstatus');
              }}
              className={
                'sort-btn' + (sorted === 'orderstatus:desc' ? ' -desc' : sorted === 'orderstatus:incr' ? ' -incr' : '')
              }
            >
              Status
            </button>
          )}
        </div>
        <div className="column payment">
          {parent === 'viewapp' ? (
            <span>Betalt</span>
          ) : (
            <button
              onClick={e => {
                callback(e, 'sort', 'datepaid');
              }}
              className={
                'sort-btn' + (sorted === 'datepaid:desc' ? ' -desc' : sorted === 'datepaid:incr' ? ' -incr' : '')
              }
            >
              Betalt
            </button>
          )}
        </div>
        <div className="column refund">Refundér</div>
        <div className="column -last changing">Flytt</div>
      </li>
      {list.map((order, i) => {
        const id = order._id;
          const source = order._source || {};
        //if ( ! productId[((source.productid||'')+'')] ) { console.log( source.productid ); }

        return showMore && showMore <= i ? null : (
          <li key={id} className={i % 2 ? 'odd-row' : 'event-row'}>
            <div className="column order-id" aria-label="Ordre">
              <Link
                className="no-wrap link"
                to={{
                  pathname: '/vieworder',
                  search: `?id=${source.orderid}&pid=${source.paymenttransactionsid || ''}`
                }}
              >
                {source.orderid}
              </Link>
            </div>
            <div className="column app-id" aria-label="App">
              <Link
                className="no-wrap link"
                to={{
                  pathname: '/viewappinfo',
                  search: `?id=${source.mobileinstanceid}&pid=${source.paymenttransactionsid}`
                }}
              >
                {source.mobileinstanceid}
              </Link>
            </div>
            <div className="column date no-wrap" aria-label="Kjøpt">
              {convertToHumanDate(source.dateordered)}
            </div>
            <div
              className="column ticket-type"
              aria-label="Billett"
              title={source.tickettypeid + ' (' + source.tickettype + ') ' + (source.productid || '')}
            >
              {(productId || {})[(source.productid || '') + ''] || source.tickettype}
            </div>
            <div className="column price" aria-label="Beløp">
              {source.totalprice}
              ,-
            </div>
            <div className="column channel" aria-label="Kanal">
              <span title={source.clientuseragent} className={'icon-btn -' + (source.clientuseragent || 'none')}>
                <span className="aria-visible">{source.clientuseragent}</span>
              </span>
            </div>
            <div className="column status" aria-label="Status">
              <span
                className={'icon-btn -include-text ' + (isConfirmed(source.orderstatus) ? '' : '-not') + '-confirmed'}
                title={'statusid: ' + source.orderstatusid}
              >
                {source.orderstatus}
              </span>
            </div>
            <div className="column payment" aria-label="Betaling">
              <span
                title={source.cardtype}
                className={'icon-btn -in-left -' + (source.cardtype || 'none').toLowerCase()}
              >
                <span className="aria-visible">{source.cardtype}</span>
                {convertToHumanDate(source.datepaid)}
              </span>
            </div>
            <div className="column refund" aria-label="Refundér">
              {validRefundStatus(source, accessToken) && (
                <button
                  title="Refunder penger"
                  className="icon-btn -refund -blue"
                  onClick={e => {
                    callback(e, 'refund', order);
                  }}
                >
                  <span className="aria-visible">Refunder {source.orderstatusid}</span>
                </button>
              )}
            </div>
            <div className="column changing -last" aria-label="Flytt">
              {validMoveticketStatus(source, accessToken) && (
                <button
                  title="Flytte billett"
                  className="icon-btn -change -blue"
                  onClick={e => {
                    callback(e, 'moveticket', order);
                  }}
                >
                  <span className="aria-visible">Flytte billett {source.orderid}</span>
                </button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
    {showMore && showMore < list.length && (
      <div className="show-more-wrapper">
        <button
          className="secondary-btn show-more-btn"
          onClick={e => {
            callback(e, 'show-more', 'order');
          }}
        >
          Vis mer
        </button>
      </div>
    )}
  </div>
)};

HomeTableList.propTypes = {
  sorted: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired
};
