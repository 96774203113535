export const SEARCH_URL = '/api/elastic'
export const CITYBIKE_URL = '/proxy/bysykkel'
export const SAPI_URL = '/proxy/sapi/'

export enum Company {
  Kolumbus = 500,
  AKT = 109,
  Ostfold = 150,
  Brakar = 102,
  Ruter = 3,
}

export const ELASTIC_MAX_RESULT_WINDOW = 10000;