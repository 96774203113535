import React from 'react';
import './AppLoading.scss';

export default function AppLoading(): JSX.Element {
  return (
      <div className="app-loading">
        <div className="spinner"></div>
        <div className="text">Laster Kundesenterportalen...</div>
      </div>
    )
}