import React from 'react';
import { useSelector } from 'react-redux';
import { RootState} from '../../app/interfaces';
import Editing from './Editing';


const EditingWrapper: React.SFC = () => {
  const showEditing = useSelector((state: RootState) => Boolean(state.oos?.editing?.type));

  return (
    showEditing
    ? <Editing />
    : null
  )
}

export default EditingWrapper;