import { parse } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getProductIdData, hasWriteAccess, validCancelOrderStatus, validMoveticketStatus,

  validPickupOrderStatus, validRefundStatus,



  validSendReceiptStatus
} from '../common/General';
import { Loading } from '../common/loading/Loading';
import { Tablist } from '../common/tablist/Tablist';
import { openEditing } from '../oos-action-creators';
import { search } from '../oos-actions';
import './ViewOrder.scss';
import { ViewOrderTableList } from './ViewOrderTableList';


class ViewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    const { actions, location } = this.props;
    const { id = '', pid = '' } = parse(location.search);

    actions.getOrderById({ id: id, pid: pid });
  }

  render() {
    const { storage, actions, hasWriteAccess, productId } = this.props;
    const search = storage.search || {};
    if (!search.order || !search.order._source) {
      return storage.waitingFor ? <Loading /> : <div className="empty-text">Ordre finnes ikke.</div>;
    }

    const order = search.order;
    const source = order._source;
    const info = { ...(search.payment._source || {}), ...source };
    const tablist = [
      { id: 'info', name: 'Info', order: source, content: info },
      {
        id: 'log',
        name: 'Logger',
        order: source,
        content: search.log.hits.hits || []
      },
      {
        id: 'download',
        name: 'Nedlastingslogg',
        order: source,
        content: search.download.hits.hits || []
      }
    ];

    return (
      <div className="view-order-wrapper">
        <div className="view-order-header">
          <h2>Billettnummer : {source.orderid}</h2>
        </div>
        <div className="view-information-wrapper view-order-information">
          <ViewOrderTableList source={source} callback={this._click} productId={productId} />
          <div className="action-holder">
            {validRefundStatus(source, hasWriteAccess) && (
              <button
                title="Refunder penger"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'refund', order);
                }}
              >
                <span aria-hidden="true">Refundér</span>
                <span className="aria-visible">Refunder {source.orderstatusid}</span>
              </button>
            )}
            {validMoveticketStatus(source, hasWriteAccess) && (
              <button
                title="Flytte billett"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'moveticket', order);
                }}
              >
                <span aria-hidden="true">Flytt</span>
                <span className="aria-visible">Flytte billett {source.orderid}</span>
              </button>
            )}

            {validPickupOrderStatus(source, hasWriteAccess) && (
              <button
                title="Sende hentekode"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'sendpickupcode', order);
                }}
              >
                <span aria-hidden="true">Sende hentekode</span>
                <span className="aria-visible">Sende hentekode {source.orderid}</span>
              </button>
            )}

            {validSendReceiptStatus(source, hasWriteAccess) && (
              <button
                title="Send kvittering"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'sendreceipt', order);
                }}
              >
                <span aria-hidden="true">Send kvittering</span>
                <span className="aria-visible">Send kvittering {source.orderid}</span>
              </button>
            )}

            {validCancelOrderStatus(source, hasWriteAccess) && (
              <button
                title="Kanseller ordre"
                className="secondary-btn -blue -fill"
                onClick={e => {
                  this._click(e, 'cancelorder', order);
                }}
              >
                <span aria-hidden="true">Kanseller</span>
                <span className="aria-visible">Kanseller ordre {source.orderid}</span>
              </button>
            )}
          </div>
        </div>
        <Tablist list={tablist} actions={actions} hasWriteAccess={hasWriteAccess} productId={productId} />
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const cQuery = parse(this.props.location.search);
    const nQuery = parse(nextProps.location.search);

    if (cQuery.id !== nQuery.id) {
      this.props.actions.getOrderById({ id: nQuery.id, pid: nQuery.pid });
    }
  }

  _submit = e => {
    if (e) {
      e.preventDefault();
    }
  };

  _click = (e, key, data) => {
    if (e) {
      e.preventDefault();
    }

    const { actions } = this.props;

    if (
      key === 'refund' ||
      key === 'moveticket' ||
      key === 'sendreceipt' ||
      key === 'deletepayment' ||
      key === 'cancelorder' ||
      key === 'sendpickupcode'
    ) {
      actions.openEditing({
        type: key,
        order: data,
        callback: response => {
          this._edited(response);
        }
      });
    } else {
      console.warn('Unknown action:', key, data);
    }
  };

  _edited = response => {
    //console.log(' === EDITED CALLBACK ==='); console.log( response );
    if (response.action === 'submited') {
      //this._search();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    hasWriteAccess: hasWriteAccess(state.accessToken),
    storage: state.oos,
    productId: getProductIdData(state.accessToken.CompanyId),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    search: (data, callback) => dispatch(search(data, callback, 'search')),
    getOrderById: (data, callback) => dispatch(search(data, callback, 'getOrderById')),
    openEditing: (data) => dispatch(openEditing(data)),
    dispatch,
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewOrder));
